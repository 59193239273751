import ShortLinks from "./short-links";
import QrCodes from "./qr-codes";
import Login from "./login";
import Signup from "./signup";
import CustomLinks from "./custom-links";
import CreateQrCode from "./create-qr-code/index";
import EditQrCode from "./create-qr-code/[id]";
import LinkInBio from "../pages/link-in-bio";
import CreateLinkInBio from "./create-link-in-bio";
import EditLinkInBio from "./create-link-in-bio/[id]";
import ResetPassword from "./reset-password";
import ForgotPassword from "./forgotPassword";
import UserSettings from "./user-settings";
import Subscription from "./subscription";
import Dashboard from "./user-dashboard";
import AdminDashboard from "./admin-dashboard";
import Subscribers from "./subscribers";
import VerifyAccount from "./verify-account";
import Contacts from "./contacts";
import emailCampaign from "./email-campaigns";
import smsCampaign from "./sms-campaigns.jsx";
import createEmailCampaign from "./email-campaigns-create";
import createSmsCampaign from "./sms-campaigns-create";
import emailTemplateGallery from "./email-template-gallery";
import whatsappTemplateGallery from "./whatsapp-template-gallery";
import emailCampignSettings from "../components/email-campaigns/emailCampaignSettings";
import whatsappCampaignSettings from "../components/whatsapp-campaigns/whatsappCampaignSettings.js";
import editEmailTemplate from "../components/email-campaigns/editEmailTemplate";
import addEmailTemplate from "../components/email-campaigns/addNewEmailTemplate";
import addNewEmailTemplateToGallery from "../components/email-campaigns/addNewEmailTemplateToGallery";
import editEmailCampaign from "../components/email-campaigns/editEmailCampaign";
import whatsappCampaign from "./whatsapp-campaigns.jsx";
import createWhatsappCampaign from "./whatsapp-campaigns-create.jsx";
import editWhatsappCampaign from "../pages/campaigns/editWhatsappCampaign";
import whatsappSettings from "../pages/campaigns/whatsappSettings";
import setupWhatsappCampaign from "../pages/campaigns/setupWhatsappCampaign";
import AcceptTerms from "./profile-acceptance/acceptTerms";
import CompleteProfile from "./profile-acceptance/completeProfile";
import Integrations from "../pages/integrations/integrations";
import QrCodeList from "../components/qr-codes/create-qr-code/qrlist";
import QrGeneration from "../components/qr-codes/create-qr-code/qrGeneration";

const routes = {
  noAuthRoutes: [
    {
      path: "/",
      component: Login,
    },
    {
      path: "/login",
      component: Login,
    },
    {
      path: "/signup",
      component: Signup,
    },
    {
      path: "/forgotPassword",
      component: ForgotPassword,
    },
    {
      path: "/reset-password",
      component: ResetPassword,
    },
    {
      path: "/verify",
      component: VerifyAccount,
    },
  ],
  adminRoutes: [
    {
      path: "/admin-dashboard",
      component: AdminDashboard,
    },
    {
      path: "/subscribers",
      component: Subscribers,
    },
  ],
  routes: [
    {
      path: "/short-links",
      component: ShortLinks,
    },
    {
      path: "/custom-links",
      component: CustomLinks,
    },
    {
      path: "/link-in-bio",
      component: LinkInBio,
    },
    {
      path: "/create-link-in-bio",
      component: CreateLinkInBio,
    },
    {
      path: "/create-link-in-bio/:id",
      component: EditLinkInBio,
    },
    {
      path: "/qr-codes",
      component: QrCodes,
    },
    {
      path: "/create-qr-code",
      component: CreateQrCode,
    },
    {
      path: "/create-qr-code-website",
      // component: CreateQrCode,
      component: QrGeneration,
      props: { qrType: "website" },
    },
    {
      path: "/create-qr-code-vcard",
      component: QrGeneration,
      props: { qrType: "vcard" },
    },
    {
      path: "/create-qr-code-business-page",
      component: QrGeneration,
      props: { qrType: "business" },
    },
    {
      path: "/create-qr-code-2dbarcode",
      component: QrGeneration,
      props: { qrType: "barcode" },
    },
    {
      path: "/create-qr-code-pdf",
      component: QrGeneration,
      props: {qrType:"pdf"}
    },
    {
      path: "/create-qr-code-video",
      component: QrGeneration,
      props: {qrType:"video"}
    },
    {
      path: "/create-qr-code-audio",
      component: QrGeneration,
      props: {qrType:"audio"}
    },
    {
      path: "/create-qr-code-images",
      component: QrGeneration,
      props: {qrType:"images"}
    },
    {
      path: "/list-qr-codes",
      component: QrCodeList,
    },
    {
      path: "/create-qr-code/:id",
      component: EditQrCode,
    },
    {
      path: "/user-settings",
      component: UserSettings,
    },
    {
      path: "/subscription",
      component: Subscription,
    },
    {
      path: "/smscampaigns",
      component: smsCampaign,
    },
    {
      path: "/emailcampaigns",
      component: emailCampaign,
    },
    {
      path: "/createemailcampaign",
      component: createEmailCampaign,
    },
    {
      path: "/editemailcampaign/:id",
      component: editEmailCampaign,
    },
    {
      path: "/addemailtemplate",
      component: addEmailTemplate,
    },
    {
      path: "/addemailtemplatetogallery",
      component: addNewEmailTemplateToGallery,
    },
    {
      path: "/editemailtemplate/:id",
      component: editEmailTemplate,
    },
    {
      path: "/emailcampaignsettings/:id",
      component: emailCampignSettings,
    },
    {
      path: "/whatsappcampaignsettings/:id",
      component: whatsappCampaignSettings,
    },
    {
      path: "/emailtemplategallery",
      component: emailTemplateGallery,
    },
    {
      path: "/whatsappcampaigns",
      component: whatsappCampaign,
    },
    {
      path: "/whatsapptemplategallery",
      component: whatsappTemplateGallery,
    },
    {
      path: "/createwhatsappcampaign",
      component: createWhatsappCampaign,
    },
    {
      path: "/editwhatsappcampaign/:id",
      component: editWhatsappCampaign,
    },
    {
      path: "/whatsappsettings",
      component: whatsappSettings,
    },
    {
      path: "/createsmscampaign",
      component: createSmsCampaign,
    },
    {
      path: "/updatesmscampaign/:id",
      component: createSmsCampaign,
    },

    {
      path: "/setupwhatsappcampaign",
      component: setupWhatsappCampaign,
    },
    {
      path: "/accept-terms",
      component: AcceptTerms,
    },
    {
      path: "/complete-profile",
      component: CompleteProfile,
    },
    {
      path: "/dashboard",
      component: Dashboard,
    },
    {
      path: "/contacts",
      component: Contacts,
    },
    {
      path: "/Contacts",
      component: Contacts,
    },
    {
      path: "/integrations",
      component: Integrations,
    },
  ],
  // Add more routes as needed
};
export default routes;
