// SvgIcons.js

import Icon0 from "../thumbnails/icon0";
import Icon1 from "../thumbnails/icon1";
import Icon2 from "../thumbnails/icon2";
import Icon3 from "../thumbnails/icon3";
import Icon4 from "../thumbnails/icon4";
import Icon5 from "../thumbnails/icon5";
import DualIcon6 from "../thumbnails/dualicon6";
import DualIcon7 from "../thumbnails/dualicon7";
import DualIcon8 from "../thumbnails/dualicon8";
import DualIcon9 from "../thumbnails/dualicon9";
import DualIcon10 from "../thumbnails/dualicon10";
import DualIcon11 from "../thumbnails/dualicon11";
import DualIcon12 from "../thumbnails/dualicon12";
import DualIcon13 from "../thumbnails/dualicon13";
import DualIcon14 from "../thumbnails/dualicon14";
import DualIcon15 from "../thumbnails/dualicon15";
import DualIcon16 from "../thumbnails/dualicon16";
import DualIcon17 from "../thumbnails/dualicon17";
import DualIcon18 from "../thumbnails/dualicon18";
import DualIcon19 from "../thumbnails/dualicon19";
import DualIcon20 from "../thumbnails/dualicon20";
import DualIcon21 from "../thumbnails/dualicon21";
import DualIcon22 from "../thumbnails/dualicon22";
import DualIcon23 from "../thumbnails/dualicon23";
import DualIcon24 from "../thumbnails/dualicon24";
import DualIcon25 from "../thumbnails/dualicon25";
import DualIcon26 from "../thumbnails/dualicon26";
import DualIcon27 from "../thumbnails/dualicon27";
import DualIcon28 from "../thumbnails/dualicon28";
import DualIcon29 from "../thumbnails/dualicon29";
import DualIcon30 from "../thumbnails/dualicon30";
import DualIcon31 from "../thumbnails/dualicon31";
import DualIcon32 from "../thumbnails/dualicon32";
import DualIcon33 from "../thumbnails/dualicon33";
import DualIcon34 from "../thumbnails/dualicon34";
import DualIcon35 from "../thumbnails/dualicon35";

import IconFrame0 from "./svgs/iconFrame0";
import IconFrame1 from "./svgs/iconFrame1";
import IconFrame2 from "./svgs/iconFrame2";
import IconFrame3 from "./svgs/iconFrame3";
import IconFrame4 from "./svgs/iconFrame4";
import IconFrame5 from "./svgs/iconFrame5";
import DualIconFrame6 from "./svgs/dualiconFrame6";
import DualIconFrame7 from "./svgs/dualiconFrame7";
import DualIconFrame8 from "./svgs/dualiconFrame8";
import DualIconFrame9 from "./svgs/dualiconFrame9";
import DualIconFrame10 from "./svgs/dualiconFrame10";
import DualIconFrame11 from "./svgs/dualiconFrame11";
import DualIconFrame12 from "./svgs/dualiconFrame12";
import DualIconFrame13 from "./svgs/dualiconFrame13";
import DualIconFrame14 from "./svgs/dualiconFrame14";
import DualIconFrame15 from "./svgs/dualiconFrame15";
import DualIconFrame16 from "./svgs/dualiconFrame16";
import DualIconFrame17 from "./svgs/dualiconFrame17";
import DualIconFrame18 from "./svgs/dualiconFrame18";
import DualIconFrame19 from "./svgs/dualiconFrame19";
import DualIconFrame20 from "./svgs/dualiconFrame20";
import DualIconFrame21 from "./svgs/dualiconFrame21";
import DualIconFrame22 from "./svgs/dualiconFrame22";
import DualIconFrame23 from "./svgs/dualiconFrame23";
import DualIconFrame24 from "./svgs/dualiconFrame24";
import DualIconFrame25 from "./svgs/dualiconFrame25";
import DualIconFrame26 from "./svgs/dualiconFrame26";
import DualIconFrame27 from "./svgs/dualiconFrame27";
import DualIconFrame28 from "./svgs/dualiconFrame28";
import DualIconFrame29 from "./svgs/dualiconFrame29";
import DualIconFrame30 from "./svgs/dualiconFrame30";
import DualIconFrame31 from "./svgs/dualiconFrame31";
import DualIconFrame32 from "./svgs/dualiconFrame32";
import DualIconFrame33 from "./svgs/dualiconFrame33";
import DualIconFrame34 from "./svgs/dualiconFrame34";
import DualIconFrame35 from "./svgs/dualiconFrame35";
import { useEffect } from "react";




const Frames = (props) => {
  useEffect(()=>{
    props?.frame &&
console.log(props,"props",);
  },[props])
  const { primary, secondary, text, textColor, type, url, width, height, minFontSize, maxFontSize }=props
  const maxCharacters = 24;
  const textLength = text?.length;

  // Calculate the font size dynamically based on the length of the text
  let fontSize = maxFontSize;
  if (text?.length > 7) {
    let q = maxFontSize - (((textLength - 6) / maxCharacters) * (maxFontSize - minFontSize));
    fontSize = Math.max(minFontSize, Math.min(q, maxFontSize));
  }
  console.log(url);
  return (
    type === "icon0" ? (
      <IconFrame0
        fontSize={fontSize}  {...props}

      />
    ) : 
    type === "icon1" ? (
      <IconFrame1
        fontSize={fontSize}  {...props}

      />
    ) : type === "icon2" ? (
      <IconFrame2
        fontSize={fontSize}  {...props}

      />
    ) : type === "icon3" ? (
      <IconFrame3
        fontSize={fontSize}  {...props}

      />
    ) : type === "icon4" ? (
      <IconFrame4
        fontSize={fontSize}  {...props}

      />
    ): type === "icon5" ? (
      <IconFrame5
        fontSize={fontSize}  {...props}

      />
    )
    
    : type === "dualicon6" ? (
      <DualIconFrame6
        fontSize={fontSize}  {...props}

      />
    ): type === "dualicon7" ? (
      <DualIconFrame7
        fontSize={fontSize}  {...props}

      />
    ): type === "dualicon8" ? (
      <DualIconFrame8
        fontSize={fontSize}  {...props}

      />
    ): type === "dualicon9" ? (
      <DualIconFrame9
        fontSize={fontSize}  {...props}

      />
    ): type === "dualicon10" ? (
      <DualIconFrame10
        fontSize={fontSize}  {...props}

      />
    ): type === "dualicon11" ? (
      <DualIconFrame11
        fontSize={fontSize}  {...props}

      />
    ): type === "dualicon12" ? (
      <DualIconFrame12
        fontSize={fontSize}  {...props}

      />
    )
    : type === "dualicon13" ? (
      <DualIconFrame13
        fontSize={fontSize}  {...props}

      />
    )
    : type === "dualicon14" ? (
      <DualIconFrame14
        fontSize={fontSize}  {...props}

      />
    )
    : type === "dualicon15" ? (
      <DualIconFrame15
        fontSize={fontSize}  {...props}

      />
    )
    : type === "dualicon16" ? (
      <DualIconFrame16
        fontSize={fontSize}  {...props}

      />
    ): type === "dualicon17" ? (
      <DualIconFrame17
        fontSize={fontSize}  {...props}

      />
    )
    : type === "dualicon18" ? (
      <DualIconFrame18
        fontSize={fontSize}  {...props}

      />
    ): type === "dualicon19" ? (
      <DualIconFrame19
        fontSize={fontSize}  {...props}

      />
    ): type === "dualicon20" ? (
      <DualIconFrame20
        fontSize={fontSize}  {...props}

      />
    ): type === "dualicon21" ? (
      <DualIconFrame21
        fontSize={fontSize}  {...props}

      />
    ): type === "dualicon22" ? (
      <DualIconFrame22
        fontSize={fontSize}  {...props}

      />
    ): type === "dualicon23" ? (
      <DualIconFrame23
        fontSize={fontSize}  {...props}

      />
    ): type === "dualicon24" ? (
      <DualIconFrame24
        fontSize={fontSize}  {...props}

      />
    ): type === "dualicon25" ? (
      <DualIconFrame25
        fontSize={fontSize}  {...props}

      />
    ): type === "dualicon26" ? (
      <DualIconFrame26
        fontSize={fontSize}  {...props}

      />
    ): type === "dualicon27" ? (
      <DualIconFrame27
        fontSize={fontSize}  {...props}

      />
    )
    : type === "dualicon28" ? (
      <DualIconFrame28
        fontSize={fontSize}  {...props}

      />
    )
    : type === "dualicon29" ? (
      <DualIconFrame29
        fontSize={fontSize}  {...props}

      />
    )
    : type === "dualicon30" ? (
      <DualIconFrame30
        fontSize={fontSize}  {...props}

      />
    )
    : type === "dualicon31" ? (
      <DualIconFrame31
        fontSize={fontSize}  {...props}

      />
    ): type === "dualicon32" ? (
      <DualIconFrame32
        fontSize={fontSize}  {...props}

      />
    )
    : type === "dualicon33" ? (
      <DualIconFrame33
        fontSize={fontSize}  {...props}

      />
    ): type === "dualicon34" ? (
      <DualIconFrame34
        fontSize={fontSize}  {...props}

      />
    ): type === "dualicon35" ? (
      <DualIconFrame35
        fontSize={fontSize}  {...props}

      />
    ): null 
    
  );
};

export default Frames;
export const SvgIcons = [
  {
    type: "icon0",
    dual: false,
    maxFontSize: 225,
    minFontSize: 63,
    svg: (<Icon0 />)
  },
  
  {
    type: "icon1",
    dual: false,
    maxFontSize: 225,
    minFontSize: 63,
    svg: (<Icon1 />)
  },
  {
    type: "icon2",
    dual: false,
    minFontSize: 62,
    maxFontSize: 224,
    svg: <Icon2 />
  },
  {
    type: "icon3",
    dual: false,
    minFontSize: 63,
    maxFontSize: 224,
    svg: <Icon3 />
  },
  {
    type: "icon4",
    dual: false,
    minFontSize: 65,
    maxFontSize: 225,
    svg: <Icon4 />
  },
  {
    type: "icon5",
    dual: false,
    minFontSize: 63,
    maxFontSize: 224,
    svg: <Icon5 />
  },
  {
    type: "dualicon6",
    dual: true,
    minFontSize: 60,
    maxFontSize: 224,
    svg: <DualIcon6 />
  },
  {
    type: "dualicon7",
    dual: true,
    minFontSize: 80,
    maxFontSize: 224,
    svg: <DualIcon7 />
  },
  {
    type: "dualicon8",
    dual: true,
    minFontSize: 76,
    maxFontSize: 224,
    svg: <DualIcon8 />
  },
  {
    type: "dualicon9",
    dual: true,
    minFontSize: 62,
    maxFontSize: 224,
    svg: <DualIcon9 />
  },
  {
    type: "dualicon10",
    dual: true,
    minFontSize: 57,
    maxFontSize: 224,
    svg: <DualIcon10 />
  },
  {
    type: "dualicon11",
    dual: true,
    minFontSize: 63,
    maxFontSize: 224,
    svg: <DualIcon11 />
  },
  {
    type: "dualicon12",
    dual: true,
    minFontSize: 108,
    maxFontSize: 300,
    svg: <DualIcon12 />
  },
  {
    type: "dualicon13",
    dual: true,
    minFontSize: 69,
    maxFontSize: 227,
    svg: <DualIcon13 />
  },
  {
    type: "dualicon14",
    dual: true,
    minFontSize: 110,
    maxFontSize: 380,
    svg: <DualIcon14 />
  },
  {
    type: "dualicon15",
    dual: true,
    minFontSize: 81,
    maxFontSize: 208,
    svg: <DualIcon15 />
  },
  {
    type: "dualicon16",
    dual: true,
    minFontSize: 85,
    maxFontSize: 300,
    svg: <DualIcon16 />
  },
  {
    type: "dualicon17",
    dual: true,
    minFontSize: 63,
    maxFontSize: 224,
    svg: <DualIcon17 />
  },
  {
    type: "dualicon18",
    dual: true,
    minFontSize: 100,
    maxFontSize: 334,
    svg: <DualIcon18 />
  },
  {
    type: "dualicon19",
    dual: true,
    minFontSize: 52,
    maxFontSize: 221,
    svg: <DualIcon19 />
  },
  {
    type: "dualicon20",
    dual: true,
    minFontSize: 95,
    maxFontSize: 300,
    svg: <DualIcon20 />
  },
  {
    type: "dualicon21",
    dual: false,
    minFontSize: 68,
    maxFontSize: 224,
    svg: (<DualIcon21 />)
  },
  {
    type: "dualicon22",
    dual: true,
    minFontSize: 68,
    maxFontSize: 226,
    svg: (<DualIcon22 />)
  },
  {
    type: "dualicon23",
    dual: true,
    minFontSize: 68,
    maxFontSize: 225,
    svg: (<DualIcon23 />)
  },
  {
    type: "dualicon24",
    dual: true,
    minFontSize: 68,
    maxFontSize: 225,
    svg: (<DualIcon24 />)
  },
  {
    type: "dualicon25",
    dual: true,
    minFontSize: 104,
    maxFontSize: 221,
    svg: (<DualIcon25 />)
  },
  {
    type: "dualicon26",
    dual: true,
    minFontSize: 63,
    maxFontSize: 170,
    svg: (<DualIcon26 />)
  },
  {
    type: "dualicon27",
    dual: true,
    minFontSize: 129,
    maxFontSize: 226,
    svg: (<DualIcon27 />)
  },
  {
    type: "dualicon28",
    dual: true,
    minFontSize: 99,
    maxFontSize: 226,
    svg: (<DualIcon28 />)
  },
  {
    type: "dualicon29",
    dual: true,
    minFontSize: 68,
    maxFontSize: 226,
    svg: (<DualIcon29 />)
  },
  {
    type: "dualicon30",
    dual: true,
    minFontSize: 111,
    maxFontSize: 223,
    svg: (<DualIcon30 />)
  },
  {
    type: "dualicon31",
    dual: true,
    minFontSize: 66,
    maxFontSize: 223,
    svg: (<DualIcon31 />)
  },
  {
    type: "dualicon32",
    dual: true,
    minFontSize: 69,
    maxFontSize: 224,
    svg: (<DualIcon32 />)
  },
  {
    type: "dualicon33",
    dual: true,
    minFontSize: 66,
    maxFontSize: 223,
    svg: (<DualIcon33 />)
  },
  {
    type: "dualicon34",
    dual: true,
    minFontSize: 68,
    maxFontSize: 224,
    svg: (<DualIcon34 />)
  },
  {
    type: "dualicon35",
    dual: true,
    minFontSize: 67,
    maxFontSize: 224,
    svg: (<DualIcon35 />)
  }
  
  
];