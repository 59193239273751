import React, { useContext, useRef, useState } from "react";
import * as yup from "yup";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link } from "react-router-dom";
import DeleteModal from "../../../modals/deleteModal";
import { createUrl } from "../../../utils";

import InputField from "../../common/inputField";
import DateField from "../../common/dateField";
import QrCodesAPI from "../../../services/http/qrCodes";
import { updateLinkValidation } from "../../../utils/validations/creatLinkValidation";
import QrCodeDetailsAnalytics from "./qrDetailsAnalytics";
import FaviconImage from "../../short-links/favicon";
import titleFetcher from "../../../utils/titleFetcher";
import imageDownloadHandler from "../../../utils/imageDownLoadHandler";
import { QrCodeContxt } from "../qrCodeContxt";
import { AuthContxt } from "../../../store/authContxt";
import { PLAN } from "../../../utils/enum";
import Frames from "../create-qr-code/frames-svg";
import Copy from "../../common/copy"

const EditQrCodeForm = ({ edittedData, edittedDataHandler }) => {
  const { quota } = useContext(AuthContxt);
  const { deleteQrCode, setQrCodes } = useContext(QrCodeContxt);
  const [editMode, setEditMode] = useState({
    title: false,
    destinationUrl: false,
    expirationDate: false,
  });

  const [loading, setLoading] = useState({
    title: false,
    destinationUrl: false,
    expirationDate: false,
  });

  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteModal, setDeleteModal] = useState();

  const [message, setMessage] = useState("");
  const messageHandler = (msg) => {
    setMessage(msg);
    setTimeout(() => {
      setMessage("");
    }, 5 * 1000);
  };

  const editModeHandler = (key, value) => {
    
    setEditMode((preState) => {
      return {
        ...preState,
        [key]: value,
      };
    });
  };

  const form = useForm({
    resolver: yupResolver(
      (edittedData?.qrType === "website" || edittedData?.qrType === "barcode")
        ? updateLinkValidation
        : yup.object().shape({}) // Fallback to an empty validation schema
    ),
    defaultValues: {
      ...edittedData,
    },
  });
  const svgRef = useRef();
  const { watch, setValue } = form;

  const { title, id, destinationUrl } = watch();

  const deleteQrCodeHandler = async () => {
    setDeleteModal(false);
    setDeleteLoading(true);
    await deleteQrCode(id);
    setDeleteLoading(false);
  };

  const submitHandler = async (type, data) => {
    
    if (
      type === "destinationUrl" &&
      data?.destinationUrl === edittedData.destinationUrl
    ) {
      editModeHandler("destinationUrl", false);
      return;
    }
    if (type === "title" && data?.title === edittedData.title) {
      editModeHandler("title", false);
      return;
    }
    if (
      type === "expirationDate" &&
      new Date(data?.expirationDate).toISOString() ===
        new Date(edittedData.expirationDate).toISOString()
    ) {
      editModeHandler("expirationDate", false);
      return;
    }
    if (data) {
      setLoading((preState) => ({ ...preState, [type]: true }));

      let newTitle = title;
      if (type === "destinationUrl") {
        try {
          const fetchedTitle = await titleFetcher(data?.destinationUrl);
          newTitle = fetchedTitle.title || title;
        } catch (e) {
          console.log("Error while fetching title: ", e.message);
        }
      }

      const auth_token = localStorage.getItem("auth_token");
      QrCodesAPI.updateQrCode(auth_token, edittedData?.id, {
        ...data,
        title: newTitle,
      })
        .then(({ data }) => {
          setQrCodes((preState) => {
            return preState.map((item) =>
              item.id === edittedData?.id ? { ...item, ...data.result } : item
            );
          });
          setValue("title", newTitle);
          if (type === "destinationUrl") {
            edittedDataHandler((preState) => {
              return { ...preState, destinationUrl: destinationUrl };
            });
          }
          editModeHandler(type, false);
          edittedDataHandler((preState) => ({
            ...preState,
            edit: data?.result?.edit,
          }));
        })
        .catch((error) => {
          messageHandler(
            error?.response?.data?.data?.message ||
              error?.response?.data?.message ||
              "Something went wrong"
          );
          setLoading((preState) => ({ ...preState, [type]: false }));
        })
        .finally(() => {
          setLoading((preState) => ({ ...preState, [type]: false }));
        });
    }
  };

  const { brandedDomain, slashTag, type } = edittedData;

  return (
    <>
      <div className="scroll-y me-lg-n7 pe-lg-7 pe-0">
        <div className="border-bottom pb-5">
          <div className="px-lg-20 border-bottom mb-2 pb-4 d-flex justify-content-between desktop-hide-f">
            <h2 className="fw-bold my-2 fs-2x">QR Analytics</h2>
            <i className="fa-light fa-xmark fs-4"></i>
          </div>
          <div className="d-flex justify-content-between mb-6">
            <div className="d-flex align-items-center">
              <span className="symbol symbol-30px" id="faviconlink">
                <FaviconImage list={edittedData} />
              </span>
              <form>
              <InputField
                  loading={loading.title}
                  submitHandler={submitHandler}
                  form={form}
                  fieldName={"title"}
                  editMode={
                    editMode?.title === true
                  }
                  editModeHandler={editModeHandler}
                />
              </form>
            </div>
            <div className="align-items-center card-toolbar d-flex  justify-content-center mobile-hide-f">
              <button
                disabled={deleteLoading}
                onClick={() => setDeleteModal(true)}
                type="button"
                className="btn btn-icon rounded-1 btn-light-info mx-2"
              >
                <i className="fa-regular fa-trash-can fs-3"></i>
              </button>
              <UncontrolledDropdown direction="down">
                <DropdownToggle className="btn btn-icon cursor-pointer rounded-1 btn-light-info mx-2 mobile-hide">
                  <i className="fa-regular fa-download fs-3"></i>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    onClick={() =>
                      imageDownloadHandler(
                        
                        { ...edittedData, height: "100%", width: "100%" },
                        "png"
                      )
                    }
                  >
                    Download PNG
                  </DropdownItem>
                  <DropdownItem
                    onClick={() =>
                      imageDownloadHandler(
                        
                        { ...edittedData, height: "100%", width: "100%" },
                        "jpg"
                      )
                    }
                  >
                    Download JPG
                  </DropdownItem>
                  <DropdownItem
                    onClick={() =>
                      imageDownloadHandler(
                        
                        { ...edittedData, height: "100%", width: "100%" },
                        "svg"
                      )
                    }
                  >
                    Download SVG
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          </div>

          <div className="separator mb-lg-6 mb-2"></div>
          <div className="d-flex justify-content-between">
       
            <div className="w-100 pe-lg-4">
            {
              editMode?.destinationUrl &&
              <div className="d-flex mobile-hide-f">
                <form>
                  <InputField
                    loading={loading.destinationUrl}
                    submitHandler={submitHandler}
                    form={form}
                    fieldName={"destinationUrl"}
                    editMode={editMode?.destinationUrl === true}
                    editModeHandler={editModeHandler}
                    editCount={
                      quota?.plan === PLAN?.STANDARD ? edittedData?.edit : null
                    }
                  />
                </form>
              </div>
            }

              {quota?.plan === PLAN.STANDARD ? (
                <div className="d-flex justify-content-end mb-3 link-info">
                  {`Remaining edit limit for destination url is ${
                    quota?.linkEdit - edittedData?.edit < 0
                      ? 0
                      : quota?.linkEdit - edittedData?.edit
                  }`}
                </div>
              ) : null}

<div className="d-flex align-items-center p-3 px-5 gap-5 border border-dark border-opacity-10 rounded-1 justify-content-between bg-light-info" >
                    <Link
                    target="_blank"
                    to={`https://${createUrl(brandedDomain, slashTag)}`}
                    className="fs-3 text-info fw-bold"
                  >
                    {createUrl(brandedDomain, slashTag)}
                  </Link>
                  <Copy copyData={`https://${createUrl(brandedDomain, slashTag)}`} />
                  </div>

              <div className="mt-6 d-flex">
                <form>
                  <DateField
                    loading={loading.expirationDate}
                    submitHandler={submitHandler}
                    form={form}
                    fieldName={"expirationDate"}
                    editMode={editMode?.expirationDate === true}
                    editModeHandler={editModeHandler}
                  />
                </form>
              </div>

              <div id="tagContainer" className="mt-6 d-flex gap-2">
                {edittedData?.tags?.split(",")?.map((tag) => {
                  return (
                    <span key={tag} className="badge badge-light">
                      {tag}
                    </span>
                  );
                })}
              </div>
            </div>
            <div className="mobile-hide">
              <div class="border border-gray-250">
                <div ref={svgRef}>
                  {edittedData?.frame ? (
                    <div className={`text-center rto-1 w-100px`}>
                    <Frames
                      type={edittedData?.frame.type}
                      minFontSize={edittedData?.frame.minFontSize}
                      maxFontSize={edittedData?.frame.maxFontSize}
                      height={"100%"}
                      url={edittedData?.qr}
                      primary={edittedData?.primary}
                      textColor={edittedData?.textColor}
                      secondary={edittedData?.secondary}
                      text={edittedData?.text}
                    />
                    </div>
                  ) : (
                    <img
                      src={edittedData?.qr}
                      alt="Qr code"
                      className="w-lg-100px w-100px mx-auto"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-end mb-3">
            {message ? (
              <span className="error-text text-danger">{message}</span>
            ) : null}
          </div>

          <QrCodeDetailsAnalytics
            shortlink={`https://${brandedDomain}/${slashTag}${
              type === "qr" ? "?r=qr" : ""
            }`}
          />
        </div>
      </div>

      {/* Modals */}
      <DeleteModal
        isOpen={deleteModal}
        modalHandler={() => setDeleteModal(false)}
        leftBtnHandler={deleteQrCodeHandler}
        rightBtnHandler={() => setDeleteModal(false)}
        data={{
          heading: "Delete shot link",
          description: "Are you sure you want to delete this short link?",
          leftBtn: "Yes, delete it",
          rightBtn: "Cancel",
        }}
      />
    </>
  );
};

export default EditQrCodeForm;
