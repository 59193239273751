import React, { useEffect } from "react";
import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5percent from "@amcharts/amcharts5/percent";

const PieChart = ({ data = [], id, fields }) => {
  useEffect(() => {
    const root = am5.Root.new(id);
    root._logo.dispose();
    root.setThemes([am5themes_Animated.new(root)]);

    const chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        layout: root.verticalLayout,
      })
    );

    const series = chart.series.push(
      am5percent.PieSeries.new(root, {
        name: "Series",
        valueField: fields.value,
        categoryField: fields.label,
        alignLabels: false,
      })
    );

    // Define custom colors
    const colorSet = am5.ColorSet.new(root, {
      colors: [
        am5.color("#FFA755"),
        am5.color("#FED479"),
        am5.color("#E76D78"),
        am5.color("#88E1C9"),
        am5.color("#82DAE1"),
        am5.color("#60C1EB"),
      ],
    });
    series.set("colors", colorSet);

    series.data.setAll(data);

    series.labels.template.setAll({
      fontSize: 7,
      text: "{name}:[bold]{valuePercentTotal.formatNumber('0.00')}%",
      alignLabels: false,
    });

    const legend = chart.children.push(
      am5.Legend.new(root, {
        centerX: am5.percent(50),
        x: am5.percent(50),
        layout: root.horizontalLayout,
      })
    );

    legend.data.setAll(series.dataItems);

    return () => {
      root.dispose();
    };
  }, [data, id, fields]);

  return <div id={id} className="min-h-auto h-250px"></div>;
};

export default PieChart;
