import { useContext, useEffect, useState } from "react";
import { AuthContxt } from "../../store/authContxt";
import {
  MONTHLY_STANDARD,
  MONTHLY_STARTER,
  MONTHLY_SUPER,
  YEARLY_STANDARD,
  YEARLY_STARTER,
  YEARLY_SUPER,
} from "../../utils/planLookUpKeys";
import capitalizeFirstLetter from "../../utils/captilizeFirstLetter";
import { PLAN_DURATION } from "../../utils/enum";
import { Tooltip } from "reactstrap";

export const CONTENT_MAPPER = {
  [MONTHLY_STARTER]: {
    type: "Starter",
  },
  [MONTHLY_STANDARD]: {
    type: "Standard",
  },
  [MONTHLY_SUPER]: {
    type: "Super",
  },
  [YEARLY_STARTER]: {
    type: "Starter",
  },
  [YEARLY_STANDARD]: {
    type: "Standard",
  },
  [YEARLY_SUPER]: {
    type: "Super",
  },
};

const typeMapper = {
  starter: 0,
  standard: 1,
  super: 2,
};

const MON = "monthly";
const ANN = "yearly";

const Plan = ({ planHandler, planPrices, form }) => {
  console.log('Plan Prices:', planPrices); // <-- Add this line
  const { profile } = useContext(AuthContxt);
  const [curPlan, setCurPlan] = useState(0);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [planDuration, setPlanDuration] = useState("monthly");
  const { watch } = form || {};
  const { plan } = watch();

  const isMon = planDuration === MON;
  const subscribedPlanDuration = profile?.plan?.split("-")[0];
  console.log(subscribedPlanDuration, "subscribeplan" )
  const planDurationHandler = (val) => {
    setPlanDuration(val);
    const selectedPlanType = plan?.split("-")[1];
    planHandler(`${val}-${selectedPlanType}`);
    setCurPlan(typeMapper[selectedPlanType]);
    console.log(selectedPlanType, "plamn type here")
  };

  const toggle = () => {
    if (subscribedPlanDuration === PLAN_DURATION.YEARLY) {
      setTooltipOpen(!tooltipOpen);
    }
  };

  useEffect(() => {
    if (profile?.plan) {
      planHandler(profile.plan);
      setPlanDuration(profile?.plan?.includes("yearly") ? ANN : MON);

      const type = profile?.plan?.split("-")[1];
      setCurPlan(typeMapper[type]);
    }
  }, [profile]);

  const currencyMapper = {
    usd: "$",
  };
  console.log(  "||" ,subscribedPlanDuration,"||",profile.plan, "||" ,plan);


  return (
    <>
      <div>
        <div className="mb-5">
          <h2 className="mb-3">Upgrade a Plan</h2>
          <div className="text-muted fw-semibold fs-5">
            {`If you need more info, please check `}
            <span className="link-info fw-bold">Pricing Guidelines</span>.
          </div>
        </div>

        <div
          class="nav-group nav-group-outline"
          style={{ width: "fit-content", display: "flex" }}
        >
          <div onMouseOver={toggle} onMouseLeave={toggle} id="monthlyTab">
            <button
              type="button"
              disabled={subscribedPlanDuration === PLAN_DURATION.YEARLY}
              onClick={() => {
                subscribedPlanDuration !== PLAN_DURATION.YEARLY &&
                  planDurationHandler(MON);
              }}
              class={`btn btn-color-gray-400 btn-active btn-active-light-info rounded-1 px-6 py-3 me-2 ${
                planDuration === MON ? "active" : ""
              }`}
            >
              Monthly
            </button>
          </div>

          <Tooltip
            placement={"top"}
            isOpen={tooltipOpen}
            target={"monthlyTab"}
            toggle={toggle}
          >
            {`You already subscribed to Yearly plan, can't switch to Monthly plan!`}
          </Tooltip>

          <button
            type="button"
            onClick={() => planDurationHandler(ANN)}
            class={`btn btn-color-gray-400 btn-active btn-active-light-info rounded-1 px-6 py-3 ${
              planDuration === ANN ? "active" : ""
            }`}
          >
            Annual
          </button>
        </div>
      </div>

      <div className="row px-4 gap-5 mt-10">
        {planPrices?.map((item, index) => {
          const { currency = "", monthlyPrice, yearlyPrice, plan } = item || {};
          return (
            <label
              className={`nav-link col btn rounded-1 btn-outline btn-outline-dashed btn-color-dark btn-active btn-active-light-info border-info d-flex flex-stack text-start p-6 mb-6 ${
                curPlan === index || curPlan === item ? "active" : ""
              }`}
            >
              <div className="d-flex align-items-center me-2">
                <div className="form-check form-check-custom form-check-solid form-check-info flex-shrink-0 me-5">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="plan"
                    checked={curPlan === index || curPlan === item}
                    readOnly
                    onClick={() => {
                      planHandler(`${isMon ? "monthly" : "yearly"}-${plan}`);
                      setCurPlan(index);
                    }}
                  />
                </div>

                <div className="flex-grow-1">
                  <div className="d-flex align-items-center fs-2 fw-bold flex-wrap">
                    {capitalizeFirstLetter(plan)}
                  </div>
                </div>
              </div>

              <div className="ms-5">
                <span className="mb-2">
                  {currencyMapper[currency] || currency}
                </span>
                <span className="fs-3x fw-bold">
                  {isMon ? monthlyPrice : yearlyPrice}
                </span>
                <span className="fs-7 opacity-50">
                  <span data-kt-element="period">{`/ Mon`}</span>
                </span>
              </div>
            </label>
          );
        })}
      </div>
    </>
  );
};

export default Plan;




// import { useContext, useEffect, useState } from "react";
// import { AuthContxt } from "../../store/authContxt";
// import {
//   MONTHLY_STANDARD,
//   MONTHLY_STARTER,
//   MONTHLY_SUPER,
//   YEARLY_STANDARD,
//   YEARLY_STARTER,
//   YEARLY_SUPER,
// } from "../../utils/planLookUpKeys";
// import capitalizeFirstLetter from "../../utils/captilizeFirstLetter";
// import { PLAN_DURATION } from "../../utils/enum";
// import { Tooltip } from "reactstrap";

// export const CONTENT_MAPPER = {
//   [MONTHLY_STARTER]: {
//     type: "Starter",
//   },
//   [MONTHLY_STANDARD]: {
//     type: "Standard",
//   },
//   [MONTHLY_SUPER]: {
//     type: "Super",
//   },
//   [YEARLY_STARTER]: {
//     type: "Starter",
//   },
//   [YEARLY_STANDARD]: {
//     type: "Standard",
//   },
//   [YEARLY_SUPER]: {
//     type: "Super",
//   },
// };

// const typeMapper = {
//   starter: 0,
//   standard: 1,
//   super: 2,
// };

// const MON = "monthly";
// const ANN = "yearly";

// const Plan = ({ planHandler, planPrices, form }) => {
//   console.log("Plan Prices:", planPrices);
//   const { profile } = useContext(AuthContxt);
//   const [curPlan, setCurPlan] = useState(0);
//   const [tooltipOpen, setTooltipOpen] = useState(false);
//   const [planDuration, setPlanDuration] = useState("monthly");
//   const { watch } = form || {};
//   const { plan } = watch();

//   const isMon = planDuration === MON;
//   const subscribedPlanDuration = profile?.plan?.split("-")[0];
//   const planDurationHandler = (val) => {
//     setPlanDuration(val);
//     const selectedPlanType = plan?.split("-")[1];
//     planHandler(`${val}-${selectedPlanType}`);
//     setCurPlan(typeMapper[selectedPlanType]);
//     console.log(selectedPlanType, "plan type here");
//   };

//   const toggle = () => {
//     if (subscribedPlanDuration === PLAN_DURATION.YEARLY) {
//       setTooltipOpen(!tooltipOpen);
//     }
//   };

//   useEffect(() => {
//     if (profile?.plan) {
//       planHandler(profile.plan);
//       setPlanDuration(profile?.plan?.includes("yearly") ? ANN : MON);

//       const type = profile?.plan?.split("-")[1];
//       setCurPlan(typeMapper[type]);
//     }
//   }, [profile]);

//   const currencyMapper = {
//     usd: "$",
//   };
//   console.log("||", subscribedPlanDuration, "||", profile.plan, "||", plan);

//   return (
//     <>
//       <div>
//         <div className="mb-5">
//           <h2 className="mb-3">Upgrade a Plan</h2>
//           <div className="text-muted fw-semibold fs-5">
//             {`If you need more info, please check `}
//             <span className="link-info fw-bold">Pricing Guidelines</span>.
//           </div>
//         </div>

//         <div
//           class="nav-group nav-group-outline"
//           style={{ width: "fit-content", display: "flex" }}
//         >
//           <div onMouseOver={toggle} onMouseLeave={toggle} id="monthlyTab">
//             <button
//               type="button"
//               disabled={subscribedPlanDuration === PLAN_DURATION.YEARLY}
//               onClick={() => {
//                 subscribedPlanDuration !== PLAN_DURATION.YEARLY &&
//                   planDurationHandler(MON);
//               }}
//               class={`btn btn-color-gray-400 btn-active btn-active-light-info rounded-1 px-6 py-3 me-2 ${
//                 planDuration === MON ? "active" : ""
//               }`}
//             >
//               Monthly
//             </button>
//           </div>

//           <Tooltip
//             placement={"top"}
//             isOpen={tooltipOpen}
//             target={"monthlyTab"}
//             toggle={toggle}
//           >
//             {`You already subscribed to Yearly plan, can't switch to Monthly plan!`}
//           </Tooltip>

//           <button
//             type="button"
//             onClick={() => planDurationHandler(ANN)}
//             class={`btn btn-color-gray-400 btn-active btn-active-light-info rounded-1 px-6 py-3 ${
//               planDuration === ANN ? "active" : ""
//             }`}
//           >
//             Annual
//           </button>
//         </div>
//       </div>

//       <div className="row px-4 gap-5 mt-10">
//         {planPrices?.map((item, index) => {
//           const { currency = "", monthlyPrice, yearlyPrice, plan } = item || {};
//           const isCurrentPlanAnnual = subscribedPlanDuration === PLAN_DURATION.YEARLY;
//           const isCurrentPlanMonthly = subscribedPlanDuration === PLAN_DURATION.MONTHLY;

//           const isPlanSelected = curPlan === index || curPlan === item;
          
//           // Determine if the option should be disabled based on the current plan
//           const isPlanDisabled = 
//             (isCurrentPlanAnnual && (planDuration === MON || index < curPlan)) ||
//             (isCurrentPlanMonthly && planDuration === MON && index < curPlan);

//           return (
//             <label
//               key={plan}
//               className={`nav-link col btn rounded-1 btn-outline btn-outline-dashed btn-color-dark btn-active btn-active-light-info border-info d-flex flex-stack text-start p-6 mb-6 ${
//                 isPlanSelected ? "active" : ""
//               } ${isPlanDisabled ? "disabled" : ""}`}
//             >
//               <div className="d-flex align-items-center me-2">
//                 <div className="form-check form-check-custom form-check-solid form-check-info flex-shrink-0 me-5">
//                   <input
//                     className="form-check-input"
//                     type="radio"
//                     name="plan"
//                     checked={isPlanSelected}
//                     readOnly
//                     onClick={() => {
//                       if (!isPlanDisabled) {
//                         planHandler(`${isMon ? "monthly" : "yearly"}-${plan}`);
//                         setCurPlan(index);
//                       }
//                     }}
//                   />
//                 </div>

//                 <div className="flex-grow-1">
//                   <div className="d-flex align-items-center fs-2 fw-bold flex-wrap">
//                     {capitalizeFirstLetter(plan)}
//                   </div>
//                 </div>
//               </div>

//               <div className="ms-5">
//                 <span className="mb-2">
//                   {currencyMapper[currency] || currency}
//                 </span>
//                 <span className="fs-3x fw-bold">
//                   {isMon ? monthlyPrice : yearlyPrice}
//                 </span>
//                 <span className="fs-7 opacity-50">
//                   <span data-kt-element="period">{`/ ${planDuration === MON ? "Mon" : "Year"}`}</span>
//                 </span>
//               </div>
//             </label>
//           );
//         })}
//       </div>
//     </>
//   );
// };

// export default Plan;
