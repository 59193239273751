import { createContext, useContext, useEffect, useState } from "react";
import WhatsappCampaignAPI from "../../services/http/whatsappCampaign";
import { AuthContxt } from "../../store/authContxt";

export const WhatsappCampaignContxt = createContext(null);

const WhatsappCampaignContxtProvider = (props) => {
  const { fetchQuota } = useContext(AuthContxt);
  const [loading, setLoading] = useState(true);
  const [whatsappCampaigns, setWhatsappCampaigns] = useState([]);
  const [notLinksScreen, setNotLinksScreen] = useState("");
  const [search, setSearch] = useState();
  const [status, setStatus] = useState("All Statuses");
  const [filters, setFilters] = useState({});
  const [currentCampaign, setCurrentCampaign] = useState({});

  const getWhatsappCampaigns = async (params) => {
    const auth_token = localStorage.getItem("auth_token");
    setLoading(true);
    WhatsappCampaignAPI.getAllWhatsappCampaigns(auth_token, params)
      .then(({ data }) => {
        setWhatsappCampaigns(data.resultData);
        data.resultData.length < 1 && setNotLinksScreen("notFound");
      })
      .catch((error) => {
        console.log("Error while fetching shortlinks", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getWhatsappCampaigns();
  }, []);

  return (
    <WhatsappCampaignContxt.Provider
      value={{
        whatsappCampaigns,
        setWhatsappCampaigns,
        getWhatsappCampaigns,
        loading,
        setLoading,
        notLinksScreen,
        setNotLinksScreen,
        search,
        setSearch,
        filters,
        setFilters,
        status,
        setStatus,
        currentCampaign,
        setCurrentCampaign,
      }}
    >
      {props.children}
    </WhatsappCampaignContxt.Provider>
  );
};

export default WhatsappCampaignContxtProvider;
