import dayjs from "dayjs";
import { useState } from "react";
import EditUser from "./newUser";
import DeleteModal from "../../../modals/deleteModal";
import UserSettingsApi from "../../../services/http/userSettings";
import relativeTime from "dayjs/plugin/relativeTime";
import { USER_TYPE } from "../../../utils/enum";
import { extractNameFromEmail } from "../../../utils/extractName";

const User = ({ user, usersHandler, userSelectorHandler }) => {
  const {
    id,
    avatar,
    firstName = "",
    lastName = "",
    email = "",
    userType,
    createdAt,
    lastLogin,
  } = user || {};
  const name = `${firstName ? firstName : ""} ${lastName ? lastName : ""}`.trim() || extractNameFromEmail(email);

  const [editUser, setEditUser] = useState(false);
  const [deleteUser, setDeleteUser] = useState(false);
  const [loading, setLoading] = useState(false);

  const deleteUserHandler = async () => {
    try {
      setDeleteUser(false);
      setLoading(true);
      const { data } = await UserSettingsApi.deleteUser(user?.id);
      if (data?.data) {
        usersHandler("delete", { id: user?.id });
      }
      setLoading(false);
    } catch (e) {
      console.log("Error while deleting user: ", e);
      setLoading(false);
    }
  };

  const getUpdatedTime = () => {
    dayjs.extend(relativeTime);
    return lastLogin !== null ? dayjs(lastLogin).fromNow() : "-";
  };

  const isAccountAdmin = user?.userType === USER_TYPE.ACCOUNT_ADMIN;

  return (
    <>
      <tr className="link-row border border-right-0 border-top-0 border-left-0">
        {/* <td className="ps-6">
          <div className="form-check form-check-sm form-check-custom form-check-info form-check-solid rounded-1">
            {!isAccountAdmin ? (
              <input
                className="form-check-input rounded-1"
                type="checkbox"
                checked={user?.isSelected}
                onChange={() => userSelectorHandler(id)}
              />
            ) : null}
          </div>
        </td> */}
        <td className="d-flex align-items-center">
          <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
            <span>
              <div className="symbol-label">
                <img
                  src={avatar || "/placeholder.png"}
                  alt={name}
                  className="w-100"
                />
              </div>
            </span>
          </div>

          <div className="d-flex flex-column">
            <span className="text-gray-800 text-hover-primary mb-1">
              {name}
            </span>
            <span>{email}</span>
          </div>
        </td>
        <td className="text-start pe-0">
          <span className="fw-bold fs-7 text-black badge bg-light text-gray-600 border">
            {isAccountAdmin ? "Account Admin" : userType}
          </span>
        </td>
        <td>
          <div className="badge badge-light text-black fw-bold">
            {getUpdatedTime()}
          </div>
        </td>
        <td className="text-start pe-0 text-black">
          {dayjs(createdAt).format("MMM DD, YYYY")}
        </td>

        <td className="ps-0">
          <div className="d-flex">
            <button
              disabled={isAccountAdmin}
              className="btn px-3"
              onClick={() => {
                !isAccountAdmin && setEditUser(true);
              }}
            >
              <i className="fa-regular fa-pen-to-square text-gray-800 fs-2"></i>
            </button>
            <button
              className="btn px-3"
              disabled={loading || isAccountAdmin}
              onClick={() => {
                !isAccountAdmin && setDeleteUser(true);
              }}
            >
              <i className="fa-regular fa-trash-can text-danger fs-2"></i>
            </button>
          </div>
        </td>
      </tr>

      {editUser ? (
        <EditUser
          edittedUser={user}
          toggleHandler={() => setEditUser(false)}
          usersHandler={usersHandler}
        />
      ) : null}

      <DeleteModal
        isOpen={deleteUser}
        modalHandler={() => setDeleteUser(false)}
        leftBtnHandler={deleteUserHandler}
        rightBtnHandler={() => setDeleteUser(false)}
        data={{
          heading: "Delete User",
          description: "Are you sure you want to delete this user ?",
          leftBtn: "Yes, delete it",
          rightBtn: "Cancel",
        }}
      />
    </>
  );
};

export default User;
