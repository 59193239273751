import React from 'react'

const IconFrame5 = ({ primary,  secondary, fontSize, text, textColor, type, url, width, height, minFontSize, maxFontSize }) => {
  return (
    <>
 
<svg width={width || 3923} height={height || 2700} viewBox="0 0 3923 2700" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_177_24775)">

<text fill={textColor || "black"} xmlSpace="preserve" style={{ whiteSpace: "pre" }} fontFamily="Arial" fontSize={fontSize || 224} fontWeight="bold" letterSpacing="0em">
      <tspan x="25%" textAnchor='middle' y="174.116">{text}</tspan>
    </text>

<path fill-rule="evenodd" clip-rule="evenodd" d="M3238.93 1972.26C3039.98 1972.26 2879.17 2133.24 2879.17 2332.07C2879.17 2531.04 3039.98 2692.03 3238.93 2692.03C3437.73 2692.03 3598.69 2531.04 3598.69 2332.07C3598.69 2133.24 3437.73 1972.26 3238.93 1972.26ZM3238.93 2470.01C3163.43 2470.01 3101.16 2407.73 3101.16 2332.06C3101.16 2256.55 3163.43 2194.28 3238.93 2194.28C3314.43 2194.28 3376.7 2256.55 3376.7 2332.06C3376.7 2407.73 3314.43 2470.01 3238.93 2470.01Z" fill={primary || "white"}/>
<path d="M3891.87 2004.2H3814.03V1661.68C3814.03 1546.46 3728.1 1459.27 3611.65 1459.27H3238.03C3230.25 1459.27 3220.91 1455.07 3215.62 1449.93L2633.39 883.05C2607.24 856.893 2575.48 837.743 2537.5 836.497H2117.18V2268.88H2739.87C2754.2 2268.88 2768.36 2250.82 2771.01 2237.74C2810.24 2012.61 3001.87 1841.81 3238.65 1841.81C3475.59 1841.81 3671.74 2013.23 3710.97 2238.37C3713.62 2252.85 3737.44 2268.88 3751.76 2268.88H3782.89C3857.46 2268.88 3923 2201.78 3923 2128.45V2031.6C3923 2017.28 3907.59 2004.2 3891.87 2004.2ZM2942.25 1459.27H2303.99C2288.26 1459.27 2272.85 1443.86 2272.85 1428.13L2272.23 1025.67C2272.23 1009.94 2288.26 992.191 2303.99 992.191H2506.36C2514.15 992.191 2530.02 1000.75 2536.56 1006.05L2957.82 1412.56C2973.54 1432.18 2967.16 1459.27 2942.25 1459.27Z" fill={secondary || "#0e1b21"}/>
<path d="M0 2135.61V276H1992.63V2268.88H1478.91C1464.59 2268.88 1448.55 2252.85 1445.9 2238.37C1406.67 2013.23 1210.37 1857.23 973.588 1857.23C736.807 1857.23 537.388 2012.61 498.158 2237.74C495.512 2250.82 481.346 2268.88 467.023 2268.88H124.54C54.7974 2268.88 0 2205.36 0 2135.61Z" fill={secondary || "#0e1b21"}/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M973.588 1980.23C774.636 1980.23 613.824 2141.21 613.824 2340.04C613.824 2539.01 774.636 2700 973.588 2700C1172.38 2700 1333.35 2539.01 1333.35 2340.04C1333.35 2141.21 1172.38 1980.23 973.588 1980.23ZM973.589 2477.98C898.087 2477.98 835.817 2415.7 835.817 2340.03C835.817 2264.52 898.087 2202.25 973.589 2202.25C1049.09 2202.25 1111.36 2264.52 1111.36 2340.03C1111.36 2415.7 1049.09 2477.98 973.589 2477.98Z" fill={primary || "white"}/>
<rect x="339" y="423" width="1316" height="1316" rx="50" fill="white"/>
</g>
<defs>
<clipPath id="clip0_177_24775">
<rect width="3923" height="2700" fill="white"/>
</clipPath>
</defs>

<image href={url} x="425" y="509" width="1144" height="1144" />
</svg>
 

    </>
  )
}

export default IconFrame5