import { useState, useEffect, useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContxt } from '../../store/authContxt';
import { slides } from './slidesData';

export const useOnBoardingLogic = (onComplete, setIsModalOpen) => {
    console.log("useOnBoardingLogic initialized");
    const navigate = useNavigate();
    const { profile } = useContext(AuthContxt);
    const [loading, setLoading] = useState(false);
    const [localTourState, setLocalTourState] = useState({
        demoTour1: false,
        demoTour2: false,
        demoTour3: false,
    });

    const getActiveSlides = useCallback(() => {
        const userEvents = profile?.userEvents || {};
        const sessionTourState = JSON.parse(sessionStorage.getItem('tourState') || '{}');

        return slides.filter(slide => 
            !sessionTourState[slide.key] && !userEvents[`${slide.key}_created`]
        );
    }, [profile]);

    const [activeSlides, setActiveSlides] = useState(getActiveSlides());
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const initializeTourState = () => {
            if (!sessionStorage.getItem('tourState')) {
                sessionStorage.setItem('tourState', JSON.stringify({
                    demoTour1: false,
                    demoTour2: false,
                    demoTour3: false,
                }));
            }

            const sessionTourState = JSON.parse(sessionStorage.getItem('tourState'));
            console.log('Raw sessionStorage value:', sessionTourState);

            const userEvents = profile?.userEvents || {};
            console.log('Profile userEvents:', userEvents);

            const updatedTourState = {
                demoTour1: sessionTourState.demoTour1 || userEvents.first_short_link_created || false,
                demoTour2: sessionTourState.demoTour2 || userEvents.first_qr_code_created || false,
                demoTour3: sessionTourState.demoTour3 || userEvents.first_link_in_bio_created || false,
            };

            console.log('Updated tour state:', updatedTourState);

            setLocalTourState(updatedTourState);
            sessionStorage.setItem('tourState', JSON.stringify(updatedTourState));

            console.log('Session storage after setting:', JSON.parse(sessionStorage.getItem('tourState')));

            // Open modal based on updated state
            const shouldOpenModal = Object.values(updatedTourState).some(tour => !tour);
            setIsModalOpen(shouldOpenModal);
        };

        if (profile) {
            initializeTourState();
        }
    }, [profile, setIsModalOpen]);

    useEffect(() => {
        const newActiveSlides = getActiveSlides();
        setActiveSlides(newActiveSlides);
        if (newActiveSlides.length === 0) onComplete(); // Call onComplete if no active slides
    }, [localTourState, getActiveSlides, onComplete]);

    const SkipDemo = useCallback((demo) => {
        setLoading(true);
        setLocalTourState(prev => {
            const updated = { ...prev, [demo]: true };
            console.log('Setting new tourState in sessionStorage:', updated);
            sessionStorage.setItem('tourState', JSON.stringify(updated));
            return updated;
        });
        setLoading(false);
    }, []);

    const handleCreate = (feature) => {
        const route = {
            "shortlink": "/short-links",
            "qr": "/qr-codes",
            "bio": "/link-in-bio"
        }[feature];

        const sessionTourState = JSON.parse(sessionStorage.getItem('tourState') || '{}');
        if (route) {
            // Update tour state based on the created feature
            Object.keys(sessionTourState).forEach(tour => {
                sessionTourState[tour] = true; // Mark all tours as complete
            });
        }

        sessionStorage.setItem('tourState', JSON.stringify(sessionTourState));
        navigate(route, { state: { openModal: false } });
        setIsModalOpen(false);
    };

    return { loading, activeSlides, currentIndex, SkipDemo, handleCreate };
};
