import React, { useContext, useEffect, useState } from "react";
import { Card, CardBody, Modal, ModalBody, Spinner } from "reactstrap";
import ShortLinksHeader from "./shortLinksHeader/index";
import ShortLinksTable from "./shortLinkTable/index";
import AddBulkShortLinks from "./addBulkLinks/addBulkShortLinks";
import ShortLinksAPI from "../../services/http/shortLinks";
import AddNewShortLink from "./addNew";
import Sidebar from "../common/sidebar";
import { ShortLinkContxt } from "./shortLinkContxt";
import { AuthContxt } from "../../store/authContxt";
import OnBoarding from '../../pages/onboarding/OnBoarding';
import useOnboarding from "../../hooks/useOnboarding";
import { useLocation } from "react-router-dom";


const ShortLinksMain = () => {
    const { profile, fetchQuota } = useContext(AuthContxt);
    const { tags, shortLinks, setShortLinks, setNotLinksScreen } = useContext(ShortLinkContxt);
    const location = useLocation();
    const currentRoute = location.pathname;
    const { isModalOpen, handleOnboardingComplete, toggleModal, setIsModalOpen  } = useOnboarding(currentRoute);

    const [hasModalOpened, setHasModalOpened] = useState(false);
    const [filtersLoading, setFiltersLoading] = useState(false);
    const [addBulk, setAddBulk] = useState(false);
    const [isAddNewLinkOpen, setIsAddNewLinkOpen] = useState(false);

    const addBulkClose = () => setAddBulk(false);
    const addBulkShow = () => setAddBulk(true);

    const shortlinksHandler = async (filters) => {
      setFiltersLoading(true);
      const auth_token = localStorage.getItem("auth_token");
  
      console.log("shortlinksHandler called with filters:", filters); // Log when function is called
  
      if (!auth_token) {
          console.error("No auth token found in localStorage");
          setFiltersLoading(false);
          return;
      }
  
      try {
          const { starred, ...rest } = filters;
          console.log("Calling API with filters:", rest); // Log before making API call
  
          const { data } = await ShortLinksAPI.filteredShortLinks(auth_token, {
              ...rest,
              ...(starred ? { starred } : {}),
              tags: filters.tags.join(","),
          });
  
          console.log("Fetched short links:", data); // Log fetched data
  
          // Check if data is in the expected structure
          if (data && data.resultData && Array.isArray(data.resultData)) {
              setShortLinks(data.resultData);
              if (data.resultData.length < 1) {
                  setNotLinksScreen("searchFound");
              } else {
                  setNotLinksScreen(""); // Clear any previous not found state
              }
          } else {
              console.error("Unexpected data structure:", data); // Log unexpected structure
              setShortLinks([]); // Clear short links if unexpected structure
              setNotLinksScreen("searchFound"); // Handle no links found
          }
      } catch (error) {
          console.error("Error while fetching shortlinks:", error); // Log API error
          setNotLinksScreen("searchFound"); // Handle error
      } finally {
          setFiltersLoading(false);
      }
  };
  

   
    return (
        <div className="w-full d-flex">
            <div className="mt-md-10 w-[280px] me-md-5 mt-xs-0 me-xs-0">
                <Sidebar />
            </div>
            <div className="customDashboardSize">
                <div className="d-flex flex-column w-100 flex-root app-root">
                    <div className="app-page flex-column flex-column-fluid">
                        <div className="app-wrapper flex-column flex-row-fluid pe-3 pe-lg-0 me-lg-5 mt-lg-5 mt-0 position-relative">
                            <div className="app-main flex-column flex-row-fluid">
                                <Card className="card card-flush rounded-sm-0">
                                    <ShortLinksHeader
                                        tags={tags}
                                        shortlinksHandler={shortlinksHandler}
                                        addBulkShow={addBulkShow}
                                        loading={filtersLoading}
                                        setIsAddNewLinkOpen={setIsAddNewLinkOpen}
                                        count={shortLinks?.length}
                                    />
                                   <CardBody className="card-body px-6 px-lg-8 pt-0 overflow-x-auto" style={{ minHeight: "200px" }}>
  
        <ShortLinksTable
            shortLinks={shortLinks}
            setIsAddNewLinkOpen={setIsAddNewLinkOpen}
        />
   
</CardBody>

                                </Card>
                            </div>
                        </div>
                    </div>
                </div>
                <AddNewShortLink
                    isAddNewLinkOpen={isAddNewLinkOpen}
                    setIsAddNewLinkOpen={setIsAddNewLinkOpen}
                />
                <AddBulkShortLinks addBulk={addBulk} addBulkClose={addBulkClose} />
            </div>
            <div className='onboarding-styles'>
            <Modal isOpen={isModalOpen} toggle={toggleModal} className="custom-modal">

                    <ModalBody>
                    <OnBoarding onComplete={handleOnboardingComplete}  />
                    </ModalBody>
                </Modal>
            </div>
        </div>
    );
};

export default ShortLinksMain;
