import React, { useContext, useState } from "react";
import ShortLinksAPI from "../../../services/http/shortLinks";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link } from "react-router-dom";
import { updateLinkValidation } from "../../../utils/validations/creatLinkValidation";
import ShareDropdown from "../shortLinkTable/shareDropDown";
import DeleteModal from "../../../modals/deleteModal";
import ShortlinkAnalytics from "./shortlinkAnalytics";

import InputField from "../../common/inputField";
import DateField from "../../common/dateField";
import FaviconImage from "../favicon";
import Copy from "../../common/copy";
import titleFetcher from "../../../utils/titleFetcher";
import { getUtmQuery } from "../../../utils";
import { ShortLinkContxt } from "../shortLinkContxt";
import useCustomMessage from "../../../hooks/useCustomMessage";
import { AuthContxt } from "../../../store/authContxt";
import { PLAN } from "../../../utils/enum";
import { Input, Label } from "reactstrap";

const EditShortLinkForm = ({
  edittedData,
  edittedDataHandler,
  setEditShortLink,
}) => {  
  const { quota } = useContext(AuthContxt);
  const { message, messageHandler } = useCustomMessage();
  const auth_token = localStorage.getItem("auth_token");

  const { deleteLinkHandler, starredLinkHandler, setShortLinks } =
    useContext(ShortLinkContxt);
  const [editMode, setEditMode] = useState({
    title: false,
    destinationUrl: false,
    expirationDate: false,
  });

  const [loading, setLoading] = useState({
    title: false,
    destinationUrl: false,
    expirationDate: false,
  });

  const [starredLoading, setStarredLoading] = useState(false);
  // Delete Link States
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteModal, setDeleteModal] = useState();

  const editModeHandler = (key, value) => {
    setEditMode((preState) => {
      return {
        ...preState,
        [key]: value,
      };
    });
  };

  const form = useForm({
    resolver: yupResolver(updateLinkValidation),
    defaultValues: {
      ...edittedData,
    },
  });

  const { watch, setValue } = form;
  const { destinationUrl, title, expirationDate, passwordProtectionEnabled } =
    watch();
console.log(destinationUrl,"bhnchod");
  const submitHandler = async (type, data) => {
    if (
      type === "destinationUrl" &&
      data?.destinationUrl === edittedData.destinationUrl
    ) {
      editModeHandler("destinationUrl", false);
      return;
    }
    if (type === "title" && data?.title === edittedData.title) {
      editModeHandler("title", false);
      return;
    }
    if (
      type === "expirationDate" &&
      new Date(data?.expirationDate).toISOString() ===
        new Date(edittedData.expirationDate).toISOString()
    ) {
      editModeHandler("expirationDate", false);
      return;
    }
    if (data) {
      setLoading((preState) => ({ ...preState, [type]: true }));
      let newTitle = title;
      if (type === "destinationUrl") {
        try {
          const fetchedTitle = await titleFetcher(data?.destinationUrl);
          newTitle = fetchedTitle.title || title;
        } catch (e) {
          console.log("Error while fetching title: ", e.message);
        }
      }
      ShortLinksAPI.updateShortLink(auth_token, edittedData?.id, {
        ...data,
        title: newTitle.trim(),
      })
        .then(async ({ data }) => {
          setShortLinks((preState) => {
            return preState.map((item) =>
              item.id === edittedData?.id
                ? { ...item, ...data?.result, title: newTitle.trim() }
                : item
            );
          });
          setValue("title", newTitle.trim());
          if (type === "destinationUrl") {
            edittedDataHandler((preState) => {
              return { ...preState, destinationUrl: destinationUrl };
            });
          }
          edittedDataHandler((preState) => ({
            ...preState,
            edit: data?.result.edit,
          }));
        })
        .catch((error) => {
          setLoading((preState) => ({ ...preState, [type]: false }));
          messageHandler(
            error?.response?.data?.data?.message || "Something went wrong",
            false
          );
        })
        .finally(() => {
          editModeHandler(type, false);
          setLoading((preState) => ({ ...preState, [type]: false }));
        });
    }
  };

  const starredHandler = () => {
    edittedDataHandler((preState) => {
      return { ...preState, isStarred: !preState?.isStarred };
    });
    setStarredLoading(false);
  };

  const utmParamBuilder = () => {
    const {
      utm_source = "",
      utm_medium = "",
      utm_campaign = "",
      utm_id = "",
      utm_term = "",
      utm_content = "",
    } = edittedData;
    const query = getUtmQuery({
      utm_campaign,
      utm_content,
      utm_medium,
      utm_source,
      utm_term,
      utm_id,
    });
    return query;
  };

  const isExpired = (date) => {
    const currentDate = new Date();
    const expDate = new Date(date);
    expDate.setHours(23, 59, 59, 999);
    console.log(expDate < currentDate, "lllllll");
    return expDate < currentDate;
  };

  return (
    <>
      <div className="scroll-y mt-lg-10 mt-0">
        <div className="border-bottom pb-5">
          <div className="px-lg-20 border-bottom mb-2 pb-4 d-flex justify-content-between desktop-hide-f">
            <h2 className="fw-bold my-2 fs-2x">Edit Short Link</h2>
            <i className="fa-light fa-xmark fs-4"></i>
          </div>
          <div className="d-flex flex-lg-row flex-column justify-content-between mb-6">
            <div className="d-flex align-items-center">
              <span className="symbol symbol-30px" id="faviconlink">
                <FaviconImage list={edittedData} />
              </span>
              <form className="w-100 min-w-lg-325px">
                <InputField
                  loading={loading.title}
                  submitHandler={submitHandler}
                  form={form}
                  fieldName={"title"}
                  editMode={
                    editMode?.title === true && !isExpired(expirationDate)
                  }
                  editModeHandler={editModeHandler}
                />
              </form>
            </div>
            <div className="align-items-center card-toolbar d-flex border rounded-1 ms-lg-10 ms-2 mt-lg-0 mt-2 px-lg-3 justify-content-start">
              <button
                onClick={async () => {
                  setStarredLoading(true);
                  await starredLinkHandler(
                    edittedData?.id,
                    edittedData?.isStarred
                  );
                  starredHandler();
                }}
                type="button"
                disabled={starredLoading || isExpired(expirationDate)}
                className="btn px-2"
              >
                <i
                  className={`fa-star fs-2 cursor-pointer ${
                    edittedData.isStarred
                      ? "fa-solid"
                      : "fa-regular on-hvr-yellow text-warning"
                  }`}
                  style={{ color: edittedData.isStarred ? "gold" : "" }}
                ></i>
              </button>
              {!isExpired(expirationDate) && (
                <ShareDropdown list={edittedData} />
              )}
              {!isExpired(expirationDate) && (
                <Copy
                  copyData={`${edittedData?.brandedDomain}/${edittedData?.slashTag}`}
                />
              )}
              <button
                onClick={() => setDeleteModal(true)}
                type="button"
                disabled={deleteLoading || isExpired(expirationDate)}
                className="btn px-2"
              >
                <i className="fa-regular fa-trash-can fs-2 ms-5 text-danger" />
              </button>
            </div>
          </div>

          <div className="separator mb-6"></div>
          <div className="d-flex mb-2">
          {
            destinationUrl &&   <form className="min-w-575px">
            <InputField
              loading={loading.destinationUrl}
              submitHandler={submitHandler}
              form={form}
              fieldName={"destinationUrl"}
              editMode={
                editMode?.destinationUrl === true &&
                !isExpired(expirationDate)
              }
              editModeHandler={editModeHandler}
              utmParams={utmParamBuilder()}
              editCount={
                quota?.plan === PLAN?.STANDARD ? edittedData?.edit : null
              }
            />
          </form>
          }
          </div>

          {!isExpired(expirationDate) && quota?.plan === PLAN.STANDARD ? (
            <div className="d-flex justify-content-end mb-3 link-info">
              {`Remaining edit limit for destination url is ${
                quota?.linkEdit - edittedData?.edit < 0
                  ? 0
                  : quota?.linkEdit - edittedData?.edit
              }`}
            </div>
          ) : null}

      {
        destinationUrl &&     <div className="d-flex mb-4">
        <form className="d-flex justify-content-center align-items-center mt-4">
          <Label className="fs-5 ms-2 fw-bold me-5 d-flex align-items-center user-select-none min-w-auto fw-bold mb-0 cursor-pointer on-hvr-primary">
          <Controller
              name="passwordProtectionEnabled"
              control={form.control}
              render={({ field }) => (
                <div className="form-check form-check-sm form-check-custom form-check-info form-check-solid not-checked-white rounded-1">
                  <Input
                    {...field}
                    type="checkbox"
                    checked={field.value}
                    className="absolute form-check-input cursor-pointer left-4 top-1/2 -mt-2 h-4 w-4 rounded-1 me-2 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    onChange={async (e) => {
                      field.onChange(e);
                      submitHandler("passwordProtectionEnabled", { passwordProtectionEnabled: field.value })
                    }}
                  />
                </div>
              )}
            />
            Password protection{" "}
          </Label>
          {passwordProtectionEnabled && (
            <div className="min-w-150px" style={{paddingLeft: "10px", borderLeft: "1px solid #dedede"}}>
              <InputField
                loading={loading.password}
                submitHandler={submitHandler}
                form={form}
                placeholder="Enter Password"
                fieldName={"password"}
                editMode={
                  editMode?.password === true && !isExpired(expirationDate)
                }
                editModeHandler={editModeHandler}
                editCount={
                  quota?.plan === PLAN?.STANDARD ? edittedData?.edit : null
                }
              />
            </div>
          )}
        </form>
      </div>
      }

          

          <div className="mt-6 d-flex justify-content-between align-items-center">
          <div
            className="position-relative rounded-1 d-flex align-items-center w-lg-50 px-4"
            style={{ height: "40px", backgroundColor: "#eef0ff" }}
          >
            <Link
              to={`https://${edittedData?.brandedDomain}/${edittedData?.slashTag}`}
              id="link"
              target="_blank"
              className=" fw-bold mb-0 fs-3 cursor-pointer text-info on-hvr-primary"
            >
              {`${edittedData?.brandedDomain}/${edittedData?.slashTag}`}
            </Link>
          </div>
            <form>
              <DateField
                loading={loading.expirationDate}
                submitHandler={submitHandler}
                form={form}
                fieldName={"expirationDate"}
                editMode={editMode?.expirationDate === true}
                editModeHandler={editModeHandler}
              />
            </form>
          </div>

          <div className="d-flex justify-content-end mb-3">
            {message ? message : null}
          </div>

          <div id="tagContainer" className="mt-6 d-flex gap-2">
            {edittedData?.tags
              ?.split(",")
              ?.filter((tag) => tag.trim())
              ?.map((tag) => {
                return (
                  <span key={tag} className="badge badge-light">
                    {tag}
                  </span>
                );
              })}
          </div>
          <ShortlinkAnalytics
            shortlink={`https://${edittedData?.brandedDomain}/${edittedData?.slashTag}`}
          />
        </div>
      </div>

      {/* Modals */}
      <DeleteModal
        isOpen={deleteModal}
        modalHandler={() => setDeleteModal(false)}
        leftBtnHandler={async () => {
          setDeleteLoading(true);
          setDeleteModal(false);
          await deleteLinkHandler(edittedData.id);
          setDeleteLoading(false);
          setEditShortLink(false);
        }}
        rightBtnHandler={() => setDeleteModal(false)}
        data={{
          heading: "Delete shot link",
          description: "Are you sure you want to delete this short link?",
          leftBtn: "Yes, delete it",
          rightBtn: "Cancel",
        }}
      />
    </>
  );
};

export default EditShortLinkForm;
