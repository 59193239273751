import React, { useContext, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { AuthContxt } from "../../store/authContxt";
import { ONBOARDING_STATUS } from "../../utils/enum";

const withOnboarding = (WrappedComponent) => {
  return (props) => {
    const { profile } = useContext(AuthContxt);
    const navigate = useNavigate();
    const location = useLocation();
    const hasRedirected = useRef(false);

    useEffect(() => {
      // Only run this effect if the profile is fully loaded and prevent re-triggering
      if (!profile || hasRedirected.current || Object.keys(profile).length === 0) return;

      const onboardingStatus = ONBOARDING_STATUS[profile?.onboardingStatus] || ONBOARDING_STATUS.NOT_STARTED;
      const currentPath = location.pathname;
      const skipProfileCompletion = sessionStorage.getItem('skipProfileCompletion') === 'true';

      console.log("Current onboarding status:", onboardingStatus);

      let redirectPath = null;

      switch (onboardingStatus) {
        case ONBOARDING_STATUS.NOT_STARTED:
          if (currentPath !== '/accept-terms') {
            redirectPath = '/accept-terms';
          }
          break;

        case ONBOARDING_STATUS.TERMS_ACCEPTED:
          if (currentPath !== '/complete-profile' && !skipProfileCompletion) {
            redirectPath = '/complete-profile';
          } else if (currentPath === '/accept-terms') {
            redirectPath = '/complete-profile';
          }
          break;

        case ONBOARDING_STATUS.COMPLETED:
          if (currentPath === '/accept-terms' || currentPath === '/complete-profile') {
            redirectPath = '/dashboard';
          }
          break;

        default:
          console.error('Unexpected onboarding status:', onboardingStatus);
      }

      // Only navigate if a valid redirect path is found and it differs from the current path
      if (redirectPath && redirectPath !== currentPath) {
        hasRedirected.current = true;
        navigate(redirectPath, { replace: true });
      }
    }, [profile, location, navigate]);

    // Avoid re-creating the WrappedComponent on every render
    return <WrappedComponent {...props} />;
  };
};

export default withOnboarding;
