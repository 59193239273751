import shotlinkArrow from '../../assets/media/onboarding/create-shortlink-arrow.svg';
import shotlinkImage from '../../assets/media/onboarding/create-shortlink-image.svg';
import qrArrow from '../../assets/media/onboarding/qr-arrow.svg';
import qrImage from '../../assets/media/onboarding/qr-image.svg';
import bioArrow from '../../assets/media/onboarding/link-in-bio-arrow.svg';
import bioImage from '../../assets/media/onboarding/link-in-bio-image.svg';

export const slides = [
    {
        key: "demoTour1",
        image: shotlinkImage,
        title: "Creating Shortlinks",
        description: "Click the Create button to make your first shortlink. Experience the ease of generating shortlinks effortlessly!",
        feature: "shortlink",
        btn: "btn-info",
        arrow: shotlinkArrow
    },
    {
        key: "demoTour2",
        image: qrImage,
        title: "QR Codes",
        description: "Create your first QR code easily with Divsly. Simplify your sharing process effortlessly today!",
        feature: "qr",
        btn: "btn-success",
        arrow: qrArrow
    },
    {
        key: "demoTour3",
        image: bioImage,
        title: "Link in Bio",
        description: "Create your first link in bio page with Divsly. Simplify your sharing process effortlessly today!",
        feature: "bio",
        btn: "btn-primary",
        arrow: bioArrow
    }
];