import { createContext, useContext, useEffect, useState } from "react";
import { AuthContxt } from "../../store/authContxt";
import QrCodesAPI from "../../services/http/qrCodes";
import { SvgIcons } from "./create-qr-code/frames-svg";

const filtersDefaultState = {
  starred: false,
  sort: "",
  tags: [],
};

export const QrCodeContxt = createContext(null);

const QrCodeContxtProvider = (props) => {
  const { fetchQuota } = useContext(AuthContxt);

  const [qrCodes, setQrCodes] = useState([]);
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(true);
  const [notQrScreen, setNotQrScreen] = useState("");
  const [search, setSearch] = useState("");
  const [filters, setFilters] = useState(filtersDefaultState);
  const [dataFetched, setDataFetched] = useState(false); // To prevent redundant fetches

  // Function to handle tags
  const tagsHandler = (links) => {
    const allTags = links?.reduce((tags, item) => {
      if (item.tags) {
        const itemTags = item.tags.split(",").map((tag) => tag.trim());
        tags.push(...itemTags.filter((tag) => tag !== ""));
      }
      return Array.from(new Set(tags)); // Unique tags
    }, []);
    setTags(allTags);
  };

  // Fetch QR codes based on filters
  const fetchFilteredData = async (filters) => {
    try {
      const { starred, ...rest } = filters;
      const auth_token = localStorage.getItem("auth_token");

      const { data } = await QrCodesAPI.filteredQrCodes(auth_token, {
        ...rest,
        ...(starred ? { starred } : {}),
        tags: filters.tags.join(","),
      });

      if (data?.success) {
        const newDataArray = data.resultData.map((item) => ({
          ...item,
          frame: item.frame ? SvgIcons.find((q) => q.type === item.frame) : item.frame,
          data: `${item.brandedDomain}/${item.slashTag}?r=qr`,
        }));
        setQrCodes(newDataArray);

        if (newDataArray.length < 1) {
          setNotQrScreen("searchFound");
        }
      }
    } catch (e) {
      console.log("Error while fetching filtered data:", e.message);
    }
  };

  // Delete QR code
  const deleteQrCode = async (id) => {
    try {
      const auth_token = localStorage.getItem("auth_token");
      const { data } = await QrCodesAPI.deleteQrCode(auth_token, id);

      if (data?.success) {
        const filteredQrCodes = qrCodes.filter((item) => item.id !== id);
        tagsHandler(filteredQrCodes);
        if (filteredQrCodes.length === 0) {
          setNotQrScreen("notFound");
        }
        setQrCodes(filteredQrCodes);
        await fetchQuota();
      }
    } catch (error) {
      console.log("Error while deleting qr code:", error);
    }
  };

  // Fetch QR codes
  const getQrCodes = async () => {
    if (dataFetched) return; // Prevent redundant fetch

    setLoading(true);
    const auth_token = localStorage.getItem("auth_token");

    try {
      const { data } = await QrCodesAPI.getQrCodes(auth_token);
      if (data?.success) {
        const newData = data?.resultData?.map((e) => ({
          ...e,
          frame: e.frame ? SvgIcons.find((q) => q.type === e.frame) : e.frame,
        }));
        setQrCodes(newData);
        tagsHandler(newData);
        if (newData.length < 1) setNotQrScreen("notFound");
        
        setDataFetched(true); // Mark as fetched to prevent redundant calls
      }
    } catch (error) {
      console.log("Error while fetching qr codes:", error);
    } finally {
      setLoading(false);
    }
  };

  // Call getQrCodes only once when the component mounts
  useEffect(() => {
    getQrCodes();
  }, []);

  return (
    <QrCodeContxt.Provider
      value={{
        loading,
        tags,
        tagsHandler,
        qrCodes,
        setQrCodes,
        notQrScreen,
        setNotQrScreen,
        deleteQrCode,
        filters,
        setFilters,
        fetchFilteredData,
        search,
        setSearch,
      }}
    >
      {props.children}
    </QrCodeContxt.Provider> 
  );
};

export default QrCodeContxtProvider;
