import React, { useState } from "react";
import {
  Button,
  Form,
  Input,
  InputGroup,
  Tooltip,
  Collapse,
  FormGroup,
  Label,
} from "reactstrap";

const Sender = ({
  selectedSender,
  setSelectedSender,
  replyToEmail,
  setReplyToEmail,
  sendToField,
  setSendToField,
  showEmailForm,
  setShowEmailForm,
  tooltipOpen,
  setTooltipOpen,
  isAdvancedOpen,
  setIsAdvancedOpen,
  senderName,
  setSenderName,
  senderEmail,
  setSenderEmail,
  handleAddSender,
  handleSave,
  handleCancel,
  fromValues,
}) => {
  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);
  const toggleEmailForm = () => setShowEmailForm(!showEmailForm);
  const toggleAdvanced = () => setIsAdvancedOpen(!isAdvancedOpen);

  const handleSenderChange = (e) => {
    console.log("target", e.target.value);
    setSelectedSender(e.target.value);
  };

  const handleNameChange = (e) => {
    setSenderName(e.target.value);
  };

  const handleReplyToEmailChange = (e) => setReplyToEmail(e.target.value);
  const handleSendToFieldChange = (e) => setSendToField(e.target.value);

  const [emailError, setEmailError] = useState("");
  const [replyToError, setReplyToError] = useState("");

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleEmailChange = (e) => {
    const email = e.target.value;
    setSenderEmail(email);
    if (!validateEmail(email)) {
      setEmailError("Invalid email address");
    } else {
      setEmailError("");
    }
  };

  const onSave = () => {};

  return (
    <div>
      <Form>
        <FormGroup>
          <Label for="selectSender">Select Sender</Label>
          <InputGroup>
            <Input
              type="select"
              name="selectSender"
              id="selectSender"
              value={selectedSender}
              onChange={handleSenderChange}
            >
              <option value="">Choose...</option>
              {fromValues.map((item, index) => (
                <option key={index} value={item.value}>
                  {item.value}
                </option>
              ))}
            </Input>
          </InputGroup>
          <div className="d-flex justify-content-end mt-3">
            <button
              type="button"
              className="btn text-nowrap rounded-1 btn-secondary"
              onClick={handleCancel}
            >
              Cancel
            </button>
            <button
              type="button"
              class="btn text-nowrap rounded-1 btn-info text-white"
              color="danger"
              onClick={handleSave}
              disabled={!!emailError || !!replyToError}
              style={{ marginLeft: "10px" }}
            >
              Save
            </button>
          </div>
        </FormGroup>
      </Form>
    </div>
  );
};

export default Sender;
