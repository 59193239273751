import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Slide from './Slide';
import WelcomeCard from './WelcomeCard';
import { useOnBoardingLogic } from './useOnBoardingLogic';
import useOnboarding from '../../hooks/useOnboarding';

// Import image assets
import imageclap from '../../assets/media/onboarding/getting-starated-image.svg';
import whileLogo from '../../assets/media/onboarding/divsly-w-w.svg';

const OnBoarding = ({ onComplete }) => {
  const { isModalOpen, handleOnboardingComplete, setIsModalOpen } = useOnboarding();
  const {
    loading,
    activeSlides,
    currentIndex,
    name,
    handleSlideChange,
    SkipDemo,
    handleCreate
  } = useOnBoardingLogic(onComplete, setIsModalOpen); // Ensure onComplete is passed

  // Log to check activeSlides length
  console.log("Active Slides Length:", activeSlides.length);

  if (activeSlides.length === 0) {
    return null; // Nothing to display
  }

  return (
    <div className="px-0 h-100">
      <div className="main-content d-flex align-items-center justify-content-center h-100">
        <div className="left-cards me-auto mt-5" style={{ width: '337px' }}>
          <Carousel
            showArrows={false}
            showStatus={false}
            showIndicators={true}
            infiniteLoop={false}
            showThumbs={false}
            selectedItem={currentIndex}
            onChange={handleSlideChange}
          >
            {activeSlides.map((slide) => (
              <Slide
                key={slide.key}
                slide={slide}
                loading={loading}
                onSkip={SkipDemo}
                onCreate={handleCreate}
              />
            ))}
          </Carousel>

          {activeSlides[currentIndex] && (
            <img
              src={activeSlides[currentIndex].arrow}
              alt={`${activeSlides[currentIndex].feature}-arrow`}
              className={`${activeSlides[currentIndex].feature}arrow_onboard`}
            />
          )}
        </div>
        <WelcomeCard name={name} imageclap={imageclap} whileLogo={whileLogo} />
      </div>
    </div>
  );
};

export default OnBoarding;
