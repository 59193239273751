import httpMethods from "..";

const SmsCampaignAPI = {
  createSmsCampaign: async (token, values) => {
    return httpMethods
      .post(`/sms-campaigns`, values, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => res);
  },

  getAllSmsCampaigns: async (token, params) => {
    return httpMethods
      .get(`/sms-campaigns`, {
        params,
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => res)
      .catch((error) => {
        throw error;
      });
  },

  updateSmsCampaign: async (token, id, values) => {
    return httpMethods
      .put(`/sms-campaigns/${id}`, values, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => res);
  },

  deleteSmsCampaign: async (token, id) => {
    return httpMethods
      .delete(`/sms-campaigns/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => res);
  },

  getSmsCampaignsById: async (token, id) => {
    return httpMethods
      .get(`/sms-campaigns/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => res)
      .catch((error) => {
        throw error;
      });
  },

  scheduleSmsCampaign: async (token, id, values) => {
    return httpMethods
      .post(`/sms-campaigns/${id}/schedule`, values, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => res);
  },

  sendSmsCampaign: async (token, id, values) => {
    return httpMethods
      .post(`/sms-campaigns/${id}/send`, values, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => res);
  },

  sendPreviewSms: async (token, id, body) => {
    return httpMethods
      .post(`/sms-campaigns/${id}/sendPreviewSms`, body, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => res);
  },

  getReports: async (token, id) => {
    return httpMethods
      .get(`/sms-campaigns/reports/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => res);
  },
};

export default SmsCampaignAPI;
