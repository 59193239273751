import React from 'react'

const IconFrame5 = ({ primary, secondary, fontSize, text, textColor, type, url, width, height, minFontSize, maxFontSize }) => {
  return (
    <>




<svg width={width || 2743} height={height || 2932} viewBox="0 0 2743 2932" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="400.191" y="107.56" width="1576.15" height="701.869" fill={secondary || "white"} />
<path d="M2704.69 944.371H2501.61C2480.45 944.371 2463.29 961.529 2463.29 982.703V1032.53H2413.86V889.519C2413.86 826.012 2362.38 774.525 2298.91 774.525H1995.86V549.512C2091.01 501.114 2153.96 402.695 2153.96 290.614C2153.96 130.365 2023.64 0 1863.46 0H503.552C343.372 0 213.049 130.365 213.049 290.614C213.049 402.695 276.004 501.114 371.152 549.512V774.525H114.95C51.4748 774.525 0 826.012 0 889.519V2817.01C0 2880.51 51.4748 2932 114.95 2932H2298.9C2362.38 2932 2413.85 2880.51 2413.85 2817.01V1208.87H2704.68C2725.85 1208.87 2743 1191.71 2743 1170.53V982.711C2743.01 961.536 2725.85 944.371 2704.69 944.371ZM1878.21 377.802C1829.49 385.921 1793.76 428.093 1793.76 477.51V774.525H573.242V477.51C573.242 428.093 537.523 385.921 488.8 377.802C446.122 370.696 415.147 334.028 415.147 290.614C415.147 241.841 454.813 202.168 503.559 202.168H1863.46C1912.21 202.168 1951.87 241.841 1951.87 290.614C1951.87 334.028 1920.89 370.688 1878.21 377.802ZM2215.76 2055.98C2215.76 2083.5 2193.46 2105.81 2165.95 2105.81C2138.44 2105.81 2116.14 2083.5 2116.14 2055.98V1836.7C2116.14 1809.17 2138.44 1786.87 2165.95 1786.87C2193.46 1786.87 2215.76 1809.17 2215.76 1836.7V2055.98ZM2215.76 1678.42C2215.76 1705.94 2193.46 1728.25 2165.95 1728.25C2138.44 1728.25 2116.14 1705.94 2116.14 1678.42V1596.01C2116.14 1568.48 2138.44 1546.17 2165.95 1546.17C2193.46 1546.17 2215.76 1568.48 2215.76 1596.01V1678.42Z" fill={primary || "#0E1B21"}/>
<text fill={textColor || "white"} xmlSpace="preserve" style={{ whiteSpace: "pre" }} fontFamily="Arial" fontSize={fontSize || 226} fontWeight="bold" letterSpacing="0em">
  <tspan x="43%" textAnchor='middle' y="2739">{text}</tspan>
</text>
<rect x="532" y="1058.25" width="1316" height="1316" rx="50" fill="white"/>
<image href={url} x="618" y="1144" width="1144" height="1144" />
</svg>

    </>

  )
}

export default IconFrame5