import React from 'react'

const IconFrame5 = ({ primary, secondary, fontSize, text, textColor, type, url, width, height, minFontSize, maxFontSize }) => {
  return (
    <>
    <svg width={width || 1800} height={height || 2290} viewBox="0 0 1800 2290" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clipPath="url(#clip0_175_62)">
    {/* Main background shape */}
    <path d="M-9 0H1791V1817C1791 2078.23 1579.23 2290 1318 2290H464C202.769 2290 -9 2078.23 -9 1817V0Z" fill={secondary || "white"} />

    {/* Horizontal lines */}
    <path d="M305 966V852H419" stroke="white" strokeWidth={21} strokeLinecap="round" strokeLinejoin="round" />
    <path d="M305 1910V2024H419" stroke="white" strokeWidth={21} strokeLinecap="round" strokeLinejoin="round" />
    <path d="M1477 966V852H1363" stroke="white" strokeWidth={21} strokeLinecap="round" strokeLinejoin="round" />
    <path d="M1477 1910V2024H1363" stroke="white" strokeWidth={21} strokeLinecap="round" strokeLinejoin="round" />

    {/* Top background rectangle */}
    <path d="M-9 0H1791V548H1341L891 733L441 548H-9V0Z" fill={primary || "#0E1B21"} />

    {/* Text "SCAN ME" */}
    <text fill={textColor || "white"} xmlSpace="preserve" style={{ whiteSpace: "pre" }} fontFamily="Arial" fontSize={fontSize || 222} fontWeight="bold" letterSpacing="0em">
      <tspan x="50%" textAnchor="middle" y="352.463">{text}</tspan>
    </text>

    {/* Image element */}
    <image href={url} x="240" y="787" width="1302" height="1302" />
  </g>

  {/* Clip path definition */}
  <defs>
    <clipPath id="clip0_175_62">
      <rect width="1800" height="2290" fill="white" />
    </clipPath>
  </defs>
</svg>


 

    </>
  )
}

export default IconFrame5