import httpMethods from "..";

const TagsApi = {
  getTags: async (payload) => {
    const token = localStorage?.getItem("auth_token");

    // Create the base query string with the required parameters
    let query = `userId=${payload.userId}&search=${encodeURIComponent(
      payload.search || ""
    )}&order=${encodeURIComponent(payload.order || "id")}`;

    // Make the HTTP GET request with the constructed URL
    return httpMethods.get(`/tags?${query}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  getTagsUser: async () => {
    const token = localStorage?.getItem("auth_token");
    return httpMethods.get(`/tags/get`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
};

export default TagsApi;
