import CreateSmsCampaign from "../components/sms-campaigns/createSmsCampaign";
import SmsCampaignContxtProvider from "../components/sms-campaigns/smsCampaignContxt";

const SmsCampaigns = () => {
  return (
    <SmsCampaignContxtProvider>
      <CreateSmsCampaign />
    </SmsCampaignContxtProvider>
  );
};

export default SmsCampaigns;
