import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import videoPreview from '../../../../assets/qr-preview/qr-video-preview.png'
import { isColorDark } from './color-checker/isColorDark';

const VideoCard = ({ videoUrl, title, description, youtubeLink }) => {
  return (
    <div>
      <div >
        <iframe
          width="100%"
          height="auto"
          style={{
            borderRadius: "10px",
          }}
          src={videoUrl}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
        ></iframe>
      </div>
      <div>
        <h3 className='mt-2'>{title}</h3>
        <p className="fs-5 mt-2 text-black text-wrap-oneline" style={{ WebkitLineClamp: "1", }}>{description}</p>
      </div>
    </div>
  );
};

const VideoSection = ({ formData }) => {
  const { title, videos, facebook, instagram, google, linkedin, pageTitle, description, buttonText, buttonLink } = formData;

  return (
    <div className="position-relative onhvr-scroll-y">
      <div className="w-100 mx-auto pb-10 scroll-content" style={{
        height: "575px",
        maxWidth: " 900px"
      }}
      >
        <div className="d-flex justify-content-center align-items-start py-10 flex-column"
          style={{
            backgroundImage: `url(${videoPreview})`,
            backgroundSize: "contain",
            backgroundPosition: "center",
            height: "170px",
          }}
        >

        </div>

        <div className="d-flex justify-content-start align-items-start flex-column text-start"
          style={{
            backgroundColor: "white",
            borderRadius: "10px",
            width: "90%",
            padding: "15px",
            margin: "-50px auto 0px auto",
            border: "1px solid #dedede"
          }}
        >
          <div className="fs-1 fw-bold mb-2">{formData?.pageTitle || "Page Title"}</div>
          <p className="fs-5 w-100">
            {formData?.description || "Lorem ipsum dolor sit amet consectetur adipisicing elit. Similique, tempora!"}
          </p>

          <a href={formData?.buttonLink}
            className={`text-${isColorDark(formData?.ctaColor) ? "white" : "black"} btn rounded-1 mt-2`}
          style={{background: formData?.ctaColor || "#348ce5"}} >
            {formData?.buttonText || "Learn More"}
          </a>
        </div>

        <div className="d-flex flex-column gap-5 p-5">
          {videos?.length > 0 ? (
            videos.map((video, index) => (
              <VideoCard
                key={index}
                videoUrl={video?.embedLink || ""}
                title={video?.title || ""}
                description={video.description || ""}
                youtubeLink={video?.youtubeLink || ""}
              />
            ))
          ) : (
            <VideoCard
              key={0}
              videoUrl="https://www.youtube.com/embed/AMK1VsDLTug"
              title="Video Title"
              description="Lorem ipsum dolor sit amet consectetur adipisicing elit. Similique, tempora!"
              youtubeLink="https://www.youtube.com/watch?v=AMK1VsDLTug"
            />
          )}
        </div>


        {/* <div className="p-5">
        <h3 className="mb-8">Social Media</h3>
        <div className="d-flex gap-5">
          <div className="w-50px h-50px rounded-circle d-flex align-items-center justify-content-center" style={{ backgroundColor: '#3b5998' }}>
            <i className="fab fa-facebook-f fs-2 text-white"></i>
          </div>
          <div className="w-50px h-50px rounded-circle d-flex align-items-center justify-content-center" style={{ backgroundColor: '#e1306c' }}>
            <i className="fab fa-instagram fs-2 text-white"></i>
          </div>
        </div>
      </div> */}

        {/* social media */}
        <div className="d-flex gap-6 mb-3 py-4 justify-content-center align-items-center">
          <span className="h-40px w-40px rounded-circle d-flex justify-content-center align-items-center" style={{ backgroundColor: "#3F51B5" }}><Link to={facebook || "#profile"} className="d-flex">
            <i class="fa-brands fs-2 fa-facebook-f text-white"></i>
          </Link>
          </span>
          <span className="h-40px w-40px rounded-circle d-flex justify-content-center align-items-center" style={{ background: 'radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%)' }}><Link to={instagram || "#profile"} className="d-flex">
            <i class="fa-brands fs-2 fa-instagram text-white"></i>
          </Link>
          </span>
          <span className="h-40px w-40px rounded-circle d-flex justify-content-center align-items-center" style={{ backgroundColor: "#e84d40" }}><Link to={google || "#profile"} className="d-flex">
            <i class="fa-brands fs-2 fa-google text-white"></i>
          </Link>
          </span>
          <span className="h-40px w-40px rounded-circle d-flex justify-content-center align-items-center" style={{ backgroundColor: "#0078D4" }}><Link to={linkedin || "#profile"} className="d-flex">
            <i class="fa-brands fs-2 fa-linkedin-in text-white"></i>
          </Link>
          </span>
        </div>
      </div>
    </div>
  );
};

// Usage Example
const VideoPreview = ({ formData }) => {

  // const videos = [
  //   {
  //     videoUrl: "https://www.youtube.com/embed/AMK1VsDLTug?si=uPPeZ0cpIaT4C-ph&controls=0",
  //     title: "Tony Robbins",
  //     description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Similique, tempora!",
  //     youtubeLink: "https://www.youtube.com/watch?v=AMK1VsDLTug"
  //   },
  //   {
  //     videoUrl: "https://www.youtube.com/embed/AMK1VsDLTug?si=uPPeZ0cpIaT4C-ph&controls=0",
  //     title: "Tony Robbins",
  //     description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Similique, tempora!",
  //     youtubeLink: "https://www.youtube.com/watch?v=AMK1VsDLTug"
  //   }
  // ];

  return <VideoSection formData={formData} />;
};

export default VideoPreview;
