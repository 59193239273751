import React from 'react'

const IconFrame5 = ({ primary,  secondary, fontSize, text, textColor, type, url, width, height, minFontSize, maxFontSize }) => {
  return (
    <>
  
<svg width={width || 1646} height={height || 2285} viewBox="0 0 1646 2285" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_175_7434)">
<rect x="1646" y="500" width="1646" height="500" rx="133" transform="rotate(-180 1646 500)" fill={primary || "#0E1B21"}/>
<path d="M599.424 674.268L616.267 674.268L626.92 661.223L789.912 461.64C807.465 440.147 840.192 439.785 858.215 460.885L1029.86 661.825L1040.48 674.268L1056.85 674.268L1421 674.268C1525.66 674.268 1610.5 759.11 1610.5 863.768L1610.5 2060C1610.5 2164.66 1525.66 2249.5 1421 2249.5L225 2249.5C120.342 2249.5 35.4999 2164.66 35.4999 2060L35.5 863.768C35.5 759.11 120.342 674.268 225 674.268L599.424 674.268Z" fill="white" stroke={secondary || "white"} stroke-width="71"/>


<text fill={textColor || "white"} xmlSpace="preserve" style={{ whiteSpace: "pre" }} fontFamily="Arial" fontSize={fontSize || 380} fontWeight="bold" letterSpacing="0em">
  <tspan x="50%" textAnchor='middle' y="296.696">{text}</tspan>
</text>
<path d="M223 1059V859H423" stroke="black" stroke-width="21" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M223 1863V2063H423" stroke="black" stroke-width="21" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M1427 1059V859H1227" stroke="black" stroke-width="21" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M1427 1863V2063H1227" stroke="black" stroke-width="21" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip0_175_7434">
<rect width="1646" height="2285" fill="white"/>
</clipPath>
</defs>
<image href={url} x="251" y="889" width="1144" height="1144" />
</svg>
    </>
  )
}

export default IconFrame5