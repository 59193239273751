import React, { useState } from 'react';

const BarcodePreview = ({ formData }) => {
  const [hasError, setHasError] = useState(false);

  const handleIframeError = () => {
    setHasError(true);
  };

  return (
    <div className='w-100' style={{ height: '486px' }}>
      <div className="web-qr-input-box bg-gray-300 p-5">
        <input
          className="w-100 form-control"
          placeholder={formData?.destinationUrl || "enter website URL"}
          type="text"
        />
      </div>
      {hasError ? (
        <div className="h-100 d-flex justify-content-center align-items-center">
          <p>Unable to load the website. Please check the URL and try again.</p>
        </div>
      ) : (
        <iframe
          id="web-view"
          className="h-100 w-100"
          src={formData?.destinationUrl || "https://divsly.com"}
          onError={handleIframeError}
        ></iframe>
      )}
    </div>
  );
};

export default BarcodePreview;
