import React from 'react'

const IconFrame5 = ({ primary,fontSize, text, textColor, type, url, width, height, minFontSize, maxFontSize }) => {
  return (
    <>
   <svg width={width || 1392} height={height || 1900} viewBox="0 0 1392 1900" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clipPath="url(#clip0_175_15)">
    {/* Outer background rectangle */}
    <rect width="1392" height="1900" rx={115} fill={primary || "#0E1B21"} />

    {/* Inner white rectangle */}
    <rect x={63} y={63} width={1266} height={1266} rx={61} fill="white" />

    {/* Text "SCAN ME" */}
    <text
      fill={textColor || "white"}
      fontFamily="Arial"
      fontSize={fontSize || 210}
      fontWeight="bold"
      letterSpacing="0em"
      x="50%"  
      y="1692.1"
      textAnchor="middle"
    >
      {text}
    </text>
  </g>

  {/* Clip path definition */}
  <defs>
    <clipPath id="clip0_175_15">
      <rect width="1392" height="1900" fill="white" />
    </clipPath>
  </defs>
  <image href={url} x="125" y="125" width="1142" height="1142" />
</svg>


    </>
  )
}

export default IconFrame5