import React, { useState, useEffect, useContext } from "react";
import {
  Card,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Tooltip,
  Collapse,
  Label,
  DropdownItem,
  InputGroup,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Spinner,
} from "reactstrap";
import Sidebar from "../../components/common/sidebar";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import EmailCampaignAPI from "../../services/http/emailCampaign";
import FromAddressAPI from "../../services/http/fromAddress";
import Sender from "./Sender";
import To from "./To";
import { Link } from "react-router-dom";
import TagsApi from "../../services/http/tags";
import { AuthContxt } from "../../store/authContxt";

const EmailCampaignSettings = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id: emailCampaignId } = useParams();
  const [modal, setModal] = useState(false);
  const toggle = () => {
    setModal(!modal);
    setMessage({ text: "", type: "" });
  };
  const [data, setData] = useState([]);
  const [subject, setSubject] = useState("");
  const [toAddress, setToAddress] = useState([]);
  const [selectedSender, setSelectedSender] = useState("");
  const [replyToEmail, setReplyToEmail] = useState("");
  const [sendToField, setSendToField] = useState("");
  const [showEmailForm, setShowEmailForm] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [isAdvancedOpen, setIsAdvancedOpen] = useState(false);
  const [senderName, setSenderName] = useState("");
  const [senderEmail, setSenderEmail] = useState("");
  const [values, setValues] = useState([]);
  const [isFromModalOpen, setIsFromModalOpen] = useState(false);
  const [isToModalOpen, setIsToModalOpen] = useState(false);
  const [isSubjectModalOpen, setIsSubjectModalOpen] = useState(false);
  const [userTags, setUserTags] = useState([]);
  const { profile } = useContext(AuthContxt);
  const [emailTemplateId, setEmailTemplateId] = useState("");
  const [title, setTitle] = useState("");
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [previewEmail, setPreviewEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [showEdit, setShowEdit] = useState(true);
  const [message, setMessage] = useState({ text: "", type: "" });
  const { id } = profile || {};
  const [emailCampaign, setEmailCampaign] = useState({});
  const [emailCampaignData, setEmailCampaignData] = useState({
    fromAddress: "",
    recipients: [],
    subject: "",
  });

  const [initialFromAddress, setInitialFromAddress] = useState("");
  const [initialReplyToEmail, setInitialReplyToEmail] = useState("");
  const [initialSendToField, setInitialSendToField] = useState("");
  const [initialToAddress, setInitialToAddress] = useState([]);
  const [initialSubject, setInitialSubject] = useState("");
  const [isScheduleDisabled, setIsScheduleDisabled] = useState(true);

  const auth_token = localStorage.getItem("auth_token");

  useEffect(() => {
    checkFields();
  }, [subject, toAddress, selectedSender]);

  useEffect(() => {
    return () => {
      setEmailCampaign({});
    };
  }, []);

  const checkFields = () => {
    if (!subject || !toAddress || !selectedSender || toAddress.length === 0) {
      setIsScheduleDisabled(true);
    } else {
      setIsScheduleDisabled(false);
    }
  };

  const getEmailCampaign = async () => {
    try {
      const auth_token = localStorage.getItem("auth_token");
      EmailCampaignAPI.getEmailCampaignsById(auth_token, emailCampaignId)
        .then(({ data }) => {
          setEmailCampaign(data.resultData);
          if (data.resultData.name) {
            setTitle(data.resultData.name);
          }
          if (data.resultData.fromAddress) {
            setSelectedSender(data.resultData.fromAddress);
            setInitialFromAddress(data.resultData.fromAddress);
          }
          if (data.resultData.replyToAddress) {
            setReplyToEmail(data.resultData.replyToAddress);
            setInitialReplyToEmail(data.resultData.replyToAddress);
          }
          if (data.resultData.sendToField) {
            setSendToField(data.resultData.sendToField);
            setInitialSendToField(data.resultData.sendToField);
          }
          if (data.resultData.subject) {
            setSubject(data.resultData.subject);
            setInitialSubject(data.resultData.subject);
          }
          if (
            data.resultData.recipients &&
            data.resultData.recipients.length > 0
          ) {
            setToAddress(JSON.parse(data.resultData.recipients));
            setInitialToAddress(JSON.parse(data.resultData.recipients));
          }
          if (data.resultData.emailTemplate) {
            setEmailTemplateId(data.resultData.emailTemplate.id);
          } else {
            console.log("setting show edit false");
            setShowEdit(false);
          }
        })
        .catch((error) => {
          console.log("Error while fetching shortlinks", error);
        });
    } catch (e) {
      console.log("Error while loading email campaign : ", e.message);
    }
  };

  const updateEmailCampaign = async () => {
    try {
      const auth_token = localStorage.getItem("auth_token");
      const values = { status: "SCHEDULED" };
      if (!subject || !toAddress || !selectedSender || toAddress.length === 0) {
        alert("Subject, Recipients and From Address should be filled");
        setLoading(false);
        return;
      }
      EmailCampaignAPI.updateEmailCampaign(auth_token, emailCampaignId, values)
        .then(({ data }) => {
          navigate("/emailcampaigns");
        })
        .catch((error) => {
          console.log("Error while fetching shortlinks", error);
          setError("Failed to schedule the email campaign. Please try again.");
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (e) {
      console.log("Error while loading email campaign : ", e.message);
      setError("Failed to schedule the email campaign. Please try again.");
      setLoading(false);
    }
  };

  const getFromAddress = async () => {
    try {
      const auth_token = localStorage.getItem("auth_token");
      const { data } = await FromAddressAPI.getAllFromAddress(auth_token);
      const newFromAddress = data.map((addr) => ({
        value: `${addr.name} <${addr.email}>`,
      }));
      setValues(newFromAddress);
    } catch (error) {
      console.log("Error while fetching from addresses", error);
    }
  };

  const saveFromAddress = async (data) => {
    try {
      const auth_token = localStorage.getItem("auth_token");
      await FromAddressAPI.createFromAddress(auth_token, data);
      await getFromAddress(); // Refresh the list after saving
      setSenderName(""); // Clear the form fields
      setSenderEmail("");
      setSelectedSender(`${data.name}<${data.email}>`); // Optionally select the newly added sender
    } catch (error) {
      console.log("Error while saving from address", error);
    }
  };

  const handleAddSender = async () => {
    if (senderName && senderEmail) {
      const newSenderData = {
        name: senderName,
        email: senderEmail,
      };
      await saveFromAddress(newSenderData);
    } else {
      console.log("Please fill out the name and email fields.");
    }
  };

  const handleSave = () => {
    console.log("selectedSender", selectedSender);
    if (selectedSender) {
      if (
        initialFromAddress === selectedSender &&
        initialReplyToEmail === replyToEmail &&
        initialSendToField === sendToField
      ) {
        handleCancel();
        return;
      }
      const data = {};
      if (selectedSender) {
        data.fromAddress = selectedSender;
      }
      if (replyToEmail) {
        data.replyToAddress = replyToEmail;
      }
      if (sendToField) {
        data.sendToField = sendToField;
      }
      EmailCampaignAPI.updateEmailCampaign(auth_token, emailCampaignId, data)
        .then(({ data }) => {
          console.log("Email campaign updated successfully", data);
          setInitialFromAddress(selectedSender);
          setInitialReplyToEmail(replyToEmail);
          setInitialSendToField(sendToField);
          handleCancel();
        })
        .catch((error) => {
          console.log("Error while updating email campaign", error);
        });
    } else {
      console.log("Please select a sender.");
    }
  };

  const handleCancel = () => {
    setShowEmailForm(false);
    setIsAdvancedOpen(false);
    setSenderName("");
    setSenderEmail("");
    toggleFromModal();
  };

  const handleCancelSubject = () => {
    toggleSubjectModal();
  };

  const handleTo = (newToAddress) => {
    console.log("toAddress", newToAddress);
    console.log("initialTo", initialToAddress);
    if (newToAddress && newToAddress.length > 0) {
      if (JSON.stringify(newToAddress) === JSON.stringify(initialToAddress)) {
        toggleToModal();
        return;
      }
      setToAddress(newToAddress);
      EmailCampaignAPI.updateEmailCampaign(auth_token, emailCampaignId, {
        recipients: JSON.stringify(newToAddress),
      })
        .then(({ data }) => {
          console.log("Email campaign updated successfully", data);
          setInitialToAddress(newToAddress);
          toggleToModal();
        })
        .catch((error) => {
          console.log("Error while updating email campaign", error);
        });
    } else {
      console.log("Please select recipients");
    }
  };

  const handleChange = (e) => {
    setSubject(e.target.value);
  };

  const handleSaveSubject = () => {
    if (subject) {
      if (initialSubject === subject) {
        toggleSubjectModal();
        return;
      }
      EmailCampaignAPI.updateEmailCampaign(auth_token, emailCampaignId, {
        subject: subject,
      })
        .then(({ data }) => {
          console.log("Email campaign updated successfully", data);
          setInitialSubject(subject);
          toggleSubjectModal();
        })
        .catch((error) => {
          console.log("Error while updating email campaign", error);
        });
    } else {
      console.log("Please enter a subject.");
    }
  };

  const handleTitleEdit = () => {
    setIsEditingTitle(true);
  };

  const handleTitleSave = () => {
    setIsEditingTitle(false);
    EmailCampaignAPI.updateEmailCampaign(auth_token, emailCampaignId, {
      name: title,
    });
  };

  const toggleFromModal = () => {
    setIsFromModalOpen(!isFromModalOpen);
  };

  const toggleToModal = () => {
    setIsToModalOpen(!isToModalOpen);
  };

  const toggleSubjectModal = () => {
    setIsSubjectModalOpen(!isSubjectModalOpen);
  };

  useEffect(() => {
    getEmailCampaign();
    getFromAddress();
  }, []);

  const handleDifferentTemplate = () => {
    navigate(`/emailtemplategallery?emailCampaignId=${emailCampaignId}`, {
      state: { from: location },
    });
  };

  const handleEditTemplate = () => {
    navigate(
      `/editemailtemplate/${emailTemplateId}?emailCampaignId=${emailCampaignId}`
    );
  };

  const handleSend = () => {
    const auth_token = localStorage.getItem("auth_token");
    EmailCampaignAPI.sendPreviewEmail(auth_token, emailCampaignId, {
      email: previewEmail,
    })
      .then(({ data }) => {
        setMessage({
          text: "Preview email sent successfully",
          type: "success",
        });
      })
      .catch((error) => {
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          setMessage({ text: error.response.data.error, type: "danger" });
        } else {
          setMessage({
            text: "Error while sending preview email",
            type: "danger",
          });
        }
        console.log("Error while fetching shortlinks", error);
      });
  };
  const handleClick = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await updateEmailCampaign();
    } catch (err) {
      setError("Failed to schedule the email campaign. Please try again.");
      setLoading(false);
    } finally {
    }
  };
  return (
    <div>
      <div className="w-full d-flex">
        <div className="mt-md-10 w-[280px] me-md-7 mt-xs-0 me-xs-0">
          <Sidebar />
        </div>
        <div className="customDashboardSize">
          <div className="d-flex flex-column w-100 flex-root app-root">
            <div className="app-page flex-column flex-column-fluid">
              <div className="app-wrapper flex-column flex-row-fluid pe-3 pe-lg-0 me-lg-10 mt-lg-10 mt-0 position-relative">
                <div className="app-main flex-column flex-row-fluid">
                  <Card className="card card-flush">
                    <div
                      className="card card-flush position-absolute scroll-y"
                      data-kt-scroll-max-height="auto"
                      data-kt-scroll-offset="100px"
                      style={{
                        right: "0px",
                        bottom: "0px",
                        height: "100%",
                        left: "0",
                        marginLeft: "27px",
                      }}
                    >
                      <div className="ps-10 pt-10 d-flex justify-content-between w-100">
                        <h2 className="me-15 fs-2x mb-0">
                          <Link to="/emailcampaigns">
                            <a>
                              <i className="fal fa-circle-arrow-left fs-1 me-3 text-gray-600 text-hover-dark"></i>
                            </a>
                          </Link>
                          {isEditingTitle ? (
                            <input
                              type="text"
                              value={title}
                              onChange={(e) => setTitle(e.target.value)}
                              className="form-control d-inline-block w-auto"
                            />
                          ) : (
                            title
                          )}
                          {isEditingTitle ? (
                            <button
                              onClick={handleTitleSave}
                              className="btn btn-icon"
                            >
                              <i className="fas fa-check"></i>
                            </button>
                          ) : (
                            <button
                              onClick={handleTitleEdit}
                              className="btn btn-icon"
                            >
                              <i className="fas fa-pencil-alt"></i>
                            </button>
                          )}
                        </h2>

                        <div className="d-flex mx-3 gap-3">
                          {error && (
                            <div className="text-danger mt-2">{error}</div>
                          )}
                          <button
                            type="button"
                            class="btn text-nowrap rounded-1 btn-info text-white"
                            color="danger"
                            onClick={toggle}
                          >
                            Preview & Test
                          </button>

                          <button
                            type="button"
                            className="btn text-nowrap rounded-1 btn-info text-white"
                            style={{ width: "150px" }}
                            onClick={(e) => handleClick(e)}
                            disabled={isScheduleDisabled}
                          >
                            {loading ? (
                              <Spinner size="sm" color="light" />
                            ) : (
                              "Schedule"
                            )}
                          </button>
                        </div>
                      </div>

                      <div className="separator border-1 my-5"></div>

                      <div className="card-body d-flex pt-0 w-100">
                        <div className="row w-100">
                          <div className="col-6 h-100">
                            <div className="border h-fit-content rounded-1 w-100 mt-5">
                              <div>
                                <div className="d-flex justify-content-between align-items-center py-10 px-20 border-bottom">
                                  <div>
                                    <h3 className="fs-4 fw-bold mb-2">From</h3>
                                    <p className="text-gray-600 fs-5">
                                      Choose which From Address you email will
                                      show.
                                    </p>
                                  </div>
                                  <div>
                                    <button
                                      onClick={toggleFromModal}
                                      className="btn btn-icon bg-light-info border rounded-1 fw-bold fs-5 hover-elevate-up text-info"
                                    >
                                      <i className="far fa-pen-to-square"></i>
                                    </button>
                                  </div>
                                </div>
                                <div className="d-flex justify-content-between py-10 px-20 border-bottom">
                                  <div>
                                    <h3 className="fs-4 fw-bold mb-2">To</h3>
                                    <p className="text-gray-600 fs-5">
                                      Select a list of recipients
                                    </p>
                                  </div>
                                  <div>
                                    <button
                                      onClick={toggleToModal}
                                      className="btn btn-icon bg-light-info border rounded-1 fw-bold fs-5 hover-elevate-up text-info"
                                    >
                                      <i className="far fa-pen-to-square"></i>
                                    </button>
                                  </div>
                                </div>
                                <div className="px-20 border-bottom">
                                  <div className="d-flex justify-content-between py-10">
                                    <div>
                                      <h3 className="fs-4 fw-bold mb-2">
                                        Subject
                                      </h3>
                                      <p className="text-gray-600 fs-5">
                                        Add a subject line to your email
                                      </p>
                                    </div>
                                    <div>
                                      <button
                                        onClick={toggleSubjectModal}
                                        className="btn btn-icon bg-light-info border rounded-1 fw-bold fs-5 hover-elevate-up text-info"
                                      >
                                        <i className="far fa-plus"></i>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-6 pt-5 pe-0 position-relative">
                            {showEdit ? (
                              <div className="d-flex justify-content-end mb-3 gap-3">
                                <button
                                  type="button"
                                  class="btn text-nowrap rounded-1 btn-info text-white"
                                  color="danger"
                                  onClick={handleDifferentTemplate}
                                >
                                  Choose a different template
                                </button>
                                <button
                                  type="button"
                                  class="btn text-nowrap rounded-1 btn-info text-white"
                                  color="danger"
                                  onClick={handleEditTemplate}
                                >
                                  Edit Template
                                </button>
                              </div>
                            ) : (
                              <div className="d-flex justify-content-end mb-3 gap-3">
                                <button
                                  type="button"
                                  class="btn text-nowrap rounded-1 btn-info text-white"
                                  color="danger"
                                  onClick={handleDifferentTemplate}
                                >
                                  Add a template
                                </button>
                              </div>
                            )}
                            <div
                              className="rounded-1 h-100 position-sticky"
                              style={{
                                border: "1px solid #e9e6f1",
                                top: "50px",
                                backgroundColor: "#F7F5FD",
                              }}
                            >
                              <div
                                className="card border-0 card-flush d-flex justify-content-center bg-transparent position-sticky p-3 pt-0 mb-0"
                                style={{ top: "60px" }}
                              >
                                {emailCampaign.emailTemplate &&
                                emailCampaign.emailTemplate.content ? (
                                  <>
                                    <iframe
                                      srcDoc={
                                        emailCampaign.emailTemplate.content
                                      }
                                      width="100%"
                                      height="650"
                                      class="mx-auto"
                                    ></iframe>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal isOpen={isFromModalOpen} toggle={toggleFromModal}>
        <ModalHeader toggle={toggleFromModal}>From</ModalHeader>
        <ModalBody>
          <Sender
            selectedSender={selectedSender}
            setSelectedSender={setSelectedSender}
            replyToEmail={replyToEmail}
            setReplyToEmail={setReplyToEmail}
            sendToField={sendToField}
            setSendToField={setSendToField}
            showEmailForm={showEmailForm}
            setShowEmailForm={setShowEmailForm}
            tooltipOpen={tooltipOpen}
            setTooltipOpen={setTooltipOpen}
            isAdvancedOpen={isAdvancedOpen}
            setIsAdvancedOpen={setIsAdvancedOpen}
            senderName={senderName}
            setSenderName={setSenderName}
            senderEmail={senderEmail}
            setSenderEmail={setSenderEmail}
            handleAddSender={handleAddSender}
            handleSave={handleSave}
            handleCancel={handleCancel}
            fromValues={values}
          />
        </ModalBody>
      </Modal>

      {/* To Modal */}
      <Modal isOpen={isToModalOpen} toggle={toggleToModal}>
        <ModalHeader toggle={toggleToModal}>Select Recipients</ModalHeader>
        <ModalBody>
          <To
            handleTo={handleTo}
            setToAddress={setToAddress}
            toAddress={toAddress}
            userTags={userTags}
          ></To>
        </ModalBody>
      </Modal>

      <Modal isOpen={isSubjectModalOpen} toggle={toggleSubjectModal}>
        <ModalHeader toggle={toggleSubjectModal}>Enter Subject</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label for="selectSender">Enter Subject</Label>
            <Input
              type="text"
              name="subject"
              id="subjectModal"
              placeholder="Enter Subject"
              value={subject}
              onChange={(e) => handleChange(e)}
            />
            <div className="d-flex justify-content-end mt-3">
              <button
                type="button"
                className="btn text-nowrap rounded-1 btn-secondary"
                onClick={handleCancelSubject}
              >
                Cancel
              </button>

              <button
                type="button"
                class="btn text-nowrap rounded-1 btn-info text-white"
                color="danger"
                onClick={handleSaveSubject}
                style={{ marginLeft: "10px" }}
              >
                Save
              </button>
            </div>
          </FormGroup>
        </ModalBody>
      </Modal>

      <Modal size="xl" isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          <div>
            <h3 className="mt-0 text-dark tp-section-title fs-2x">
              Preview &amp; Test
            </h3>
          </div>
        </ModalHeader>
        <ModalBody>
          <div className="col-12 pe-0 mt-6">
            <div className="d-flex w-100">
              <div className="w-100 pe-10">
                <Label className="fs-5 fw-bold mb-2">
                  <span>Select email</span>
                </Label>
                <div className="custom-dropdown form-control p-0">
                  <Input
                    className="form-control h-50px rounded-1 border"
                    type="text"
                    value={previewEmail}
                    onChange={(e) => {
                      setPreviewEmail(e.target.value);
                      setMessage({ text: "", type: "" });
                    }}
                    onBlur={(e) => {
                      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                      if (!emailRegex.test(e.target.value)) {
                        setMessage({
                          text: "Invalid email address",
                          type: "danger",
                        });
                      } else {
                        setMessage({ text: "", type: "" });
                      }
                    }}
                  />
                </div>
              </div>
              <div className="mt-9">
                <Button
                  className="btn btn-info rounded-1 h-50px mb-3 w-250px text-white"
                  onClick={handleSend}
                  disabled={message.type === "danger"}
                >
                  Send Test Email
                </Button>
              </div>
            </div>
            {message.text && (
              <div
                className={`alert ${
                  message.type === "success" ? "alert-success" : "alert-danger"
                }`}
                role="alert"
              >
                {message.text}
              </div>
            )}
          </div>

          <div className="col-12 pt-5 pb-8 px-0 position-relative">
            <div className="col-12 py-5 rounded-1 ps-10 mb-5 border">
              <p className="fs-5 mb-1">
                <span class="fw-bold">From : </span>
                <span className="text-gray-600">
                  {emailCampaign.fromAddress || "Divsly <support@divsly.com>"}
                </span>
              </p>
              <p className="fs-5 mb-1">
                <span class="fw-bold">Reply : </span>
                <span className="text-gray-600">
                  {emailCampaign.replyToAddress ||
                    emailCampaign.fromAddress ||
                    "support@divsly.com"}
                </span>
              </p>

              <p className="fs-5 mb-1">
                <span class="fw-bold">Subject : </span>
                <span className="text-gray-600">
                  {emailCampaign.subject || "Divsly Preview Test Email"}
                </span>
              </p>
            </div>
            <div
              className="rounded-1 h-fit-content pt-5 position-sticky"
              style={{
                border: "1px solid #e9e6f1",
                top: "50px",
                backgroundColor: "#F7F5FD",
              }}
            >
              <div
                className="card border-0 card-flush d-flex justify-content-center bg-transparent position-sticky p-3 pt-0 mb-0"
                style={{ top: "60px" }}
              >
                <div id="desktop-content-prev">
                  <div className="iframe desktop overflow-hidden">
                    {emailCampaign.emailTemplate &&
                    emailCampaign.emailTemplate.content ? (
                      <>
                        <iframe
                          srcDoc={emailCampaign.emailTemplate.content}
                          width="100%"
                          height="100%"
                          class="mx-auto"
                        ></iframe>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default EmailCampaignSettings;
