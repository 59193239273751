import React from 'react'

const IconFrame5 = ({ primary,  secondary, fontSize, text, textColor, type, url, width, height, minFontSize, maxFontSize }) => {
  return (
    <>
  

 
<svg width={width || 2373} height={height || 2928} viewBox="0 0 2373 2928" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_175_7444)">
<path d="M0 1162C0 520.245 520.245 0 1162 0H1211C1852.75 0 2373 520.245 2373 1162V2864C2373 2899.35 2344.35 2928 2309 2928H64C28.6538 2928 0 2899.35 0 2864V1162Z" fill={primary || "#0E1B21"}/>
<circle cx="1197" cy="1181" r="1050" fill={secondary || "white"}/>


<text fill={textColor || "white"} xmlSpace="preserve" style={{ whiteSpace: "pre" }} fontFamily="Arial" fontSize={fontSize || 380} fontWeight="bold" letterSpacing="0em">
      <tspan x="50%" textAnchor='middle' y="2633.238">{text}</tspan>
    </text>
<circle cx="1197" cy="1181" r="870" fill="white" stroke="black" stroke-width="48"/>
</g>
<defs>
<clipPath id="clip0_175_7444">
<rect width="2373" height="2928" fill="white"/>
</clipPath>
</defs>
<image href={url} x="625" y="609" width="1144" height="1144" />
</svg>

    </>
  )
}

export default IconFrame5