import React, { useState } from "react";

const VideoForm = ({ formData, setFormData }) => {
  const [videoLink, setVideoLink] = useState(
    "https://www.youtube.com/watch?v=AMK1VsDLTug"
  );
  const [videoData, setVideoData] = useState([]);
  const [error, setError] = useState("");

  const handleInputChange = (e) => {
    setVideoLink(e.target.value);
  };

  const fetchVideoData = async () => {
    if(!videoLink) {
      setError("Please enter a YouTube video link");
      return;
    }
    const videoId = extractVideoId(videoLink);
    if (!videoId) {
      setError("Invalid video link. Please provide a valid YouTube link.");
      setVideoData([]);
      return;
    }

    // Replace 'YOUR_API_KEY' with your actual YouTube Data API key
    const apiKey = "AIzaSyCsK66TOZCXnEcbZ_txhwcwFC8PpYWOP_k";
    const url = `https://www.googleapis.com/youtube/v3/videos?id=${videoId}&key=${apiKey}&part=snippet`;

    try {
      const response = await fetch(url);
      const data = await response.json();
      console.log("Yt-Response: ", data?.items[0]);

      if (data?.items && data?.items.length > 0) {
        const ytVideoData = {
          id: data?.items[0]?.id,
          title: data?.items[0]?.snippet?.title,
          description: data?.items[0]?.snippet?.description,
          thumbnail: data?.items[0]?.snippet?.thumbnails?.high?.url,
        };
        handleFormData(ytVideoData);
        setVideoData((prev) => {
          return [...prev, ytVideoData];
        });
        setVideoLink("");
        setError("");
      } else {
        setError("Video not found.");
        setVideoData([]);
      }
    } catch (err) {
      console.error(err);
      setError("An error occurred while fetching the video data.");
      setVideoData([]);
    }
  };

  // Extract video ID from YouTube URL
  const extractVideoId = (link) => {
    const regex =
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^&\n]{11})/;
    const matches = link.match(regex);
    return matches ? matches[1] : null;
  };

  const handleFormData = (ytData) => {
    const { id, title, thumbnail, description } = ytData;

    if (!id)
      throw new Error("video id is missing from the google api response");

    const embedLink = `https://www.youtube.com/embed/${id}`;

    const videos = formData?.videos ? [...formData?.videos] : [];

    videos?.push({
      youtubeLink: videoLink,
      embedLink: embedLink,
      title,
      description,
      thumbnail,
    });
    setFormData((prev) => {
      return { ...prev, videos: [...videos] };
    });
  };

  // video remove
  const handleVideoRemove = (index) => {
    const updatedList = formData?.videos?.filter((ele, i) => i !== index);
    setVideoData(updatedList);
    setFormData((prev) => {
      return { ...prev, videos: updatedList };
    });
  };

  return (
    <div>
      <label className="fs-4 fw-semibold mb-4 form-label">Add a Video Link</label>
      <div className="d-flex gap-5">
      <input
        type="text"
        className="form-control h-50px mb-0"
        value={videoLink}
        onChange={handleInputChange}
        placeholder="Enter YouTube video link"
        style={{ width: "100%", padding: "8px", marginBottom: "10px" }}
      />
      <button
        onClick={fetchVideoData}
        className="btn btn-outline text-info w-150px border border-info rounded-1"
        style={{ padding: "10px 15px" }}
      >
        Add Video
      </button>
      </div>

      {error && <p style={{ color: "red" }}>{error}</p>}
      <div>
        {videoData?.map((item, key) =>
          <div className="position-relative w-100" key={key} style={{ margin: "24px 0" }}>
            <i onClick={() => handleVideoRemove(key)} style={{ height: "32px", top: "10px", right: "10px" }} className="fa-solid fa-circle-xmark fs-4 text-danger position-absolute"></i>
            <div
            className="bg-light"
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              overflow: "hidden",
              gap: "16px",
              borderRadius: "7px",
              padding: "7px",
              border: "1px solid #dedede",
                      }}
            >
            <img
              src={item?.thumbnail}
              alt={item?.title}
              className="rounded-1"
              style={{ width: "100%", height: "100%", maxWidth: "100px", objectFit: "cover" }}
              />
             <div>
             <h3>{item?.title}</h3>
             <p className="text-wrap-oneline mb-0" style={{width: "95%"}}>{item?.description}</p>
             </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default VideoForm;
