

import { useContext, useEffect, useRef, useState } from "react";
import SubscriptionApi from "../../services/http/subscription";
import { Spinner } from "reactstrap";
import { useStripe } from "@stripe/react-stripe-js";
import delayResponse from "../../utils/delayResponse";
import { AuthContxt } from "../../store/authContxt";
import { UserSettingsContxt } from "../user-settings/userSettingContxt";
import { PLAN } from "../../utils/enum";
import RazorPaySubcription from "../../services/http/razorPaySubcription";
import axios from "axios";

const subscriptionStatusMapper = {
  active: "Subscribed to a plan, updates will take a time.",
  past_due: "Payment processing due to payment due.",
  canceled: "You subscription has been canceled!",
  incomplete: "Subscribing to a plan, updates will take some time.",
};

const Summary = ({ form, planPrices }) => {
  const { profile } = useContext(AuthContxt);
  const { selectedAddress } = useContext(UserSettingsContxt);
  const { watch } = form;
  const { plan } = watch();
  const stripe = useStripe();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState();
  const [successMessage, setSuccessMessage] = useState();
  const [statusMsg, setStatusMsg] = useState("");
  const [showPaymentOptions, setShowPaymentOptions] = useState(false);
  const [planQuota, setPlanQuota] = useState({});
  const isAnnual = plan?.includes("yearly");

  // Plan info
  const selectedPlanType = plan?.split("-")[1];
  const subscribedPlanType = profile?.plan?.split("-")[1];
  const subscribedPlanDuration = profile?.plan?.split("-")[0];
  const subscribedPlan = profile?.plan;
  // console.log(
  //   selectedPlanType,
  //   "||",
  //   subscribedPlanType,
  //   "||",
  //   subscribedPlanDuration,
  //   "||",
  //   subscribedPlan,
  //   "||",
  //   plan
  // );
  // Check if the user is already subscribed to the selected plan
  const isAlreadySubscribed =
    selectedPlanType === subscribedPlanType &&
    subscribedPlanDuration === "monthly";

  const resetErrorMessage = () => {
    setTimeout(() => {
      setMessage("");
    }, 5 * 1000);
  };

  const razorPay_Key = process.env.RAZORPAY_KEY;

  const purchaseHandler = async () => {
    try {
      if (!selectedAddress) {
        setStatusMsg("Please select address.");
        setTimeout(() => {
          setStatusMsg("");
        }, 500);
        return;
      }
      setLoading(true);
      const values = watch();
      if (plan === "monthly-super") {
        values.plan = process.env.REACT_APP_MONTHLY_SUPER_KEY;
      } else if (plan === "yearly-super") {
        values.plan = process.env.REACT_APP_YEARLY_SUPER_KEY;
      }
      const response = await SubscriptionApi.createSubscription({
        ...values,
        address: selectedAddress,
      });
      if (response?.data?.error) {
        setMessage(response.data?.data?.message || "Something went wrong");
        setLoading(false);
        resetErrorMessage();
      } else {
        setStatusMsg("Please wait...");
        if (response?.data?.status === "requires_action") {
          const result = await stripe.confirmCardPayment(
            response?.data?.client_secret
          );
          if (result.error) {
            setStatusMsg(
              result.error?.message || "Payment is not processed yet"
            );
            console.log(result.error);
          } else {
            setStatusMsg(subscriptionStatusMapper[response.data?.data?.status]);
            await delayResponse(5);
          }
        } else {
          await delayResponse(5);
          setStatusMsg(
            subscriptionStatusMapper[response.data?.data?.status || "active"]
          );
        }
        setLoading(false);
      }
    } catch (e) {
      console.log("Error while creating subscription: ", e);
      setMessage(
        e?.response?.data?.data?.message ||
          e?.response?.data?.message ||
          "Something went wrong"
      );
      setLoading(false);
      resetErrorMessage();
    }
  };

  const MON = "monthly";
  const latestPlan = useRef("");

  useEffect(() => {
    if (selectedPlanType) {
      const fetchDefaultPlan = async () => {
        latestPlan.current = selectedPlanType;
        try {
          const response = await SubscriptionApi.getDefaultPlan(
            selectedPlanType
          );
          // console.log(
          //   selectedPlanType,
          //   "selectedPlanType",
          //   response.data.error
          // );
          if (!response.data.error && latestPlan.current === selectedPlanType) {
            setPlanQuota(response?.data?.data);
          }
        } catch (e) {
          console.log("Error while fetching default plan: ", e);
        }
      };

      fetchDefaultPlan();
    }
  }, [selectedPlanType]);

  const {
    connectedDomain = 0,
    qrCode = 0,
    bulkShortening = 0,
    linkInBioPages = 0,
    linkRedirect = 0,
    linkEdit = 0,
    shortlink = 0,
  } = planQuota || {};

  const selectedPlan = planPrices?.filter((item) =>
    plan?.includes(item?.plan)
  )[0];
  const {
    currency = "usd",
    monthlyPrice = 0,
    yearlyPrice = 0,
  } = selectedPlan || {};
  const currencyMapper = {
    usd: "$",
  };
  const calculatedPrice = `${currencyMapper[currency] || currency}${
    isAnnual ? yearlyPrice * 12 : monthlyPrice
  }`;

  const monthlyTotal = monthlyPrice * 12;
  const yearlyTotal = yearlyPrice * 12;

  const savings = monthlyTotal - yearlyTotal;

  //////////////////////////

  useEffect(() => {
    // Add the Razorpay script to the page
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);

    // Cleanup on component unmount
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  ///////////////////////////

  // razorpay function to create subscripion ID by waqas

  const handleRazorPaySubscription = async () => {
    setLoading(true);
    const values = watch();
    try {
      if (plan === "monthly-super") {
        values.plan = process.env.REACT_APP_MONTHLY_SUPER_KEY;
      } else if (plan === "yearly-super") {
        values.plan = process.env.REACT_APP_YEARLY_SUPER_KEY;
      }

      console.log(values, "values here ");
      const response = await RazorPaySubcription.createSubscription({
        ...values,
      });
      console.log(response.data);
      return response;
    } catch (error) {
      console.log(error, "error here ");
    }
  };

  // handle payment function to process payment

  const handlePayment = async () => {
    const response = await handleRazorPaySubscription();

    const options = {
      // key: process.env.RAZORPAY_KEY,
      key: "rzp_test_mBL9SHJtJkoxCt", // Replace with your key_id
      subscription_id: `${response?.data?.razorSubscription?.id}`,
      name: `${profile?.firstName + " " + profile?.lastName}`,
      description: "Subscription",
      image: `${profile?.avatar}`,
      prefill: {
        name: `${profile?.firstName + " " + profile?.lastName}`,
        email: `${profile?.email}`,
        contact: `${profile?.mobile}`,
      },
      theme: {
        color: "#F37254",
      },
      handler: async function (response) {
        // Handle payment success response here
        const paymentVerificationResponse = await fetch(
          `${process.env.REACT_APP_API_URL}/payment_verification?id=${profile.id}&plan=${plan}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              razorpay_payment_id: response.razorpay_payment_id,
              razorpay_subscription_id: response.razorpay_subscription_id,
              razorpay_signature: response.razorpay_signature,
            }),
          }
        );

        const result = await paymentVerificationResponse.json();
        console.log(result);
        if (result) {
          setSuccessMessage(result?.message);
          setLoading(false);
        }
        console.log("Payment verified", result);
      },
    };

    const rzp1 = new window.Razorpay(options);
    rzp1.open();
  };

  return (
    <div className="flex-column flex-lg-row-auto w-100 w-xl-400px mb-10">
      <div
        className="card card-flush bg-light border-info border-opacity-25 mb-0"
        style={{ height: "fit-content", position: "sticky", top: "5px" }}
      >
        <div className="card-header">
          <div className="card-title">
            <h2>Purchase summary</h2>
          </div>
        </div>

        <div className="card-body pt-0 fs-6">
          <div className="separator separator-dashed mb-7"></div>

          <div className="mb-7">
            <h5 className="mb-4">Plan details</h5>

            <div className="mb-0">
              <span className="badge badge-success me-2">{`${selectedPlanType} Plan`}</span>
              <span className="fw-semibold text-gray-600">
                {`${calculatedPrice} / ${isAnnual ? "Year" : "Month"}`}
              </span>
            </div>
            {isAnnual && selectedPlanType !== PLAN.STARTER ? (
              <div className="mb-0 mt-4">
                <div className="alert alert-success bg-light-success border-left-4 rounded-1 border-top-dashed border-right-dashed border-bottom-dashed border-left-">
                  <div className="fs-6 fs-semibold">
                    Save{" "}
                    <span className="fw-bold">
                      {" "}
                      {currencyMapper[currency] || currency}
                      {savings.toFixed(2)}{" "}
                    </span>{" "}
                    when Purchase Annually{" "}
                    <span className="fw-bold">
                      ({((savings / monthlyTotal) * 100).toFixed(0)}%)
                    </span>
                  </div>
                </div>
              </div>
            ) : null}

            <div className="mt-5">
              <ul>
                <li>{`${shortlink} Shortlink/mon`}</li>
                <li>{`${connectedDomain} Connected domains/mon`}</li>
                <li>{`${qrCode} Qr Codes/mon`}</li>
                <li>{`${
                  linkRedirect === null ? "Unlimited" : linkRedirect
                } Link Redirects`}</li>
                <li>{`${
                  linkEdit === null ? "Unlimited" : linkEdit
                } Link Edits`}</li>
                <li>{`Bulk Shortening ${bulkShortening}/CSV`}</li>
                <li>{`${linkInBioPages} Link-in-bio`}</li>
              </ul>
            </div>
          </div>

          <div className="separator separator-dashed mb-4"></div>

          {/* Display the "Already Subscribed" message */}
          {isAlreadySubscribed && (
            <div className="alert alert-info text-center mb-4">
              You are already subscribed to this plan.
            </div>
          )}

          <div className="d-flex justify-content-between align-items-center mb-4">
            <h5 className="fw-bold fs-1 mb-0">
              {selectedPlanType === PLAN.STARTER
                ? "Billed "
                : `Billed ${subscribedPlan !== plan ? "Now" : ""}`}
            </h5>
            <div className="fw-bold fs-2x">{calculatedPrice}</div>
          </div>

          <div className="separator separator-dashed mb-4"></div>

          <div
            style={{
              textAlign: "end",
            }}
          >
            {message ? (
              <span className="text-error fs-6 fw-bold">{message}</span>
            ) : null}
          </div>

          <div
            style={{
              textAlign: "end",
            }}
          >
            {statusMsg !== "" ? (
              <span className="link-primary fs-6 fw-bold">{statusMsg}</span>
            ) : null}
          </div>

          {/* Conditionally render payment options */}
          {!isAlreadySubscribed && (
            <div className="mt-4">
              {/* <button
                onClick={purchaseHandler}
                className="btn btn-success w-100 mb-3"
              >
                Pay with Stripe
              </button> */}
              <div
                style={{
                  textAlign: "end",
                }}
              >
                {successMessage !== "" ? (
                  <span className="link-primary fs-6 fw-bold">
                    {successMessage}
                  </span>
                ) : null}
              </div>

              <div>
                {loading ? (
                  <Spinner />
                ) : (
                  <button
                    onClick={handlePayment}
                    className="btn btn-danger w-100"
                  >
                    {subscribedPlan !== subscribedPlanDuration
                      ? "Upgrade Plan"
                      : "Pay with Razorpay"}
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Summary;






                                              /// OLD CODE OF SUMMARY COMPONENT///

// // import { useContext, useEffect, useRef, useState } from "react";
// // import SubscriptionApi from "../../services/http/subscription";
// // import { Spinner } from "reactstrap";
// // import { useStripe } from "@stripe/react-stripe-js";
// // import delayResponse from "../../utils/delayResponse";
// // import { AuthContxt } from "../../store/authContxt";
// // import { UserSettingsContxt } from "../user-settings/userSettingContxt";
// // import { PLAN } from "../../utils/enum";
// // import RazorPaySubcription from "../../services/http/razorPaySubcription";

// // const subscriptionStatusMapper = {
// //   active: "Subscribed to a plan, updates will take a time.",
// //   past_due: "Payment processing due to payment due.",
// //   canceled: "You subscription has been canceled!",
// //   incomplete: "Subscribing to a plan, updates will take some time.",
// // };

// // const Summary = ({ form, planPrices }) => {
// //   const { profile } = useContext(AuthContxt);
// //   const { selectedAddress } = useContext(UserSettingsContxt);
// //   const { watch } = form;
// //   const { plan } = watch();
// //   const stripe = useStripe();
// //   const [loading, setLoading] = useState(false);
// //   const [message, setMessage] = useState();
// //   const [statusMsg, setStatusMsg] = useState("");

// //   const [planQuota, setPlanQuota] = useState({});

// //   const isAnnual = plan?.includes("yearly");

// //   const resetErrorMessage = () => {
// //     setTimeout(() => {
// //       setMessage("");
// //     }, 5 * 1000);
// //   };

// //   const purchaseHandler = async () => {
// //     try {
// //       if (!selectedAddress) {
// //         setStatusMsg("Please select address.");
// //         setTimeout(() => {
// //           setStatusMsg("");
// //         }, 500);
// //         return;
// //       }
// //       setLoading(true);
// //       const values = watch();
// //       if(plan == "monthly-super"){
// //         values.plan = process.env.REACT_APP_MONTHLY_SUPER_KEY
// //       }
// //       else if(plan == "yearly-super"){
// //         values.plan = process.env.REACT_APP_YEARLY_SUPER_KEY

// //       }
// //       const response = await SubscriptionApi.createSubscription({
// //         ...values,
// //         address: selectedAddress,
// //       });
// //       if (response?.data?.error) {
// //         setMessage(response.data?.data?.message || "Something went wrong");
// //         setLoading(false);
// //         resetErrorMessage();
// //       } else {
// //         setStatusMsg("Please wait...");
// //         if (response?.data?.status === "requires_action") {
// //           const result = await stripe.confirmCardPayment(
// //             response?.data?.client_secret
// //           );
// //           if (result.error) {
// //             setStatusMsg(
// //               result.error?.message || "Payment is not processed yet"
// //             );
// //             console.log(result.error);
// //           } else {
// //             setStatusMsg(subscriptionStatusMapper[response.data?.data?.status]);
// //             await delayResponse(5);
// //           }
// //         } else {
// //           await delayResponse(5);
// //           setStatusMsg(
// //             subscriptionStatusMapper[response.data?.data?.status || "active"]
// //           );
// //         }
// //         setLoading(false);
// //       }
// //     } catch (e) {
// //       console.log("Error while creating subscription: ", e);
// //       setMessage(
// //         e?.response?.data?.data?.message ||
// //           e?.response?.data?.message ||
// //           "Something went wrong"
// //       );
// //       setLoading(false);
// //       resetErrorMessage();
// //     }
// //   };
// //   const handleSubscription = async () => {
// //     const values = watch();
// //    try {
// //     if(plan == "monthly-super"){
// //       values.plan = process.env.REACT_APP_MONTHLY_SUPER_KEY
// //     }
// //     else if(plan == "yearly-super"){
// //       values.plan = process.env.REACT_APP_YEARLY_SUPER_KEY

// //     }

// //     console.log(values, "values here ")
// //     const response = await RazorPaySubcription.createSubscription({
// //       ...values,

// //     });
// //     console.log(response, "razorpay response here")

// //    } catch (error) {
// //     console.log(error, "error here ")
// //    }
// //     }

// //   const MON = "monthly";
// //   const selectedPlanType = plan?.split("-")[1];
// //   const subscribedPlanType = profile?.plan?.split("-")[1];
// //   const subscribedPlanDuration = profile?.plan?.split("-")[0];
// //   const subscribedPlan = profile?.plan;
// //   const latestPlan = useRef('')

// //   useEffect(() => {
// //     if (selectedPlanType) {
// //       const fetchDefaultPlan = async () => {
// //         latestPlan.current = selectedPlanType;
// //         try {
// //           const response = await SubscriptionApi.getDefaultPlan(
// //             selectedPlanType
// //           );
// //           console.log(selectedPlanType, 'selectedPlanType', response.data.error)
// //           if (!response.data.error && latestPlan.current === selectedPlanType) {
// //             setPlanQuota(response?.data?.data);
// //           }
// //         } catch (e) {
// //           console.log("Error while fetching default plan: ", e);
// //         }
// //       };

// //       fetchDefaultPlan();
// //     }
// //   }, [selectedPlanType]);

// //   const {
// //     connectedDomain = 0,
// //     qrCode = 0,
// //     bulkShortening = 0,
// //     linkInBioPages = 0,
// //     linkRedirect = 0,
// //     linkEdit = 0,
// //     shortlink = 0,
// //   } = planQuota || {};

// //   const selectedPlan = planPrices?.filter((item) =>
// //     plan?.includes(item?.plan)
// //   )[0];
// //   const {
// //     currency = "usd",
// //     monthlyPrice = 0,
// //     yearlyPrice = 0,
// //   } = selectedPlan || {};
// //   const currencyMapper = {
// //     usd: "$",
// //   };
// //   const calculatedPrice = `${currencyMapper[currency] || currency}${
// //     isAnnual ? yearlyPrice * 12 : monthlyPrice
// //   }`;

// //   const monthlyTotal = monthlyPrice * 12;
// //   const yearlyTotal = yearlyPrice * 12;

// //   const savings = monthlyTotal - yearlyTotal;

// //   return (
// //     <div class="flex-column flex-lg-row-auto w-100 w-xl-400px mb-10">
// //       <div
// //         class="card card-flush bg-light border-info border-opacity-25 mb-0"
// //         style={{ height: "fit-content", position: "sticky", top: "5px" }}
// //       >
// //         <div class="card-header">
// //           <div class="card-title">
// //             <h2>Purchase summary</h2>
// //           </div>
// //         </div>

// //         <div class="card-body pt-0 fs-6">
// //           <div class="separator separator-dashed mb-7"></div>

// //           <div class="mb-7">
// //             <h5 class="mb-4">Plan details</h5>

// //             <div class="mb-0">
// //               <span class="badge badge-success me-2">{`${selectedPlanType} Plan`}</span>

// //               <span class="fw-semibold text-gray-600">
// //                 {`${calculatedPrice} / ${isAnnual ? "Year" : "Month"}`}
// //               </span>
// //             </div>
// //             {isAnnual && selectedPlanType !== PLAN.STARTER ? (
// //               <div class="mb-0 mt-4">
// //                 <div class="alert alert-success bg-light-success border-left-4 rounded-1 border-top-dashed border-right-dashed border-bottom-dashed border-left-">
// //                   <div class="fs-6 fs-semibold">
// //                     Save{" "}
// //                     <span class="fw-bold">
// //                       {" "}
// //                       {currencyMapper[currency] || currency}
// //                       {savings.toFixed(2)}{" "}
// //                     </span>{" "}
// //                     when Purchase Annually{" "}
// //                     <span class="fw-bold">
// //                       ({((savings / monthlyTotal) * 100).toFixed(0)}%)
// //                     </span>
// //                   </div>
// //                 </div>
// //               </div>
// //             ) : null}

// //             <div class="mt-5">
// //               <ul>
// //                 <li>{`${shortlink} Shortlink/mon`}</li>
// //                 <li>{`${connectedDomain} Connected domains/mon`}</li>
// //                 <li>{`${qrCode} Qr Codes/mon`}</li>
// //                 <li>{`${
// //                   linkRedirect === null ? "Unlimited" : linkRedirect
// //                 } Link Redirects`}</li>
// //                 <li>{`${
// //                   linkEdit === null ? "Unlimited" : linkEdit
// //                 } Link Edits`}</li>
// //                 <li>{`Bulk Shortening ${bulkShortening}/CSV`}</li>
// //                 <li>{`${linkInBioPages} Link-in-bio`}</li>
// //               </ul>
// //             </div>
// //           </div>

// //           <div class="separator separator-dashed mb-4"></div>

// //           <div class="d-flex justify-content-between align-items-center mb-4">
// //             <h5 class="fw-bold fs-1 mb-0">
// //               {selectedPlanType === PLAN.STARTER
// //                 ? "Billed "
// //                 : `Billed ${subscribedPlan !== plan ? "Now" : ""}`}
// //             </h5>
// //             <div class="fw-bold fs-2x">{calculatedPrice}</div>
// //           </div>

// //           <div class="separator separator-dashed mb-4"></div>

// //           <div class="mb-10">
// //             <span class="fs-7">
// //               {selectedPlanType === PLAN.STARTER
// //                 ? "You'll will not charged on FREE plan."
// //                 : subscribedPlan !== plan
// //                 ? `
// //               You'll be charged ${calculatedPrice} until you cancel your
// //               subscription.
// //               `
// //                 : null}
// //             </span>
// //           </div>

// //           <div
// //             style={{
// //               textAlign: "end",
// //             }}
// //           >
// //             {message ? (
// //               <span className="text-error fs-6 fw-bold">{message}</span>
// //             ) : null}
// //           </div>

// //           <div
// //             style={{
// //               textAlign: "end",
// //             }}
// //           >
// //             {statusMsg !== "" ? (
// //               <span className="link-primary fs-6 fw-bold">{statusMsg}</span>
// //             ) : null}
// //           </div>

// //           {selectedPlanType !== PLAN.STARTER ? (
// //             <div
// //               onClick={() => {
// //                 subscribedPlan !== plan && (subscribedPlanType !== 'super' || subscribedPlanDuration === MON) && purchaseHandler();
// //               }}
// //               class="mb-0"
// //             >
// //               {subscribedPlan === plan ? (
// //                 <div>{`✅  Already Subscribed To This Plan`}</div>
// //               ) : selectedPlanType === subscribedPlanType ? (
// //                 <div>
// //                   <span>{`✅  Already Subscribed To This Plan`}</span>
// //                   <span className="btn btn-info rounded-1 mt-2">{loading ? <Spinner /> : `Switch to ${subscribedPlanDuration === MON ? 'Yearly' : 'Monthly'}`}</span>
// //                 </div>
// //               ) : (
// //                 <span className="btn btn-info rounded-1">
// //                   {loading ? <Spinner /> : subscribedPlanType === 'super'  ? "Your current plan is the super plan!" : "Complete Purchase"}
// //                 </span>
// //               )}
// //             </div>

// //           ) : null}
// //           {selectedPlanType !== PLAN.STARTER ? (
// //             <div
// //               onClick={() => {
// //                 subscribedPlan !== plan && (subscribedPlanType !== 'super' || subscribedPlanDuration === MON) && handleSubscription();
// //               }}
// //               class="mb-0"
// //             >
// //               {subscribedPlan === plan ? (
// //                 <div>{`✅  Already Subscribed To This Plan`}</div>
// //               ) : selectedPlanType === subscribedPlanType ? (
// //                 <div>
// //                   <span>{`✅  Already Subscribed To This Plan`}</span>
// //                   <span className="btn btn-info rounded-1 mt-2">{loading ? <Spinner /> : `Switch to ${subscribedPlanDuration === MON ? 'Yearly' : 'Monthly'}`}</span>
// //                 </div>
// //               ) : (
// //                 <span className="btn btn-info rounded-1">
// //                   {loading ? <Spinner /> : subscribedPlanType === 'super'  ? "Your current plan is the super plan!" : "Complete Purchase"}
// //                 </span>
// //               )}

// //             </div>

// //           ) : null}

// //           {/* {subscribedPlan === plan || selectedPlanType === PLAN.STARTER ? (
// //             <span class="btn btn-info rounded-1">
// //               {"Already Subscribed To This Plan"}
// //             </span>
// //           ) : (
// //             <div onClick={purchaseHandler} class="mb-0">
// //               <span class="btn btn-info rounded-1">
// //                 {loading ? <Spinner /> : "Complete Purchase"}
// //               </span>
// //             </div>
// //           )} */}
// //         </div>
// //       </div>
// //     </div>
// //   );
// // };

// // export default Summary;