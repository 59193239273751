import React from 'react'

const IconFrame5 = ({ primary,  secondary, fontSize, text, textColor, type, url, width, height, minFontSize, maxFontSize }) => {
  return (
    <>
  <svg width={width || 1647} height={height || 2295} viewBox="0 0 1647 2295" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clipPath="url(#clip0_175_7406)">
    {/* Background rectangle with rounded corners */}
    <rect width="1647" height="2295" rx="320" fill={primary || "#0E1B21"} />
    
    {/* Bottom rectangle */}
    <rect x="143.5" y="1739.5" width="1361" height="413" rx="206.5" fill={secondary || "white"} stroke={secondary || "white"} />
    
    {/* Main white shape with stroke */}
    <path d="M1009 1468.5H992.159L981.506 1481.54L857.086 1633.87C839.534 1655.36 806.81 1655.72 788.787 1634.63L657.491 1480.94L646.863 1468.5H630.5H368C263.342 1468.5 178.5 1383.66 178.5 1279V367C178.5 262.342 263.342 177.5 368 177.5H1280C1384.66 177.5 1469.5 262.342 1469.5 367V1279C1469.5 1383.66 1384.66 1468.5 1280 1468.5H1009Z" fill="white" stroke={secondary || "white"} strokeWidth={71} />
    
    {/* Text "SCAN ME" */}
    <text fill={textColor || "black"} xmlSpace="preserve" style={{ whiteSpace: "pre" }} fontFamily="Arial" fontSize={fontSize || 224} fontWeight="bold" letterSpacing="0em">
      <tspan x="50%" textAnchor='middle' y="2030.16">{text}</tspan>
    </text>
  </g>
  
  {/* Clip path definition */}
  <defs>
    <clipPath id="clip0_175_7406">
      <rect width="1647" height="2295" fill="white" />
    </clipPath>
  </defs>
  
  {/* Image element */}
  <image href={url} x="248" y="248" width="1144" height="1144" />
</svg>


 

    </>
  )
}

export default IconFrame5