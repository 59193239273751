import { Input, Spinner } from "reactstrap";
import { Controller } from "react-hook-form";
import { useContext, useMemo } from "react";
import dateFormate from "../../utils/dateFormat";
import { AuthContxt } from "../../store/authContxt";
import { PLAN } from "../../utils/enum";
import { addDays } from 'date-fns';

const DateField = ({
  loading,
  form,
  submitHandler,
  fieldName,
  editMode,
  editModeHandler,
}) => {
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = form;

  const fieldValue = watch(fieldName);
  const { quota } = useContext(AuthContxt);

  const formattedDate = useMemo(() => {
    const currentDate = new Date();
    const futureDate = new Date(currentDate);
    futureDate?.setDate(currentDate.getDate() + 90);
    return dateFormate(fieldValue === "" ? futureDate : fieldValue);
  }, [fieldValue]);

  const notUpgraded = quota?.plan === PLAN.STARTER;

  return (
    <div className="d-flex align-items-center ms-2">
      {editMode ? (
        <>
          <div className="d-flex align-items-center">
            <Controller
              name={fieldName}
              control={control}
              render={({ field }) => (
                <Input
                  type="date"
                  {...field}
                  className={"cursor-pointer"}
                  min={addDays(new Date(), 1).toISOString().split("T")[0]}
                />
              )}
            />

            <button
              disabled={loading}
              className="btn btn-icon btn-circle btn-active-color-info w-25px h-25px bg-body ms-2 show-input-edit-title-btn"
              onClick={handleSubmit(() =>
                submitHandler(fieldName, {
                  [fieldName]: new Date(fieldValue),
                })
              )}
            >
              {loading ? <Spinner /> : <i className="fa fa-check"></i>}
            </button>
          </div>
          {errors?.[fieldName] && <p>{errors?.[fieldName]?.message}</p>}
        </>
      ) : (
        <>
          <i className="fa-regular fa-calendar-days fs-3 me-2"></i>
          <p className="mb-0 cursor-pointer">{formattedDate}</p>
          {notUpgraded ? (
            <i className="fas ms-2 fa-lock"></i>
          ) : (
            <span
              className="btn btn-icon btn-circle btn-active-color-info w-25px h-25px bg-body ms-2 show-input-edit-title-btn"
              onClick={() => editModeHandler(fieldName, true)}
            >
              <i className="fa fa-pencil" />
            </span>
          )}
        </>
      )}
    </div>
  );
};

export default DateField;
