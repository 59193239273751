import httpMethods from "..";

const WhatsappCampaignAPI = {
  createWhatsappCampaign: async (token, values) => {
    return httpMethods
      .post(`/whatsapp-campaigns`, values, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => res);
  },

  getAllWhatsappCampaigns: async (token, params) => {
    return httpMethods
      .get(`/whatsapp-campaigns`, {
        params,
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => res)
      .catch((error) => {
        throw error;
      });
  },

  updateWhatsappCampaign: async (token, id, values) => {
    return httpMethods
      .put(`/whatsapp-campaigns/${id}`, values, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => res);
  },

  deleteWhatsappCampaign: async (token, id) => {
    return httpMethods
      .delete(`/whatsapp-campaigns/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => res);
  },

  getWhatsappCampaignsById: async (token, id) => {
    return httpMethods
      .get(`/whatsapp-campaigns/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => res)
      .catch((error) => {
        throw error;
      });
  },

  scheduleWhatsappCampaign: async (token, id, values) => {
    return httpMethods
      .post(`/whatsapp-campaigns/${id}/schedule`, values, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => res);
  },

  sendWhatsappCampaign: async (token, id, values) => {
    return httpMethods
      .post(`/whatsapp-campaigns/${id}/send`, values, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => res);
  },

  sendPreviewWhatsapp: async (token, id, body) => {
    return httpMethods
      .post(`/whatsapp-campaigns/${id}/sendPreviewWhatsapp`, body, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => res);
  },

  getReports: async (token, id) => {
    return httpMethods
      .get(`/whatsapp-campaigns/reports/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => res);
  },
};

export default WhatsappCampaignAPI;
