import { useContext, useState } from "react";
import { MenuItem,SubMenu } from "react-pro-sidebar";
import { Link } from "react-router-dom";
import { Tooltip } from "reactstrap";
import { AuthContxt } from "../../store/authContxt";
import { USER_TYPE } from "../../utils/enum";

const UserSidebar = () => {
  const { profile } = useContext(AuthContxt);

  const [dashboardTooltipOpen, setDashboardTooltipOpen] = useState(false);
  const toggleDashboardTooltip = () =>
    setDashboardTooltipOpen(!dashboardTooltipOpen);

  const [linksTooltipOpen, setLinksTooltipOpen] = useState(false);
  const toggleLinksTooltip = () => setLinksTooltipOpen(!linksTooltipOpen);

  const [qrcodeTooltipOpen, setqrcodeTooltipOpen] = useState(false);
  const toggleqrcodeTooltip = () => setqrcodeTooltipOpen(!qrcodeTooltipOpen);

  const [linkinbioTooltipOpen, setlinkinbioTooltipOpen] = useState(false);
  const togglelinkinbioTooltip = () =>
    setlinkinbioTooltipOpen(!linkinbioTooltipOpen);

  const [customlinksTooltipOpen, setcustomlinksTooltipOpen] = useState(false);
  const togglecustomlinksTooltip = () =>
    setcustomlinksTooltipOpen(!customlinksTooltipOpen);

  const [contactsTooltipOpen, setcontactsTooltipOpen] = useState(false);
  const togglecontactsTooltip = () =>
    setcontactsTooltipOpen(!contactsTooltipOpen);

  const [emailCampaignsTooltipOpen, setEmailCampaignsTooltipOpen] = useState(false);
  const toggleEmailCampaignsTooltip = () =>
    setEmailCampaignsTooltipOpen(!emailCampaignsTooltipOpen);

  const [smsCampaignsTooltipOpen, setSmsCampaignsTooltipOpen] = useState(false);
  const toggleSmsCampaignsTooltip = () =>
    setSmsCampaignsTooltipOpen(!smsCampaignsTooltipOpen);
  
  const [whatsappCampaignsTooltipOpen, setWhatsappCampaignsTooltipOpen] = useState(false);
  const toggleWhatsappCampaignsTooltip = () =>
    setWhatsappCampaignsTooltipOpen(!whatsappCampaignsTooltipOpen);

  const [notificationsTooltipOpen, setnotificationsTooltipOpen] =
    useState(false);
  const togglenotificationsTooltip = () =>
    setnotificationsTooltipOpen(!notificationsTooltipOpen);

  const [settingsTooltipOpen, setsettingsTooltipOpen] = useState(false);
  const togglesettingsTooltip = () =>
    setsettingsTooltipOpen(!settingsTooltipOpen);

  return (
    <>
      <div className="text-start ps-4 text-light-brand fs-5 fw-semibold text-uppercase mb-5 menu-title">
        Analytics
      </div>
      <MenuItem
        active={window.location.pathname === "/dashboard"}
        component={<Link to="/dashboard" />}
      >
        {" "}
        <i id="dashboardtooltip" className="fa-regular fa-border-all text-black fs-1"></i>
        <Tooltip
          placement="top"
          isOpen={dashboardTooltipOpen}
          autohide={false}
          target="dashboardtooltip"
          innerClassName="rounded-1"
          toggle={toggleDashboardTooltip}
        >
          Dashboard
        </Tooltip>
        <span className="menu-title fs-4 fw-semibold text-black">
          Dashboard
        </span>{" "}
      </MenuItem>
      <div className="border-bottom border-gray-250 separator-menu"></div>
      <div className="text-start ps-4 text-light-brand fs-5 fw-semibold text-uppercase mt-8 mb-5 menu-title">
        Application
      </div>
      <MenuItem
        active={
          window.location.pathname === "/short-links" ||
          window.location.pathname === "/short-links/"
        }
        component={<Link to="/short-links" />}
      >
        {" "}
        <i
          id="linkstooltip"
          className="fa-regular text-black fa-link fs-1"
          style={{ transform: "rotate(45deg)" }}
        ></i>
        <Tooltip
          placement="top"
          isOpen={linksTooltipOpen}
          autohide={false}
          innerClassName="rounded-1"
          target="linkstooltip"
          toggle={toggleLinksTooltip}
        >
          Links
        </Tooltip>
        <span className="menu-title fs-4 fw-semibold text-black">
          {" "}
          links
        </span>
      </MenuItem>
      <MenuItem
        active={window.location.pathname === "/qr-codes"}
        component={<Link to="/qr-codes" />}
      >
        {" "}
        <i id="qrcodetooltip" className="fa-regular fa-qrcode fs-1 text-black"></i>
        <span className="menu-title fs-4 fw-semibold text-black">
          {" "}
          QR Codes
          <Tooltip
            placement="top"
            isOpen={qrcodeTooltipOpen}
            autohide={false}
          innerClassName="rounded-1"
            target="qrcodetooltip"
            toggle={toggleqrcodeTooltip}
          >
            QR Codes
          </Tooltip>
        </span>
      </MenuItem>

      <MenuItem
        active={window.location.pathname === "/link-in-bio"}
        component={<Link to="/link-in-bio" />}
      >
        <i id="linkinbiotooltip" className="fa-regular fa-file-lines fs-1 text-black"></i>
        <span className="menu-title fs-4 fw-semibold text-black">
          {" "}
          Link in Bio
          <Tooltip
            placement="top"
            isOpen={linkinbioTooltipOpen}
            autohide={false}
          innerClassName="rounded-1"
            target="linkinbiotooltip"
            toggle={togglelinkinbioTooltip}
          >
            Link in Bio
          </Tooltip>
        </span>
      </MenuItem>
      <MenuItem
        active={window.location.pathname === "/custom-links"}
        component={<Link to="/custom-links" />}
      >
        {" "}
        <i id="customlinkstooltip" className="fa-regular fa-link text-black fs-1"></i>
        <span className="menu-title fs-4 fw-semibold text-black">
          {" "}
          Custom Links
          <Tooltip
            placement="top"
            isOpen={customlinksTooltipOpen}
            autohide={false}
          innerClassName="rounded-1"
            target="customlinkstooltip"
            toggle={togglecustomlinksTooltip}
          >
            Custom Links
          </Tooltip>
        </span>
      </MenuItem>
      <MenuItem
        active={window.location.pathname === "/contacts"}
        component={<Link to="/contacts" />}
      >
        {" "}
        <i id="contactstooltip" className="fa-regular fa-address-book text-black fs-1"></i>
        <span className="menu-title fs-4 fw-semibold text-black">
          {" "}
          Contacts
          <Tooltip
            placement="top"
            isOpen={contactsTooltipOpen}
            autohide={false}
          innerClassName="rounded-1"
            target="contactstooltip"
            toggle={togglecontactsTooltip}
          >
            Contacts
          </Tooltip>
        </span>
      </MenuItem>
      <SubMenu label={<span className="menu-title fs-4 fw-semibold text-black">Campaigns</span>} icon={<i className="fa-regular fa-folder-tree text-black fs-1"></i>}>
        <MenuItem
          active={window.location.pathname === "/emailcampaigns"}
          component={<Link className="me-0" to="/emailcampaigns" />}
        >
          {" "}
          <i id="emailcampaignstooltip" className="fa-regular fa-envelope text-black fs-1"></i>
          <span className="menu-title fs-4 fw-semibold text-black">
            {" "}
            Email
            <Tooltip
              placement="top"
              isOpen={emailCampaignsTooltipOpen}
              autohide={false}
          innerClassName="rounded-1"
              target="emailcampaignstooltip"
              toggle={toggleEmailCampaignsTooltip}
            >
              Email Campaigns
            </Tooltip>
          </span>
        </MenuItem>
        <MenuItem
          active={window.location.pathname === "/smscampaigns"}
          component={<Link to="/smscampaigns" />}
        >
          {" "}
          <i id="smscampaignstooltip" className="fa-regular fa-sms text-black fs-1"></i>
          <span className="menu-title fs-4 fw-semibold text-black">
            {" "}
            SMS
            <Tooltip
              placement="top"
              isOpen={smsCampaignsTooltipOpen}
              autohide={false}
          innerClassName="rounded-1"
              target="smscampaignstooltip"
              toggle={toggleSmsCampaignsTooltip}
            >
              SMS Campaigns
            </Tooltip>
          </span>
        </MenuItem>
        <MenuItem
        active={window.location.pathname === "/whatsappcampaigns"}
        component={<Link to="/whatsappcampaigns" />}
      >
        {" "}
        <i id="whatsappcampaignstooltip" className="fa-brands fa-whatsapp fw-bold text-black fs-1"></i>
        <span className="menu-title fs-4 fw-semibold text-black">
          {" "}
          WhatsApp 
          <Tooltip
            placement="top"
            isOpen={whatsappCampaignsTooltipOpen}
            autohide={false}
          innerClassName="rounded-1"
            target="whatsappcampaignstooltip"
            toggle={toggleWhatsappCampaignsTooltip}
          >
            WhatsApp Campaigns
          </Tooltip>
        </span>
        </MenuItem>
      </SubMenu>
      <MenuItem
        active={window.location.pathname === "/integrations"}
        component={<Link to="/integrations" />}
      >
        {" "}
        <i id="integrations" className="fa-regular fa-plug text-black fs-1"></i>
        <span className="menu-title fs-4 fw-semibold text-black">
          {" "}
          Integrations
          <Tooltip
            placement="top"
            isOpen={customlinksTooltipOpen}
            autohide={false}
          innerClassName="rounded-1"
            target="integrations"
            toggle={togglecustomlinksTooltip}
          >
            Integrations
          </Tooltip>
        </span>
      </MenuItem>

      <div className="border-bottom border-gray-250 separator-menu"></div>

      <div className="text-start ps-4 text-light-brand fs-5 fw-semibold text-uppercase mt-8 mb-5 menu-title">
        Other
      </div>
      <MenuItem
        active={window.location.pathname === "/notifications"}
        component={<Link to="/notifications" />}
      >
        {" "}
        <i id="notificationstooltip" className="fa-regular fa-bell text-black fs-1"></i>
        <span className="menu-title fs-4 fw-semibold text-black">
          {" "}
          Notifications
          <Tooltip
            placement="top"
            isOpen={notificationsTooltipOpen}
            autohide={false}
          innerClassName="rounded-1"
            target="notificationstooltip"
            toggle={togglenotificationsTooltip}
          >
            Notifications
          </Tooltip>
        </span>
      </MenuItem>
      { !(profile.userType === USER_TYPE.USER) &&
      <MenuItem
        active={window.location.pathname === "/subscription"}
        component={<Link to="/subscription" />}
      >
        {" "}
        <i class="fa-regular fa-credit-card text-black fs-1"></i>
        <span className="menu-title fs-4 fw-semibold text-black">
          {" "}
          Subscription
        </span>
      </MenuItem>}
      {!(profile.userType === USER_TYPE.USER) && <MenuItem
        active={window.location.pathname === "/user-settings"}
        component={
          <Link
            to={profile.userType === USER_TYPE.USER ? "/dashboard" : "/user-settings"}
          />
        }
      >
        {" "}
        <i id="settingstooltip" className="fa-regular fa-gear text-black fs-1"></i>
        <span className="menu-title fs-4 fw-semibold text-black">
          {" "}
          Settings
          <Tooltip
            placement="top"
            isOpen={settingsTooltipOpen}
            autohide={false}
          innerClassName="rounded-1"
            target="settingstooltip"
            toggle={togglesettingsTooltip}
          >
            Settings
          </Tooltip>
        </span>
      </MenuItem>}
    </>
  );
};

export default UserSidebar;
