// toastService.js
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const showErrorToast = (message, autoClose = 3000) => {
  toast.error(message, {
    position: "bottom-left",
    autoClose,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

const showSuccessToast = (message, autoClose = 3000) => {
  toast.success(message, {
    position: "bottom-left",
    autoClose,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

export { showErrorToast, showSuccessToast };
