import React, { useState, useEffect, useContext } from "react";
import Sidebar from "../../components/common/sidebar";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Card, Spinner } from "reactstrap";
import { Link } from "react-router-dom";
import Select from "react-select/creatable";
import SmsCampaignAPI from "../../services/http/smsCampaign";
import TagsApi from "../../services/http/tags";
import ShortlinksDropdown from "../email-campaigns/ShortlinksDropDown";
import MergeTagsDropdown from "../email-campaigns/MergeTagsDropdown";

let mergeTags = {
  first_name: {
    name: "First Name",
    value: "{{first_name}}",
    sample: "{{first_name}}",
  },
  last_name: {
    name: "Last Name",
    value: "{{last_name}}",
    sample: "{{last_name}}",
  },
  email: {
    name: "Email",
    value: "{{email}}",
    sample: "{{email}}",
  },
  sms: {
    name: "SMS",
    value: "{{sms}}",
    sample: "{{sms}}",
  },
  date: {
    name: "Date",
    value: "{{date}}",
    sample: "{{date}}",
  },
};

const CreateSmsCampaigns = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id: smsCampaignId } = useParams();
  console.log("id", smsCampaignId, useParams());
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState({
    name: "",
    content: "",
    recipients: "",
  });
  const [userTags, setUserTags] = useState([]);
  const [formState, setFormState] = useState({
    name: "",
    smsSender: "",
    compliance: false,
    organizationPrefix: "",
    unsubscribeInstructions: "",
    content: "",
    recipients: [],
  });

  useEffect(() => {
    const getUserTags = async () => {
      try {
        const response = await TagsApi.getTagsUser();

        if (response.status === 200) {
          const tags = response?.data || [];
          const tagOptions = tags.map((tag) => ({
            value: tag.name,
            label: tag.name,
            count: tag.count + "" || 0 + "",
          }));
          console.log("tagOptions", tagOptions);
          setUserTags(tagOptions);
        }
      } catch (error) {
        console.log(error);
      }
    };

    getUserTags();
  }, []);

  useEffect(() => {
    if (smsCampaignId) {
      const fetchCampaignDetails = async () => {
        try {
          setLoading(true);
          console.log("smsCampaignId", smsCampaignId);
          const auth_token = localStorage.getItem("auth_token");
          const response = await SmsCampaignAPI.getSmsCampaignsById(
            auth_token,
            smsCampaignId
          );
          console.log("response", response);
          const campaign = response.data.resultData;
          campaign.recipients = JSON.parse(campaign.recipients || "[]");
          setFormState({
            name: campaign.name,
            smsSender: campaign.smsSender,
            compliance: campaign.compliance,
            organizationPrefix: campaign.organizationPrefix,
            unsubscribeInstructions: campaign.unsubscribeInstructions,
            content: campaign.content,
            recipients: campaign.recipients.map((recipient) => ({
              value: recipient,
              label: recipient,
              count: campaign.contactCount + "",
            })),
          });
        } catch (error) {
          console.log("Error fetching campaign details", error);
        } finally {
          setLoading(false);
        }
      };

      fetchCampaignDetails();
    }
  }, [smsCampaignId]);

  const formatOptionLabel = ({ label, count }) => (
    <div>
      {console.log("count", count)}
      <div>{label}</div>
      {count != "0" && (
        <div
          style={{ fontSize: "12px", color: "#888" }}
        >{`${count} contacts`}</div>
      )}
    </div>
  );

  const handleRecipientChange = (selected) => {
    setFormState((prevState) => ({
      ...prevState,
      recipients: selected,
    }));
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
    console.log("formState", formState);
  };

  const [cursorPosition, setCursorPosition] = useState(0);

  const handleCursorPosition = (e) => {
    setCursorPosition(e.target.selectionStart);
  };

  const MAX_CHARACTERS = 169;

  const handleSmsMessageChange = (e) => {
    const { value } = e.target;
    if (value.length <= MAX_CHARACTERS) {
      setFormState((prevState) => ({
        ...prevState,
        content: value,
      }));
    }
  };

  const insertShortlink = (link) => {
    let shortLink = link.slashTag
      ? `https://${link.brandedDomain}/${link.slashTag}`
      : `https://${link.pageDomain}/${link.pageLink}`;

    setFormState((prevState) => {
      const content = prevState.content;
      const newSmsMessage =
        content.slice(0, cursorPosition) +
        shortLink +
        content.slice(cursorPosition);

      if (newSmsMessage.length <= MAX_CHARACTERS) {
        return {
          ...prevState,
          content: newSmsMessage,
        };
      } else {
        return prevState;
      }
    });
  };

  const handleMergeTagSelect = (mergeTag) => {
    setFormState((prevState) => {
      const content = prevState.content;
      const newSmsMessage =
        content.slice(0, cursorPosition) +
        mergeTag.value +
        content.slice(cursorPosition);

      if (newSmsMessage.length <= MAX_CHARACTERS) {
        return {
          ...prevState,
          content: newSmsMessage,
        };
      } else {
        return prevState;
      }
    });
  };

  const createSmsCampaign = async (e) => {
    e.preventDefault();
    let hasError = false;
    const newError = { name: "", content: "", recipients: "" };

    if (!formState.name) {
      newError.name = "Campaign name is required.";
      hasError = true;
    }

    if (!formState.content) {
      newError.content = "SMS content is required.";
      hasError = true;
    }

    if (formState.recipients.length === 0) {
      newError.recipients = "At least one recipient is required.";
      hasError = true;
    }

    if (hasError) {
      setError(newError);
      return;
    }

    try {
      setLoading(true);
      const auth_token = localStorage.getItem("auth_token");
      const payload = {
        ...formState,
        recipients: JSON.stringify(
          formState.recipients.map((recipient) => recipient.value)
        ),
      };

      if (smsCampaignId) {
        // Update existing campaign
        await SmsCampaignAPI.updateSmsCampaign(
          auth_token,
          smsCampaignId,
          payload
        );
      } else {
        // Create new campaign
        await SmsCampaignAPI.createSmsCampaign(auth_token, payload);
      }

      setLoading(false);
      setSuccess(true);
      navigate(`/smscampaigns`);
    } catch (error) {
      console.log("Error while creating/updating sms campaign", error);
      setLoading(false);
      setError((prevError) => ({
        ...prevError,
        general:
          error.response?.data?.error || "An error occurred. Please try again.",
      }));
    }
  };

  return (
    <div>
      <div className="w-full d-flex">
        <div className="mt-md-10 w-[280px] me-md-7 mt-xs-0 me-xs-0">
          <Sidebar />
        </div>
        <div className="customDashboardSize">
          <div className="d-flex flex-column w-100 flex-root app-root">
            <div className="app-page flex-column flex-column-fluid">
              <div className="app-wrapper flex-column flex-row-fluid pe-3 pe-lg-0 me-lg-10 mt-lg-10 mt-0 position-relative">
                <div className="app-main flex-column flex-row-fluid">
                  <Card className="card card-flush overflow-auto">
                    <div className="card-body mx-auto w-75">
                      <div
                        className="py-20 pt-8 rounded-1 w-100"
                        style={{ borderColor: "#f3f0fb !important" }}
                      >
                        <h2 className="me-15 fs-2x">
                          {smsCampaignId
                            ? "Update SMS Campaign"
                            : "Setup SMS Campaign"}
                        </h2>
                        <p>
                          {smsCampaignId
                            ? "Update your SMS campaign to reach your customers directly and boost engagement."
                            : "Set up your SMS campaign to reach your customers directly and boost engagement. Follow our simple steps to create and launch an effective SMS campaign today."}
                        </p>
                        <div
                          className="separator border-bottom my-10"
                          style={{ borderColor: "#f3f0fb !important" }}
                        ></div>
                        <div className="mt-15">
                          <label className="fw-bold fs-5 mb-2">
                            Campaign Name
                          </label>
                          <input
                            type="text"
                            id="campaign-name"
                            name="name"
                            className="form-control rounded-1 h-50px mb-2"
                            placeholder="Example: Summer Sale Campaign"
                            value={formState.name}
                            onChange={handleChange}
                          />
                          {error.name && (
                            <div>
                              <span className="text-danger">{error.name}</span>
                            </div>
                          )}
                          <span className="text-gray-800">
                            Give your campaign an internal name to help organize
                            and locate it easily within your account. For
                            example: 'Sale_November'
                          </span>
                        </div>
                        <div className="mt-15">
                          <label className="fw-bold fs-5 mb-2">
                            SMS Sender
                          </label>
                          <input
                            type="text"
                            id="sms-sender"
                            name="smsSender"
                            className="form-control rounded-1 h-50px mb-2"
                            placeholder="Example: MyCompany"
                            value={formState.smsSender}
                            onChange={handleChange}
                          />
                          <span className="text-gray-800">
                            Use this field to customize the SMS sender with up
                            to 11 characters or 15 digits numeric. Recipients in
                            some countries (including the U.S.) cannot see
                            customizations - instead the sender will appear as a
                            numerical short code.
                          </span>
                        </div>
                        <div className="mt-15"></div>
                        <div className="form-check form-switch form-check-custom form-check-n-dark form-check-info form-check-solid mb-2">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="manage-compliance"
                            name="compliance"
                            checked={formState.compliance}
                            onChange={handleChange}
                            style={{ height: "19px", width: "35px" }}
                          />
                          <label
                            className="form-check-label fs-5 fw-bold text-black"
                            htmlFor="manage-compliance"
                          >
                            Manage compliance for the United States
                          </label>
                        </div>
                        <span className="text-gray-800">
                          If you are sending this SMS campaign to the United
                          States recipients without following this compliance
                          then your campaign will not be sent and will get
                          suspended.
                        </span>
                      </div>
                      <div className="mt-15">
                        <label className="fw-bold fs-5 mb-2">
                          Organization prefix
                        </label>
                        <input
                          type="text"
                          id="organization-prefix"
                          name="organizationPrefix"
                          className="form-control rounded-1 h-50px mb-2"
                          placeholder="Example: MyCompany"
                          value={formState.organizationPrefix}
                          onChange={handleChange}
                        />
                        <span className="text-gray-800">
                          A recognizable prefix will ensure your audience knows
                          who you are. Recommended by the U.S. carriers.
                        </span>
                      </div>
                      <div className="mt-15">
                        <label className="fw-bold fs-5 mb-2">
                          Unsubscribe instructions
                        </label>
                        <input
                          type="text"
                          id="unsubscribe-instructions"
                          name="unsubscribeInstructions"
                          className="form-control rounded-1 h-50px mb-2"
                          placeholder="Example: Text STOP to unsubscribe"
                          value={formState.unsubscribeInstructions}
                          onChange={handleChange}
                        />
                        <span className="text-gray-800">
                          Opt-out language is required in certain jurisdictions
                          and we strongly recommend including it in your
                          messages. Your message must include STOP keyword.
                        </span>
                      </div>
                      <div className="mt-15">
                        <div className="d-flex justify-content-between w-100 mb-5 align-items-center">
                          <p className="fw-bold fs-5 mb-0">SMS Message</p>

                          <div className="d-flex">
                            <MergeTagsDropdown
                              mergeTags={mergeTags}
                              onSelect={handleMergeTagSelect}
                            />
                            <ShortlinksDropdown onSelect={insertShortlink} />
                          </div>
                        </div>
                        <textarea
                          type="text"
                          className="form-control rounded-1 mb-2"
                          rows="7"
                          cols="20"
                          placeholder="Type your SMS message here..."
                          name="content"
                          value={formState.content}
                          onChange={handleSmsMessageChange}
                          onClick={handleCursorPosition}
                          onKeyUp={handleCursorPosition}
                        ></textarea>
                        {error.content && (
                          <div>
                            <span className="text-danger">{error.content}</span>
                          </div>
                        )}
                        <div className="d-flex justify-content-between">
                          <div>
                            <span className="text-gray-800">
                              <span className="me-2 fw-bold text-dark">
                                {MAX_CHARACTERS - formState.content.length}
                              </span>
                              characters remaining
                            </span>
                          </div>
                          <div>
                            <span className="text-gray-800">
                              <span className="me-2 fw-bold text-dark">
                                {formState.content.length}
                              </span>
                              characters
                            </span>
                            <span className="text-dark fw-bold">
                              <span className="me-2 fw-bold"> | 0</span>SMS
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="mt-15">
                        <p className="fw-bold fs-5 mb-2">Send a Test SMS</p>
                        <p className="text-gray-800">
                          Verify that you are sending the test SMS to an
                          existing contact in your database with a mobile phone
                          number saved in the 'SMS' field and who is not
                          blacklisted for SMS campaigns.
                        </p>
                        <p className="text-gray-800">
                          The cost of sending your test SMS will be deducted
                          from your SMS credits.
                        </p>
                      </div>

                      <div className="separator my-10"></div>

                      <div className="border p-5 rounded-1 bg-light-info mb-15">
                        <span className="fw-bold fs-3 mb-2 d-block">
                          Choose your contact lists
                        </span>
                        <Select
                          id="multiSelect"
                          options={userTags} // Use userTags for the options
                          isMulti
                          value={formState.recipients}
                          onChange={handleRecipientChange}
                          placeholder="Select or add options..."
                          formatOptionLabel={formatOptionLabel}
                          onCreateOption={(inputValue) => {
                            const newOption = {
                              value: inputValue.toLowerCase(),
                              label: inputValue,
                              count: "0",
                            };
                            const newOptions = [
                              ...formState.recipients,
                              newOption,
                            ];
                            setFormState((prevState) => ({
                              ...prevState,
                              recipients: newOptions,
                            }));
                          }}
                        />
                        {error.recipients && (
                          <div>
                            <span className="text-danger">
                              {error.recipients}
                            </span>
                          </div>
                        )}
                        <p className="fs-5 text-gray-800 mt-3">
                          <i className="fa-duotone fa-user-vneck opacity-50 me-2"></i>
                          <span className="fw-bold text-black">16108</span>{" "}
                          Recipents
                        </p>
                      </div>

                      <div className="d-flex gap-5 mt-10 justify-content-end">
                        {success && (
                          <p className="text-success mt-3">
                            Campaign {smsCampaignId ? "updated" : "created"}{" "}
                            successfully!
                          </p>
                        )}
                        <Link to={`/smscampaigns`}>
                          <button className="btn btn-light border border-2 rounded-1 border hover-elevate-up">
                            Cancel
                          </button>
                        </Link>
                        <button
                          className="btn btn-info rounded-1"
                          style={{ width: "160px" }}
                          onClick={(e) => createSmsCampaign(e)}
                          disabled={loading}
                        >
                          {loading ? (
                            <Spinner animation="border" size="sm" />
                          ) : smsCampaignId ? (
                            "Update"
                          ) : (
                            "Create"
                          )}
                        </button>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateSmsCampaigns;
