import { useState } from "react";
import ShorlinkEmptyState from "../../../assets/media/empty-state-link-list.png";
import useWindowSize from "../../../hooks/useWindowSize";
import { Tooltip } from "reactstrap";

const NotFound = ({ handler, type, isLimitReached = false }) => {
  const { width } = useWindowSize();

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const tooltipToggle = () => {
    if (isLimitReached) {
      setTooltipOpen(!tooltipOpen);
    }
  };

  const typesMapper = {
    qr: {
      h2: "Currently, there are no QR to display.",
      p: "Create QR and manages all your QR codes enabling you to brand, track and share short URLs.",
      btn: "Add First QR",
    },
    shortlink: {
      h2: "Currently, there are no links to display.",
      p: "Create short link and manages all your links enabling you to brand, track and share short URLs.",
      btn: "Add First Link",
    },
    customLink: {
      h2: "Currently, there are no custom links to display.",
      p: "Create custom links and manages all your custom links enabling you to brand, track and share short URLs.",
      btn: "Create Custom Link",
    },
    linkInBio: {
      h2: "Currently, there are no pages to display.",
      p: "Create Link in Bio page and manages all your pages enabling you to brand, track and share short URLs.",
      btn: "Create Link in Bio",
    },
    users: {
      h2: "Currently, there is no user to display.",
      p: "Create user and manages all your users.",
      btn: "Create User",
    },
    emailCampaign: {
      h2: "Currently, there are no email campaigns to display.",
      p: "Create email campaigns and manages all your email campaigns.",
      btn: "Create Email Campaign",
    },
    smsCampaign: {
      h2: "Currently, there are no sms campaigns to display.",
      p: "Create sms campaigns and manages all your sms campaigns.",
      btn: "Create Sms Campaign",
    },
    whatsappCampaign: {
      h2: "Currently, there are no whatsapp campaigns to display.",
      p: "Create whatsapp campaigns and manages all your whatsapp campaigns.",
      btn: "Create Whatsapp Campaign",
    },
    myTemplates: {
      h2: "No templates found in the Gallery",
      p: "",
      btn: "Create Email Campaign",
    },
  };

  const { h2, p, btn } = typesMapper[type];

  return (
    <>
      <div className="mobile-hide mt-15">
        <img
          className="opacity-50 img-fluid d-block mx-auto"
          src={ShorlinkEmptyState}
          alt={h2}
        />
        <div className="text-center mt-lg-10">
          <h2 className="fs-2x w-75 pt-5 mx-auto fw-bold">{h2}</h2>
          <p className="w-50 mx-auto fw-semibold fs-5 text-gray-500 mb-8">
            {p}
          </p>
          <div
            onMouseOver={tooltipToggle}
            onMouseLeave={tooltipToggle}
            id="newItemAdd"
          >
            <button
              disabled={isLimitReached}
              type="button"
              className="btn btn-info w-300px fs-2 rounded-1"
              onClick={handler}
            >
              {btn}
            </button>
          </div>
        </div>
      </div>

      <div
        className="desktop-hide-f flex-column align-items-center justify-content-center autowidthmobile mt-10 position-absolute start-0 end-0"
        style={{ width: `calc(${width}px - 40px)` }}
      >
        <img
          className="opacity-50 w-50 img-fluid d-block"
          src={ShorlinkEmptyState}
          alt={h2}
        />
        <div className="text-start mt-lg-10">
          <h2 className="fs-lg-2x fs-1 pt-lg-5 p-3 pb-3 text-center fw-bold">
            {h2}
          </h2>
          <p className="fw-semibold fs-lg-5 fs-6 text-center text-gray-500 mb-8">
            {p}
          </p>
          <div className="d-flex w-100 justify-content-center">
            <div
              onMouseOver={tooltipToggle}
              onMouseLeave={tooltipToggle}
              id="newItemAdd"
            >
              <button
                disabled={isLimitReached}
                type="button"
                className="btn btn-info rounded-1"
                onClick={handler}
              >
                {btn}
              </button>
            </div>

            <Tooltip
              disabled={isLimitReached}
              placement={"top"}
              isOpen={tooltipOpen}
              target={"newItemAdd"}
              toggle={tooltipToggle}
            >
              {`Your Monthly Limit Reached. Please Upgrade your Plan`}
            </Tooltip>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotFound;
