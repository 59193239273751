import React, { useContext } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import TermsBackground from "../../assets/auth/background.jpg";
import TermsImage from "../../assets/auth/acceptTerms.svg";
import TimelineComponent from './proileCompletion';
import { AuthContxt } from '../../store/authContxt'; // Use AuthContxt to access profile data
import SignupPageWrapper from "../../components/wrapper/signupPageWrapper";


const CompleteProfile = () => {
    const { profile } = useContext(AuthContxt); // Access profile from context
    
    // Debugging to check if profile data is available
    console.log('Profile data in CompleteProfile:', profile);

    return (
        <SignupPageWrapper>
                    <div className='mx-auto w-75 h-100'>
                        <TimelineComponent /> 
                    </div>
       </SignupPageWrapper>
    );
};

// Remove direct withOnboarding wrapper here since it's already handled in AuthProviderWithOnboarding
export default CompleteProfile;
