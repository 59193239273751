import React, { useContext, useEffect, useState } from "react";
import LinkInBioApi from "../../services/http/linkInBio";
import { Modal, ModalBody, Spinner, Tooltip } from "reactstrap";
import LinkInBioCard from "./link-in-bio-card";
import { useLocation, useNavigate } from "react-router-dom";
import NotFound from "../common/emptyScreens/notFound";
import DeleteModal from "../../modals/deleteModal";
import { AuthContxt } from "../../store/authContxt";
import OnBoarding from '../../pages/onboarding/OnBoarding';
import useOnboarding from "../../hooks/useOnboarding";


const Index = () => {
  const { fetchQuota } = useContext(AuthContxt);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [linkInBioPages, setLinkInBioPages] = useState([]);
  const [deletePage, setDeletePage] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const { isModalOpen, handleOnboardingComplete, toggleModal } = useOnboarding();

  const { quota } = useContext(AuthContxt);
  const isLimitReached = quota?.linkInBioPages === 0;
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => {
    if (isLimitReached) {
      setTooltipOpen(!tooltipOpen);
    }
  };

  const deletePageHandler = async () => {
    setDeleteModal(false);
    try {
      const token = localStorage.getItem("auth_token");
      const response = await LinkInBioApi.deleteLinkInBioPage(token, deletePage);
      if (response?.data?.success) {
        setLinkInBioPages((preState) => {
          return preState?.filter((item) => item?.id !== deletePage);
        });
        await fetchQuota();
        setDeletePage(null);
      }
    } catch (e) {
      console.log("Error while deleting link in bio page: ", e);
      setDeletePage(null);
    }
  };

  const deleteHandler = (id) => {
    if (!deletePage) {
      setDeleteModal(true);
      setDeletePage(id);
    }
  };

  useEffect(() => {
    const fetchLinkInBioPages = async () => {
      setLoading(true);
      try {
        const auth_token = localStorage.getItem("auth_token");
        const response = await LinkInBioApi.getLinkInBioPages(auth_token);
        setLinkInBioPages(response.data?.data || []);
        setLoading(false);
      } catch (error) {
        console.log("Error while fetching pages", error);
        setLoading(false);
      }
    };

    fetchLinkInBioPages().catch(error => error);
  }, []);

  const notLinkInBioPagesrScreenMapper = {
    notFound: (
      <NotFound
        handler={() => {
          navigate("/create-link-in-bio");
        }}
        type={"linkInBio"}
      />
    ),
  };

  return (
    <>
      <div className="onboarding-styles">
        <div className="row">
          <div className="col-6 d-flex justify-content-start align-items-center">
            <h2 className="fw-bold mb-0 fs-2x"> Link-in-bios </h2>
          </div>
          <div className="col-6 d-flex justify-content-end">
            <div onMouseOver={toggle} onMouseLeave={toggle} id="plusIcon">
              <button
                disabled={isLimitReached}
                className="btn btn-info rounded-1 mobile-hide"
                onClick={() => {
                  !isLimitReached && navigate("/create-link-in-bio");
                }}
              >
                Add New Link in Bio
              </button>
            </div>
            <Tooltip
              placement={"top"}
              isOpen={tooltipOpen}
              target={"plusIcon"}
              toggle={toggle}
              className="mobile-hide"
            >
              {`Your Bio Page Limit Reached. Please Upgrade your Plan`}
            </Tooltip>

            <div onMouseOver={toggle} onMouseLeave={toggle} id="plusIcon">
              <button
                disabled={isLimitReached}
                className="btn btn-icon btn-info align-items-center rounded-circle position-fixed mobile-add-link-btn desktop-hide-f"
                style={{ bottom: "20px" }}
                onClick={() => {
                  !isLimitReached && navigate("/create-link-in-bio");
                }}
              >
                <i className="fa-solid fa-plus fs-2"></i>
              </button>
            </div>
            <Tooltip
              placement={"top"}
              isOpen={tooltipOpen}
              target={"plusIcon"}
              toggle={toggle}
              className="desktop-hide-f"
            >
              {`Your Bio Page Limit Reached. Please Upgrade your Plan`}
            </Tooltip>
          </div>
        </div>

        {loading ? (
          <div
            style={{
              height: "70vh",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spinner />
          </div>
        ) : (
          <>
            {/* Render linkInBioPages if count is > 0, otherwise show NotFound */}
            <div className="row g-lg-10 g-6 pt-lg-10 pb-lg-10 mb-lg-0 mb-14 py-6">
              {linkInBioPages.length > 0 ? (
                linkInBioPages.map((linkInBio) => (
                  <LinkInBioCard
                    key={linkInBio?.id}
                    linkInBio={linkInBio}
                    linkInBioPagesHandler={setLinkInBioPages}
                    deleteHandler={deleteHandler}
                    setDeleteModal={setDeleteModal}
                  />
                ))
              ) : (
                <tr
                  style={{
                    display: "flex",
                  }}
                >
                  <td className="border-0 position-relative w-100" colSpan={"8"}>
                    {notLinkInBioPagesrScreenMapper["notFound"]}
                  </td>
                </tr>
              )}
            </div>
          </>
        )}

        {deleteModal ? (
          <DeleteModal
            isOpen={true}
            modalHandler={() => setDeleteModal(false)}
            leftBtnHandler={deletePageHandler}
            rightBtnHandler={() => setDeleteModal(false)}
            data={{
              heading: "Delete Page",
              description: "Are you sure you want to delete this Page ?",
              leftBtn: "Yes, delete it",
              rightBtn: "Cancel",
            }}
          />
        ) : null}

        <div className="onboarding-styles">
          <Modal isOpen={isModalOpen} toggle={toggleModal} className="custom-modal">
            <ModalBody>
              <OnBoarding onComplete={handleOnboardingComplete} />
            </ModalBody>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default Index;
