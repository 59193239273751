import React, { useContext, useState, useCallback } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  ProgressBar,
  Spinner,
} from "react-bootstrap";
import divslyIcon from "../../assets/media/divsly-icon-w.svg";
import { AuthContxt } from "../../store/authContxt";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { Controller, useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ONBOARDING_STATUS } from "../../utils/enum";

// Validation schemas for each step
const step1Validation = yup.object().shape({
  jobTitle: yup.string().required("Please fill this field!"),
  companySize: yup.string().required("Please fill this field!"),
});

const step2Validation = yup.object().shape({
  industry: yup.string().required("Please fill this field!"),
});

const TimelineComponent = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const { profile, updateProfile } = useContext(AuthContxt);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  // Initialize form with step 1 validation
  const step1Methods = useForm({
    resolver: yupResolver(step1Validation),
    defaultValues: {
      jobTitle: profile?.jobTitle || "",
      companySize: profile?.companySize || "",
    },
  });

  // Initialize form with step 2 validation
  const step2Methods = useForm({
    resolver: yupResolver(step2Validation),
    defaultValues: {
      industry: profile?.industry || "",
    },
  });

  const {
    trigger: triggerStep1,
    formState: { errors: errorsStep1 },
    control: controlStep1,
    getValues: getValuesStep1,
  } = step1Methods;

  const {
    trigger: triggerStep2,
    formState: { errors: errorsStep2 },
    control: controlStep2,
    getValues: getValuesStep2,
  } = step2Methods;

  const handleSkip = useCallback(() => {
    sessionStorage.setItem('skipProfileCompletion', 'true');
    if (currentStep === 1) {
      setCurrentStep(2);
    } else {
      updateProfile({ ...profile, onboardingStatus: ONBOARDING_STATUS.COMPLETED })
          .then(() => navigate("/dashboard"))
          .catch(error => console.error("Error updating profile status:", error));
    }
  }, [currentStep, updateProfile, profile, navigate]);

  const getSafeValue = (value) => (value == null ? "" : value); // Returns empty string if value is null or undefined

  const submitHandler = async (formData) => {
    try {
      setLoading(true);
      const updatedPayload = {
        ...profile,
        ...formData,
        lastName: getSafeValue(profile.lastName), // Ensures lastName is an empty string if null
        onboardingStatus: ONBOARDING_STATUS.COMPLETED,
      };
      await updateProfile(updatedPayload);
      navigate("/dashboard");
    } catch (e) {
      console.error("Error while updating profile: ", e);
    } finally {
      setLoading(false);
    }
  };

  const handleNextStep = async () => {
    if (currentStep === 1) {
      const isValid = await triggerStep1();
      if (!isValid) return;
      setCurrentStep(2);
    } else if (currentStep === 2) {
      const isValid = await triggerStep2();
      if (!isValid) return;
      await submitHandler({
        ...getValuesStep1(),
        ...getValuesStep2(),
      });
    }
  };

  return (
      <Container className="h-100">
        <ProgressBar className="w-100 rounded-1" style={{backgroundColor: "#eeeeee",height: "10px"}}>
          <ProgressBar style={{backgroundColor: "#d8c9f7", borderRight: "2px solid #fff"}} now={currentStep >= 1 ? 50 : 0} key={1} />
          <ProgressBar style={{backgroundColor: "#d8c9f7", borderLeft: "2px solid #fff"}} now={currentStep === 2 ? 50 : 0} key={2} />
        </ProgressBar>

        <Row className="justify-content-center h-100 w-100">
          <Col md={12} className={`timeline-step ${currentStep === 1 ? "active" : "completed"}`}>
            <h2 className="mb-5 mt-5 fs-1 mb-10 text-black pt-5 d-flex flex-column gap-5 fw-bold pb-5">
              <span className="fs-2x fw-bolder">Hi <span className=" text-capitalize text-info">{profile.firstName}</span>,</span>
              <span className="fw-bolder fs-2hx">Welcome to Divsly!</span>
            </h2>
            {currentStep === 1 && (
                <FormProvider {...step1Methods}>
                  <Form onSubmit={(e) => { e.preventDefault(); handleNextStep(); }}>
                    <Form.Group controlId="formJobTitle" className="mb-10">
                      <Form.Label className="text-black fw-bold mb-7 fs-3">What is your Job Title?</Form.Label>
                      <Controller
                          name="jobTitle"
                          control={controlStep1}
                          render={({ field }) => (
                              <Form.Control
                                  type="text"
                                  className="form-control bg-white rounded-1 h-55px border border-gray-300 form-control-lg form-control-solid mb-2 pe-15 rounded-55rem"
                                  placeholder="Enter Your Job Title"
                                  {...field}
                              />
                          )}
                      />
                      {errorsStep1.jobTitle && <div className="text-danger">{errorsStep1.jobTitle.message}</div>}
                    </Form.Group>
                    <Form.Group controlId="formCompanySize" className="mb-10">
  <Form.Label className="text-black fw-bold mb-7 fs-3">How many employees do you have?</Form.Label>
  <div className="d-inline-block align-items-center">
    {["1-9", "10-49", "50-199", "200-1K", "1K-5K", "5K+"].map((range) => (
      <Controller
        key={range}
        name="companySize"
        control={controlStep1}
        render={({ field }) => (
          <div
            className={`border rounded-1 p-3 px-4 d-inline-block cursor-pointer border border-dark border-opacity-25 text-dark fs-3 me-4 mb-4 ${field.value === range ? "radio-active" : ""}`}
          >
            <Form.Check
              {...field}
              type="radio"
              className="form-check d-flex mb-0 align-items-center gap-3"
              label={range}
              value={range}
              id={range}
              checked={field.value === range}
              onChange={field.onChange}
            />
          </div>
        )}
      />
    ))}
  </div>
  {errorsStep1.companySize && <div className="text-danger">{errorsStep1.companySize.message}</div>}
</Form.Group>

                    <Button
                        variant="primary"
                        type="submit"
                        className="d-flex btn btn-info fs-2 h-65px w-100 rounded-1 align-items-center justify-content-between"
                        style={{maxWidth: "425px"}}
                        disabled={loading}
                    >
                      {loading ? <Spinner animation="border" size="sm" /> : (
                          <>
                            Continue Process
                            <img src={divslyIcon} alt="signup"/>
                          </>
                      )}
                    </Button>
                    <Button
                        variant="link"
                        onClick={handleSkip}
                        className="text-gray-500 fst-italic"
                        disabled={loading}
                    >
                      Skip for now
                    </Button>
                  </Form>
                </FormProvider>
            )}
          </Col>

          <Col md={12} className={`timeline-step ${currentStep === 2 ? "active" : ""} h-100`}>
            {currentStep === 2 && (
                <Form className="d-flex justify-content-between flex-column" style={{height: "100%", maxHeight: "58%"}} onSubmit={(e) => { e.preventDefault(); handleNextStep(); }}>
                  <Form.Group controlId="formIndustry">
                    <Form.Label className="text-black fw-bold mb-1 fs-3">
                      What does your company specialize in?
                    </Form.Label>
                    <span className="fs-5 d-block text-gray-400 mb-8">
                  This will help us better understand your needs
                </span>
                    <Controller
                        name="industry"
                        control={controlStep2}
                        render={({ field }) => (
                            <Form.Control
                                as="select"
                                className="p-5 h-60px mb-5 form-select rounded-1"
                                {...field}
                            >
                              <option value="">Choose an industry...</option>
                              <option value="Agri and Allied Industries">Agri and Allied Industries</option>
                              <option value="Auto Components">Auto Components</option>
                              <option value="IT">IT</option>
                              <option value="Software">Software</option>
                              <option value="Banking">Banking</option>
                              <option value="Consumer Durables">Consumer Durables</option>
                            </Form.Control>
                        )}
                    />
                    {errorsStep2.industry && <div className="text-danger">{errorsStep2.industry.message}</div>}
                  </Form.Group>

                  <div>
                  <Button
                      variant="primary"
                      type="submit"
                      className="d-flex btn btn-info fs-2 h-65px w-100 mt-10 rounded-1 align-items-center justify-content-between"
                        style={{maxWidth: "425px"}}
                        disabled={loading}
                  >
                    {loading ? <Spinner animation="border" size="sm" /> : (
                        <>
                          Complete Profile
                          <img src={divslyIcon} alt="signup"/>
                    </>
                    )}
                  </Button>
                  <Button
                        variant="link"
                        onClick={handleSkip}
                        className="mb-5 text-gray-500 fst-italic"
                        disabled={loading}
                    >
                      Skip for now
                    </Button>
                  </div>
                </Form>
            )}
          </Col>
        </Row>
      </Container>
  );
};

export default TimelineComponent;