import React, { useState } from "react";
import Cropper from "react-easy-crop";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Button,
  Input,
  FormGroup,
  Label,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import designicon from '../../../../assets/qr-icons/design.png';
import infoicon from '../../../../assets/qr-icons/info-contact.png';
import socialicon from '../../../../assets/qr-icons/social-media.png';
import linkicon from "../../../../assets/qr-icons/link.png";
import QrLink from "../QrLink";
import getCroppedImg from './cropImage'; // Import helper function for cropping
import './Crop.css'
import defaultimg from "../../../../assets/qr-logos/default.png"

const Vcard = ({ formData, setFormData, isEdit, qrType }) => {
  const [open, setOpen] = useState("0");
  const [imageSrc, setImageSrc] = useState(null); // Original image source
  const [cropping, setCropping] = useState(false); // Toggle cropping modal
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  
  const toggle = (id) => {
    open === id ? setOpen("") : setOpen(id);
  };

  // Handle image change to open cropping modal
  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const imageDataUrl = await readFile(file);
      setImageSrc(imageDataUrl);
      setCropping(true); 
      setFormData((prevData) => ({
        ...prevData,
         file,
      }));
    }
  };

  const handleSaveCroppedImage = async () => {
    try {
      const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels);
      setFormData((prevData) => ({
        ...prevData,
        image: croppedImage,
      }));
      setCropping(false);
    } catch (error) {
      console.error("Error cropping the image", error);
    }
  };

  const readFile = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.readAsDataURL(file);
    });
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => {
      const updatedData = { ...prevData, [id]: value };
  
      if (
        id === "address" 
      ) {
          const query = updatedData.address;
          const encodedQuery = encodeURIComponent(query.trim());
          const url = `https://www.google.com/maps/search/?api=1&query=${encodedQuery}`;
          console.log(url);
  
        updatedData["location"]= url;
      }
      return updatedData;
    });
  };

  const accordionItems = [

    {
      id: "0",
      title: "Short Link",
      icon: linkicon,
      content: (
        <QrLink
          formData={formData}
          setFormData={setFormData}
          isEdit={isEdit}
          qrType={qrType}
        />
      ),
    },
    {
      id: "1",
      title: "Customize Preview",
      icon: designicon,
      content: (
        <div className="d-flex gap-10 align-items-center">
          {/* Primary Color Picker */}
          <FormGroup>
            <Label className="fs-4 fw-semibold mb-4">Primary Color</Label>
            <div className="color-picker-wrapper">
              <Input
                type="color"
                className="color-picker rounded-0 cursor-pointer"
                value={formData?.primaryColor}
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    primaryColor: e.target.value,
                  }))
                }
              />
              <Input
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    primaryColor: e.target.value,
                  }))
                }
                className="hex-input form-control rounded-0"
                type="text"
                maxLength="7"
                value={formData?.primaryColor}
                placeholder="#000000"
              />
            </div>
          </FormGroup>
          {/* Button Color Picker */}
          {/* <FormGroup>
            <Label className="fs-4 fw-semibold mb-4">Button Color</Label>
            <div className="color-picker-wrapper">
              <Input
                type="color"
                className="color-picker rounded-0 cursor-pointer"
                value={formData?.ctaColor}
                onChange={(e) =>
                  setFormData((prev) => ({ ...prev, ctaColor: e.target.value }))
                }
              />
              <Input
                className="hex-input form-control rounded-0"
                type="text"
                maxLength="7"
                value={formData?.ctaColor}
                onChange={(e) =>
                  setFormData((prev) => ({ ...prev, ctaColor: e.target.value }))
                }
                placeholder="#000000"
              />
            </div>
          </FormGroup> */}
          {/* Gradient Toggle */}
          <FormGroup className="mt-10" check>
            <Label check>
              <Input
                type="checkbox"
                checked={formData?.gradient}
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    gradient: e.target.checked,
                  }))
                }
              />{" "}
              Enable Gradient
            </Label>
          </FormGroup>
          {formData?.gradient && (
            <FormGroup>
              <Label className="fs-4 fw-semibold mb-4">Gradient Color</Label>
              <div className="color-picker-wrapper">
                <Input
                  type="color"
                  className="color-picker rounded-0 cursor-pointer"
                  value={formData?.gradientColor}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      gradientColor: e.target.value,
                    }))
                  }
                />
                <Input
                  className="hex-input form-control rounded-0"
                  type="text"
                  maxLength="7"
                  value={formData?.gradientColor}
                  onChange={(e) =>
                    setFormData((prev) => ({ ...prev, gradientColor: e.target.value }))
                  }
                  placeholder="#000000"
                />
              </div>
            </FormGroup>
          )}
        </div>
      ),
    },
    {
      id: "2",
      icon: infoicon,
      title: "Your Information",
      content: (
        <>
          <div>
            <p className="fs-5 fw-bold mb-0">Profile</p>
          </div>
          <div className="mt-3 pe-8 mb-6">
            <div
              className="image-input image-input-outline"
              style={{ backgroundImage: `url(${formData?.image || defaultimg})` }}
            >
              <div
                className="image-input-wrapper w-125px h-125px shadow-none border border-dark border-opacity-10"
                style={{ backgroundImage: `url(${formData?.image || defaultimg})` }}
              ></div>
              <Label
                className="btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body position-absolute border border-dark border-opacity-10"
                style={{ right: "3px", top: "3px" }}
              >
                <i className="fas fa-pencil fs-6"></i>
                <Input
                  className="d-none"
                  type="file"
                  name="image"
                  accept=".png, .jpg, .jpeg"
                  onChange={handleImageChange} // Open cropping modal
                />
              </Label>
            </div>
          </div>
        </>
      ),
    
      fields: [
        { id: "firstName", label: "First Name", type: "text", colSize: 6, className: "h-50px" },
        { id: "lastName", label: "Last Name", type: "text", colSize: 6, className: "h-50px" },
        { id: "mobile", label: "Mobile", type: "tel", colSize: 6, className: "h-50px" },
        { id: "phone", label: "Phone", type: "tel", colSize: 6, className: "h-50px" },
        { id: "fax", label: "Fax", type: "text", colSize: 6, className: "h-50px" },
        { id: "email", label: "Email", type: "email", colSize: 6, className: "h-50px" },
        { id: "company", label: "Company", type: "text", colSize: 6, className: "h-50px" },
        { id: "job", label: "Job", type: "text", colSize: 6, className: "h-50px" },
        { id: "address", label: "Address", type: "text", className: "h-50px" },
        { id: "website", label: "Website", type: "text", colSize: 6, className: "h-50px" },
        { id: "businessType", label: "Business Type", type: "text", colSize: 6, className: "h-50px" },
        { id: "summary", label: "Summary", type: "textarea", maxLength: 200 },
      ],
    },
    {
      id: "3",
      icon: socialicon,
      title: "Social Media",
      fields: [
        { id: "facebook", label: "Facebook URL", placeholder: "https://facebook.com/", type: "text", colSize: 6, className: "h-50px" },
        { id: "instagram", label: "Instagram URL", placeholder: "https://instagram.com/", type: "text", colSize: 6, className: "h-50px" },
        { id: "google", label: "Google URL", placeholder: "https://google.com/", type: "text", colSize: 6, className: "h-50px" },
        { id: "linkedin", label: "LinkedIn URL", placeholder: "https://linkedin.com/", type: "text", colSize: 6, className: "h-50px" },
      ],
    },

    // {
    //   id: "4",
    //   title: "Welcome Screen",
    //   fields: [
    //     {
    //       id: "welcomeImage",
    //       label: "Upload Welcome Screen Image",
    //       type: "file",
    //     },
    //   ],
    // },
    // {
    //   id: "5",
    //   title: "Advanced Options",
    //   content: (
    //     <FormGroup check>
    //       <Label check>
    //         <Input type="checkbox" /> Add a share button to the page
    //       </Label>
    //     </FormGroup>
    //   ),
    // },
  ];

  return (
    <>
    <Accordion className="qr-accodian-form mt-8" open={open} toggle={toggle}>
      {accordionItems.map((item) => (
        <AccordionItem key={item.id}>
          <AccordionHeader targetId={item.id}> <img src={item.icon} alt={item.title} className="w-30px me-4" /> {item.title}</AccordionHeader>
          <AccordionBody accordionId={item.id}>
            {item.content}
            {item.fields && (
              <Form>
                <div className="row">
                  {item.fields.map((field) => (
                    <div
                      key={field.id}
                      className={`col-md-${field.colSize || 12} mb-3`} // 6 columns if colSize is 6, otherwise full width
                    >
                      <FormGroup>
                        <Label className="fs-5 fw-bold" for={field.id}>
                          {field.label}
                        </Label>
                        <Input
                          type={field.type}
                          className={field.className}
                          maxLength={field.maxLength}
                          id={field.id}
                          placeholder={field.placeholder}
                          value={field.type != "file" && formData[field?.id] || ""}
                          onChange={handleInputChange}
                        />
                      </FormGroup>
                    </div>
                  ))}
                </div>
              </Form>
            )}
          </AccordionBody>

        </AccordionItem>
      ))}
    </Accordion>

  {/* Cropping Modal */}
      <Modal isOpen={cropping} toggle={() => setCropping(!cropping)}>
        <ModalHeader toggle={() => setCropping(!cropping)}>
          Crop your image
        </ModalHeader>
        <ModalBody>
          {imageSrc && (
            <div className="crop-container">
              <Cropper
                image={imageSrc}
                crop={crop}
                zoom={zoom}
                aspect={1} // 1:1 aspect ratio for square crop
                onCropChange={setCrop}
                onCropComplete={(_, croppedAreaPixels) =>
                  setCroppedAreaPixels(croppedAreaPixels)
                }
                onZoomChange={setZoom}
              />
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSaveCroppedImage}>
            Save Image
          </Button>
          <Button color="secondary" onClick={() => setCropping(false)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
</>
  );
};

export default Vcard;
