import React, { useState } from "react";
import {
  Button,
  Form,
  Input,
  InputGroup,
  Tooltip,
  Collapse,
  FormGroup,
  Label,
} from "reactstrap";

const Sender = ({
  selectedSender,
  setSelectedSender,
  replyToEmail,
  setReplyToEmail,
  sendToField,
  setSendToField,
  showEmailForm,
  setShowEmailForm,
  tooltipOpen,
  setTooltipOpen,
  isAdvancedOpen,
  setIsAdvancedOpen,
  senderName,
  setSenderName,
  senderEmail,
  setSenderEmail,
  handleAddSender,
  handleSave,
  handleCancel,
  fromValues,
}) => {
  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);
  const toggleEmailForm = () => setShowEmailForm(!showEmailForm);
  const toggleAdvanced = () => setIsAdvancedOpen(!isAdvancedOpen);

  const handleSenderChange = (e) => {
    console.log("target", e.target.value);
    setSelectedSender(e.target.value);
  };

  const handleNameChange = (e) => {
    setSenderName(e.target.value);
  };

  const handleReplyToEmailChange = (e) => setReplyToEmail(e.target.value);
  const handleSendToFieldChange = (e) => setSendToField(e.target.value);

  const [emailError, setEmailError] = useState("");
  const [replyToError, setReplyToError] = useState("");

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleEmailChange = (e) => {
    const email = e.target.value;
    setSenderEmail(email);
    if (!validateEmail(email)) {
      setEmailError("Invalid email address");
    } else {
      setEmailError("");
    }
  };

  const onSave = () => {};

  return (
    <div>
      <Form>
        <FormGroup>
          <Label for="selectSender">Select Sender</Label>
          <InputGroup>
            <Input
              type="select"
              name="selectSender"
              id="selectSender"
              value={selectedSender}
              onChange={handleSenderChange}
            >
              <option value="">Choose...</option>
              {fromValues.map((item, index) => (
                <option key={index} value={item.value}>
                  {item.value}
                </option>
              ))}
            </Input>
            <Button id="addSenderButton" onClick={toggleEmailForm}>
              +
            </Button>
            <Tooltip
              placement="top"
              isOpen={tooltipOpen}
              target="addSenderButton"
              toggle={toggleTooltip}
            >
              Add Sender
            </Tooltip>
          </InputGroup>
        </FormGroup>

        {showEmailForm && (
          <FormGroup>
            <Label for="senderName">Name of the sender</Label>
            <Input
              type="text"
              name="nameOfSender"
              id="senderName"
              placeholder="Enter Name"
              value={senderName}
              onChange={handleNameChange}
            />
            <Label for="addEmail">Add Email</Label>
            <Input
              type="email"
              name="addEmail"
              id="addEmail"
              placeholder="Enter email"
              value={senderEmail}
              onChange={handleEmailChange}
              invalid={!!emailError}
            />
            {emailError && <div className="text-danger">{emailError}</div>}
            {/* <Button color="primary" onClick={handleAddSender} disabled={!!emailError}>
              Add
            </Button> */}
            <button
              type="button"
              class="btn text-nowrap rounded-1 btn-info text-white"
              color="danger"
              onClick={handleAddSender}
              disabled={!!emailError}
              style={{ marginTop: "10px" }}
            >
              Add
            </button>
          </FormGroup>
        )}

        <Button color="link" onClick={toggleAdvanced}>
          Advanced Settings <i className="fas fa-chevron-down"></i>
        </Button>
        <Collapse isOpen={isAdvancedOpen}>
          <FormGroup>
            <Label for="replyToEmail">
              Customize the Reply-To Email address
            </Label>
            <Input
              type="email"
              name="replyToEmail"
              id="replyToEmail"
              placeholder="Enter Reply-To email"
              value={replyToEmail}
              onChange={(e) => {
                handleReplyToEmailChange(e);
                if (e.target.value && !validateEmail(e.target.value)) {
                  setReplyToError("Invalid email address");
                } else {
                  setReplyToError("");
                }
              }}
              invalid={!!emailError}
            />
            {replyToError && <div className="text-danger">{replyToError}</div>}
          </FormGroup>
          {/* <FormGroup>
            <Label for="sendToField">Personalize the 'Send To' field</Label>
            <Input 
              type="text" 
              name="sendToField" 
              id="sendToField" 
              placeholder="Enter Send To field" 
              value={sendToField}
              onChange={handleSendToFieldChange}
            />
          </FormGroup> */}
        </Collapse>

        {/* Cancel and Save Buttons */}
        <div className="d-flex justify-content-end mt-3">
          {/* <Button color="secondary" className="mr-2" onClick={handleCancel}>
            Cancel
          </Button> */}
          <button
            type="button"
            className="btn text-nowrap rounded-1 btn-secondary"
            onClick={handleCancel}
          >
            Cancel
          </button>
          {/* <Button color="primary" onClick={handleSave} disabled={!!emailError || !!replyToError} style={{ marginTop: '10px', marginLeft: '10px' }}>
            Save
          </Button> */}
          <button
            type="button"
            class="btn text-nowrap rounded-1 btn-info text-white"
            color="danger"
            onClick={handleSave}
            disabled={!!emailError || !!replyToError}
            style={{ marginLeft: "10px" }}
          >
            Save
          </button>
        </div>
      </Form>
    </div>
  );
};

export default Sender;
