import React, { useEffect, useState } from "react";
import ClicksChart from "./clicksChart";
import CountCard from "./countCard";
import DeviceChart from "./deviceChart";
import LocationMap from "./locationMap";
import LocationStat from "./locationStat";
import ScansChart from "./scansChart";
import SourceChart from "./sourceChart";
import UserSettingsApi from "../../services/http/userSettings";
import dayjs from "dayjs";
import DateRangeSelector from "../common/analytics/dateRange";
import OnBoarding from "../../pages/onboarding/OnBoarding";
import { Modal, ModalBody } from "reactstrap";
import useOnboarding from "../../hooks/useOnboarding";

const Index = () => {
    const [loading, setLoading] = useState(false);
    const [analytics, setAnalytics] = useState({});
    const [openDatePicker, setOpenDatePicker] = useState(false);
    const [selectedDateRange, setSelectedDateRange] = useState({
        startDate: new Date(new Date().setDate(new Date().getDate() - 30)),
        endDate: new Date(),
        key: "selection",
    });

    // Call the useOnboarding hook directly
    const { isModalOpen, handleOnboardingComplete, activeSlides } = useOnboarding();

    const fetchAnalytics = async (
        startDate = new Date(new Date().setDate(new Date().getDate() - 30)).toUTCString(),
        endDate = new Date().toUTCString()
    ) => {
        setLoading(true);
        try {
            const response = await UserSettingsApi.dashboardAnalytics({
                startDate,
                endDate,
            });
            setAnalytics(response?.data?.resultData || {});
            setLoading(false);
        } catch (e) {
            console.log("Error while fetching user dashboard analytics: ", e);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchAnalytics().catch(error => error);
    }, []);

    const {
        country = [],
        city = [],
        date = [],
        device = [],
        clicks = [],
        scans = [],
        linkQrCount = [],
        referrer = [],
    } = analytics || {};

    const totalClicks = clicks?.reduce((total, item) => total + item?._count?.createdAt, 0);
    const totalScans = scans?.reduce((total, item) => total + item?._count?.createdAt, 0);
    const linksCount = linkQrCount?.reduce(
        (total, item) =>
            ["shortlink", "linkInBio", "qr"].includes(item?.type) ? total + item?._count?.userId : total,
        0
    );
    const qrCount = linkQrCount?.reduce(
        (total, item) =>
            ["qr"].includes(item?.type) ? total + item?._count?.userId : total,
        0
    );

    return (
        <div className="card card-flush rounded-sm-0 border-0">
            <div className="px-lg-10 px-6 py-lg-11 py-5 d-flex justify-content-between w-100">
                <h2 className="me-15 mb-0 fs-2x">Dashboard</h2>
                <div className="card-toolbar">
                    <div className="mt-5 mt-lg-0">
                        <div
                            onClick={() => setOpenDatePicker(!openDatePicker)}
                            className="form-control form-control-solid w-lg-250px justify-content-between cursor-pointer"
                            style={{ position: "relative" }}
                        >
                            <span>{dayjs(selectedDateRange.startDate).format("MM/DD/YYYY")}</span>
                            <span> - </span>
                            <span>{dayjs(selectedDateRange.endDate).format("MM/DD/YYYY")}</span>
                        </div>
                        {openDatePicker && (
                            <div style={{ position: "absolute", zIndex: 99999, right: 0 }}>
                                <DateRangeSelector
                                    analyticsHandler={fetchAnalytics}
                                    toggleHandler={setOpenDatePicker}
                                    selectedDateRange={selectedDateRange}
                                    setSelectedDateRange={setSelectedDateRange}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <div className="card-body border-top pt-lg-10 px-lg-10 px-6 pt-6 mb-lg-0 mb-20">
                <div className="row g-5 g-xl-10 mb-xl-10">
                    <CountCard stats={{ type: "clicks", count: totalClicks }} />
                    <CountCard stats={{ type: "scans", count: totalScans }} />
                    <CountCard stats={{ type: "links", count: linksCount }} />
                    <CountCard stats={{ type: "qr", count: qrCount }} />
                    <ClicksChart clicks={clicks} totalClicks={totalClicks} loading={loading} />
                    <ScansChart scans={scans} totalScans={totalScans} loading={loading} />
                    <DeviceChart device={device} loading={loading} />
                    <SourceChart referrer={referrer} loading={loading} />
                    <LocationMap country={country} loading={loading} />
                    <LocationStat
                        country={country}
                        city={city}
                        date={date}
                        loading={loading}
                    />
                </div>
                <div className="mb-20 desktop-hide"></div>
                <div className="position-fixed d-flex justify-content-between mobbottommenu">
                    <button type="button" className="btn d-flex flex-column align-items-center rounded-1 px-4 py-3">
                        <i className="fa-solid fa-qrcode text-info fs-3"></i>
                        <span style={{ fontSize: "10px" }}>QR Code</span>
                    </button>
                    <button type="button" className="btn d-flex flex-column align-items-center rounded-1 px-4 py-3">
                        <i className="fa-solid fa-globe text-info fs-3"></i>
                        <span style={{ fontSize: "10px" }}>Custom Link</span>
                    </button>
                    <button type="button" className="btn d-flex flex-column align-items-center rounded-1 px-4 py-3">
                        <i className="fa-regular fa-link text-info fs-3"></i>
                        <span style={{ fontSize: "10px" }}>Short Link</span>
                    </button>
                    <button type="button" className="btn d-flex flex-column align-items-center rounded-1 px-4 py-3">
                        <i className="fa-solid fa-clipboard-list text-info fs-3"></i>
                        <span style={{ fontSize: "10px" }}>Link-in-bio</span>
                    </button>
                </div>
            </div>

            <div className='onboarding-styles' style={{ background: "none" }}>
            <Modal isOpen={isModalOpen} toggle={handleOnboardingComplete} className="custom-modal">
                    <ModalBody>
                        <OnBoarding onComplete={handleOnboardingComplete} />
                    </ModalBody>
                </Modal>
            </div>
        </div>
    );
};

export default Index;
