import httpMethods from "..";

const WhatsappTemplateAPI = {
  createWhatsappTemplate: async (token, values) => {
    return httpMethods
      .post(`/whatsapp-templates`, values, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => res);
  },

  createGalleryWhatsappTemplate: async (token, values) => {
    return httpMethods
      .post(`/whatsapp-templates/gallery`, values, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => res);
  },

  getAllWhatsappTemplates: async (token, params) => {
    return httpMethods
      .get(`/whatsapp-templates`, {
        params,
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => res)
      .catch((error) => {
        throw error;
      });
  },

  updateWhatsappTemplates: async (token, id, values) => {
    return httpMethods
      .put(`/whatsapp-templates/${id}`, values, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => res);
  },

  deleteWhatsappTemplates: async (token, id) => {
    return httpMethods
      .delete(`/whatsapp-templates/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => res);
  },

  getWhatsappTemplateById: async (token, id) => {
    return httpMethods
      .get(`/whatsapp-templates/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => res)
      .catch((error) => {
        throw error;
      });
  },

  fileUpload: async (token, body) => {
    return httpMethods
      .post(`/whatsapp-templates/file/upload`, body, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => res)
      .catch((error) => {
        throw error;
      });
  },
};

export default WhatsappTemplateAPI;
