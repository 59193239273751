import React, { useContext } from 'react';
import { SocialIcon } from 'react-social-icons';
import { AuthContxt } from '../../../store/authContxt';

// QR Monkey API URL
const QR_MONKEY_API_URL = "https://api.qrmonkey.com/";

const SocialMediaIconsQR = ({ setFormData, setB }) => {
  const { render, setRender } = useContext(AuthContxt);

  // Social media links with specific IDs (for QR Monkey logo customization)
  const socialLinks = [
    { id: "facebook-circle", link: "https://www.facebook.com" },
    { id: "instagram-circle", link: "https://www.instagram.com" },
    { id: "linkedin-circle", link: "https://www.linkedin.com" },
    { id: "twitter-circle", link: "https://www.twitter.com" },
    { id: "youtube-circle", link: "https://www.youtube.com" },
    { id: "pinterest-circle", link: "https://www.pinterest.com" },
    { id: "whatsapp-circle", link: "https://www.whatsapp.com" }
  ];

  const handleClick = async (e, link, id) => {
    e.preventDefault();

  
      setFormData((prev) => ({
        ...prev,
        logo: link,
        qrLogoId: `#${id}`, 
      }));

      setRender(!render);
  };

  return (
    <>
      {socialLinks.map((social, index) => (
        <div
          key={index}
          className={
            "bg-white p-3 h-100px w-100px d-flex justify-content-center align-items-center me-5 mb-5 rounded-1 btn on-hvr-border bdr-qr-btn"
          }
          onClick={(e) => handleClick(e, social.link, social.id)}
        >
          <SocialIcon url={social.link} />
        </div>
      ))}
    </>
  );
};

export default SocialMediaIconsQR;



