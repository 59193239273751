import React from 'react'

const IconFrame5 = ({ primary,  secondary, fontSize, text, textColor, type, url, width, height, minFontSize, maxFontSize }) => {
  return (
    <>
  

<svg width={width || 3765} height={height || 3215} viewBox="0 0 3765 3215" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M430.027 2907.59C478.284 3084.52 640.311 3215 832.316 3215C1024.34 3215 1186.36 3084.51 1234.62 2907.59H430.027Z" fill={primary ||  "#0E1B21"}/>
<path d="M2800.13 2907.59C2848.39 3084.52 3010.41 3215 3202.42 3215C3394.43 3215 3556.45 3084.51 3604.71 2907.59H2800.13Z" fill={primary ||  "#0E1B21"}/>
<path d="M3478.14 1869.76L3005.09 1534.58L3005.6 1254.51C3027.86 1271.24 3055.44 1281.29 3085.44 1281.29C3158.97 1281.29 3218.59 1221.67 3218.59 1148.14V872.15C3218.59 798.616 3158.97 739 3085.44 739C3046.1 739 3010.86 756.165 2986.48 783.294C2960.63 764.119 2928.73 752.644 2894.08 752.644C2820.47 752.644 2752.85 776.346 2697.99 816.251L2172.42 973.413C2101.96 994.475 2061.94 1068.67 2083.01 1139.12C2100.26 1196.86 2153.19 1234.16 2210.51 1234.16C2223.15 1234.16 2235.99 1232.35 2248.72 1228.54L2561.52 1135.01L2555.73 1770.55L2470.81 2012.35C2356.95 2285.14 2090.31 2462.77 1794.71 2462.77H1579.79C1531.26 2250.19 1569.14 2024.14 1689.81 1837.14H498.39C243.61 1936.52 63 2184.14 63 2474.1V2662.39C63 2711.24 90.8301 2753.51 131.458 2774.44H2506.05C2539.7 2419.78 2839.11 2141.39 3202.42 2141.39C3341.59 2141.39 3471.3 2182.4 3580.39 2252.74L3583.98 2250.47C3643.39 2114.52 3599.2 1955.53 3478.14 1869.76Z" fill={secondary ||  "white"}/>
<path d="M1722.23 1470.64H899.216C825.682 1470.64 766.066 1530.26 766.066 1603.79C766.066 1643.79 783.796 1679.57 811.712 1703.98H1809.73C1837.65 1679.58 1855.38 1643.79 1855.38 1603.79C1855.38 1530.26 1795.76 1470.64 1722.23 1470.64Z" fill={primary ||  "#0E1B21"}/>
<path d="M3202.42 2274.54C2912.58 2274.54 2672.88 2493.33 2639.84 2774.44H3765C3731.95 2493.34 3492.25 2274.54 3202.42 2274.54Z" fill={secondary ||  "white"}/>
<rect width="1392" height="1704" rx="100" fill={primary ||  "#0E1B21"}/>
<rect x="63" y="63" width="1266" height="1266" rx="61" fill="white"/>

<text fill={textColor || "white"} xmlSpace="preserve" style={{ whiteSpace: "pre" }} fontFamily="Arial" fontSize={fontSize || 223} fontWeight="bold" letterSpacing="0em">
      <tspan x="19%" textAnchor='middle' y="1585.31">{text}</tspan>
    </text>
<image href={url} x="124" y="124" width="1144" height="1144" />
</svg>
 

    </>
  )
}

export default IconFrame5