import { useEffect, useState } from "react";
import { getUrlOrigin } from "../../utils";
import { getFavicon } from "../../utils/getFavicon";

const divslyImg =
  "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTkiIGhlaWdodD0iNTIiIHZpZXdCb3g9IjAgMCA1OSA1MiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgb3BhY2l0eT0iMC41Ij4KPHBhdGggZD0iTTQ4LjU2MDcgMjUuOTg2OUwzNC4xNTIgNDAuODEyOEMzMi4zMDcyIDQyLjc1MjEgMzAuNTI1OSA0NC4xNTk3IDI4LjgwODMgNDUuMDM1NUMyNy4wOTA3IDQ1Ljg0ODcgMjUuNTAwMyA0Ni4yNTUzIDI0LjAzNzIgNDYuMjU1M0MyMi40NDY4IDQ2LjI1NTMgMjAuOTgzNyA0NS44NDg3IDE5LjY0NzggNDUuMDM1NUMxOC4zMTE4IDQ0LjIyMjMgMTcuMDcxMyA0My4xMjc0IDE1LjkyNjMgNDEuNzUxM0wzMi4zMzg5IDI2LjA4MDdMMTYuMTE3MSAxMC41OTc5QzE4Ljg1MjYgNy41MzI2NCAyMS41ODggNiAyNC4zMjM0IDZDMjcuMzc3IDYgMzAuNzE2OCA3Ljg0NTQzIDM0LjM0MjggMTEuNTM2M0w0OC41NjA3IDI1Ljk4NjlaIiBmaWxsPSIjQkVCRUJFIi8+CjxwYXRoIGQ9Ik00My4wNjYzIDIxLjUyQzQzLjA2NjMgMjMuMTkzIDQyLjczMzUgMjQuNzU3IDQyLjA2NzggMjYuMjExN0M0MS40NzU5IDI3LjY2NjUgNDAuNjI1MyAyOC45Mzk1IDM5LjUxNTggMzAuMDMwNkMzOC40MDYzIDMxLjEyMTggMzcuMDc0OCAzMS45OTQ2IDM1LjUyMTMgMzIuNjQ5MkMzNC4wNDE5IDMzLjMwMzkgMzIuMzc3NiAzMy42MzEzIDMwLjUyODMgMzMuNjMxM0gxMUMxMSAzMi4wMzEgMTEuMjk1OSAzMC41MDM0IDExLjg4NzcgMjkuMDQ4NkMxMi40Nzk0IDI3LjUyMSAxMy4zMzAxIDI2LjIxMTcgMTQuNDM5NyAyNS4xMjA2QzE1LjU0OTIgMjQuMDI5NSAxNi44NDM3IDIzLjE1NjcgMTguMzIzMSAyMi41MDJDMTkuODc2NSAyMS44NDczIDIxLjYxNDggMjEuNTIgMjMuNTM4MSAyMS41Mkg0My4wNjYzWiIgZmlsbD0iIzg5ODk4OSIvPgo8L2c+Cjwvc3ZnPgo=";


const FaviconImage = ({ list }) => {
  const [image, setImage] = useState("");

  const imgHandler = async () => {
    
    let origin=""
    if(list?.destinationUrl) {
       origin = await getUrlOrigin(list.destinationUrl);

    }

    const imgUrl = await getFavicon(list.destinationUrl);
    const result = imgUrl || divslyImg || `${origin}/favicon.ico`;
    setImage(result);
  };

  useEffect(() => {
    imgHandler();
  }, [list.destinationUrl]);

  return <img className="rounded-1" src={image||""} alt="Favicon" />;
};

export default FaviconImage;
