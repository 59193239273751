import httpMethods from "..";

const FromNumberAPI = {
  getAllFromNumbers: async (auth_token) => {
    try {
      const response = await httpMethods.get(`/from-numbers`, {
        headers: {
          Authorization: `Bearer ${auth_token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching from numbers:", error);
      throw error;
    }
  },

  createFromNumber: async (auth_token, data) => {
    try {
      const response = await httpMethods.post(`/from-numbers`, data, {
        headers: {
          Authorization: `Bearer ${auth_token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error creating from number:", error);
      throw error;
    }
  },

  updateFromNumber: async (auth_token, id, data) => {
    try {
      const response = await httpMethods.put(`/from-numbers/${id}`, data, {
        headers: {
          Authorization: `Bearer ${auth_token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error updating from number:", error);
      throw error;
    }
  },

  deleteFromNumber: async (auth_token, id) => {
    try {
      const response = await httpMethods.delete(`/from-numbers/${id}`, {
        headers: {
          Authorization: `Bearer ${auth_token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error deleting from number:", error);
      throw error;
    }
  },
};

export default FromNumberAPI;
