import CreateWhatsappCampaign from "../components/whatsapp-campaigns/createWhatsappCampaign";
import WhatsappCampaignContxtProvider from "../components/whatsapp-campaigns/whatsappCampaignContxt";

const WhatsappCampaigns = () => {
  return (
    <WhatsappCampaignContxtProvider>
      <CreateWhatsappCampaign />
    </WhatsappCampaignContxtProvider>
  );
};

export default WhatsappCampaigns;
