import React from 'react';

const IconFrame11 = ({
  primary,
  secondary,
  fontSize,
  text,
  textColor,
  url,
  width,
  height,
}) => {
  return (
    <svg width={width || 1723} height={height || 1712} viewBox="0 0 1723 1713" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_175_7418)">
<path d="M176 1295H1538L1728 1504L1538 1713H176L-4 1504L176 1295Z" fill={primary || "white" }/>
<text
          fill={textColor || "black"}
          xmlSpace="preserve"
          style={{ whiteSpace: "pre" }}
          fontFamily="Arial"
          fontSize={fontSize || 224}
          fontWeight="bold"
          letterSpacing="0em"
        >
          <tspan x="50%" textAnchor='middle' y="1568.16">
            {text}
          </tspan>
        </text>

<rect x="179" width="1348" height="1348" rx="108" fill={secondary || "black"}/>
<rect x="252" y="73" width="1202" height="1202" rx="58" fill="white"/>
</g>
<defs>
<clipPath id="clip0_175_7418">
<rect width="1723" height="1713" fill="white"/>
</clipPath>
</defs>

<image href={url} x="301" y="122" width="1104" height="1104" />
</svg>
  );
};

export default IconFrame11;
