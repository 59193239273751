import React from 'react';
import {useContext } from 'react';
import Profile from '../../components/user-settings/profile';
import { AuthContxt } from '../../store/authContxt';



const WelcomeCard = ({ name, imageclap, whileLogo }) =>  {
    const { profile } = useContext(AuthContxt);

    return (
    <div className="large-card onboard ms-5 d-flex flex-column align-items-start mt-5 ms-auto p-5 bg-light rounded" style={{width: "400px", background: '#490aa8' }}>
        <div className="image-clap">
            <img src={whileLogo} alt="image clapping" className="w-75 p-4" />
        </div>
        <h5 style={{ fontSize: '16px' }} className="ps-4">Welcome to <span style={{ color: 'yellow' }}>{profile.firstName}</span></h5>
        <div className="image-clap">
            <img src={imageclap} alt="image clapping" style={{marginTop: "-10px"}} className="img-fluid" />
        </div>
        <div className="ms-5">
            <h4 style={{ fontSize: '25px', marginTop: '-55px', fontWeight: 'bold' }}>Let's Set Up Initial Preferences</h4>
            <p style={{ fontSize: '15px', padding: '15px 0px 4px' }}>Start Using Divsly</p>
            <p style={{ fontSize: '15px', padding: '0px 0px 4px' }}>Creating Shortlinks</p>
            <p style={{ fontSize: '15px', padding: '0px 0px 4px' }}>Customized QR Codes</p>
            <p style={{ fontSize: '15px', padding: '0px 0px 4px' }}>Creating Link-in-bio Page</p>
            <p style={{ fontSize: '15px', padding: '0px 0px 4px' }}>Custom Domain Basics</p>
        </div>
        <a href="https://help.divsly.com/kb/" className="button-clas" target="_blank" rel="noopener noreferrer">
            Know more about Divsly
        </a>
    </div>
)};

export default WelcomeCard;