import React, { useEffect, useRef } from "react";
import ApexCharts from "apexcharts";

const BarChartA = ({ categories, data, type }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    if (!chartRef.current) return;

    const element = chartRef.current;

    const height = "400px";
    const labelColor = "#181c32";
    const borderColor = "#e1e3ea";

    const colors = [
      "#FFA755",
      "#FED479",
      "#E76D78",
      "#88E1C9",
      "#82DAE1",
      "#60C1EB",
      "#A1A0EC",
    ];

    const options = {
      series: [
        {
          name: type,
          data,
        },
      ],
      chart: {
        fontFamily: "inherit",
        type: "bar",
        height: height,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: ["28%"],
          borderRadius: 5,
          dataLabels: {
            position: "top",
          },
          startingShape: "flat",
          distributed: true, // Ensures each bar gets a different color
        },
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: true,
        offsetY: -28,
        style: {
          fontSize: "13px",
          colors: [labelColor],
        },
        formatter: function (val) {
          return val;
        },
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories,
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          style: {
            colors: getComputedStyle(document.documentElement).getPropertyValue(
              "--bs-gray-500"
            ),
            fontSize: "13px",
          },
        },
        crosshairs: {
          fill: {
            gradient: {
              opacityFrom: 0,
              opacityTo: 0,
            },
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: "#a1a5b7",
            fontSize: "13px",
          },
          formatter: function (val) {
            return val;
          },
        },
      },
      fill: {
        opacity: 1,
      },
      colors: colors,
      states: {
        normal: {
          filter: {
            type: "none",
            value: 0,
          },
        },
        hover: {
          filter: {
            type: "none",
            value: 0,
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: "none",
            value: 0,
          },
        },
      },
      tooltip: {
        style: {
          fontSize: "12px",
        },
        y: {
          formatter: function (val) {
            return val;
          },
        },
      },
      grid: {
        borderColor: borderColor,
        strokeDashArray: 4,
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
    };

    const chart = new ApexCharts(element, options);
    chart.render();

    return () => {
      chart.destroy();
    };
  }, [categories, data, type]);

  return <div ref={chartRef} style={{width: "100%"}}></div>;
};

export default BarChartA;
