import React from 'react'

const IconFrame5 = ({ primary,  secondary, fontSize, text, textColor, type, url, width, height, minFontSize, maxFontSize }) => {
  return (
    <>
  

<svg width={width || 2366} height={height || 2843} viewBox="0 0 2366 2843" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M658.364 583.602L430.594 1212.22V2558.37L2311.55 2505.54L2366 989.501L1831.05 497.337L658.364 583.602Z" fill={primary ||  "#0E1B21"}/>
<path d="M795.712 98.8881C810.004 60.4244 797.823 52.5001 768.369 81.0054L561.174 281.912L697.621 269.281L400.942 642.811V437.308L0 826.15V2033.72L400.942 2588L381.124 1207.18L795.712 98.8881Z" fill={secondary ||  "white"}/>
<path d="M430.594 375.758V565.089L631.048 312.653L474.944 327.123C450.505 329.447 430.594 351.211 430.594 375.758Z" fill={secondary ||  "white"}/>
<path d="M1978.77 315.904C1978.77 291.373 1958.88 269.541 1934.42 267.252L1798.39 254.637L1611.75 31.7794C1585.44 0.388739 1580.1 4.06573 1599.71 39.993L1826.41 453.748L2365.99 932.583L1978.77 470.133V315.904Z" fill={secondary ||  "white"}/>
<path d="M847.105 92.6295L689.648 528.532L1778.29 452.158L1565.14 62.3028C1545.39 26.3075 1496.28 -1.54236 1455.25 0.0663263L946.532 19.9153C905.527 21.4814 861.023 54.0296 847.105 92.6295ZM1433.98 185.652C1474.92 183.541 1523.13 211.51 1541.53 248.127L1580.85 326.025L879.462 367.34L907.945 281.901C920.918 243.012 964.638 209.791 1005.58 207.68L1433.98 185.652Z" fill={primary ||  "#0E1B21"}/>

<text fill={textColor || "black"} xmlSpace="preserve" style={{ whiteSpace: "pre" }} fontFamily="Arial" fontSize={fontSize || 226} fontWeight="bold" letterSpacing="0em">
      <tspan x="60%" textAnchor='middle' y="2823.85">{text}</tspan>
    </text>

<rect x="711" y="946" width="1316" height="1316" rx="50" fill="white"/>
<image href={url} x="797" y="1032" width="1144" height="1144" />
</svg>


 

    </>
  )
}

export default IconFrame5