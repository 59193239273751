import React from 'react'

const IconFrame11 = ({ primary,  secondary, fontSize, text, textColor, type, url, width, height, minFontSize, maxFontSize }) => {
  return (
    <>
  <svg width={width || 2572} height={height || 2912} viewBox="0 0 2572 2912" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clipPath="url(#clip0_175_7425)">
    {/* Background shape */}
    <path d="M1099.12 140.199C1209.86 57.1226 1362.14 57.1226 1472.88 140.199V140.199C1537.08 188.358 1617.38 209.876 1697.06 200.266V200.266C1834.5 183.689 1966.38 259.833 2020.75 387.149V387.149C2052.26 460.952 2111.05 519.741 2184.85 551.255V551.255C2312.17 605.619 2388.31 737.503 2371.73 874.944V874.944C2362.12 954.617 2383.64 1034.92 2431.8 1099.12V1099.12C2514.88 1209.86 2514.88 1362.14 2431.8 1472.88V1472.88C2383.64 1537.08 2362.12 1617.38 2371.73 1697.05V1697.05C2388.31 1834.5 2312.17 1966.38 2184.85 2020.74V2020.74C2111.05 2052.26 2052.26 2111.05 2020.75 2184.85V2184.85C1966.38 2312.17 1834.5 2388.31 1697.06 2371.73V2371.73C1617.38 2362.12 1537.08 2383.64 1472.88 2431.8V2431.8C1362.14 2514.88 1209.86 2514.88 1099.12 2431.8V2431.8C1034.92 2383.64 954.617 2362.12 874.945 2371.73V2371.73C737.504 2388.31 605.619 2312.17 551.255 2184.85V2184.85C519.741 2111.05 460.952 2052.26 387.149 2020.74V2020.74C259.833 1966.38 183.69 1834.5 200.267 1697.05V1697.05C209.876 1617.38 188.358 1537.08 140.2 1472.88V1472.88C57.123 1362.14 57.123 1209.86 140.2 1099.12V1099.12C188.358 1034.92 209.876 954.617 200.267 874.944V874.944C183.69 737.503 259.833 605.619 387.149 551.255V551.255C460.952 519.741 519.741 460.952 551.255 387.149V387.149C605.619 259.833 737.503 183.689 874.945 200.266V200.266C954.617 209.876 1034.92 188.358 1099.12 140.199V140.199Z" fill={secondary || "white"} />
    
    {/* Bottom shape */}
    <path d="M9.64079 2475.19C-11.4654 2408.77 38.1096 2341 107.804 2341H2465.45C2534.77 2341 2584.3 2408.11 2563.87 2474.36L2526.17 2596.59C2520.06 2616.4 2520.07 2637.59 2526.2 2657.4L2563.7 2778.55C2584.21 2844.82 2534.67 2912 2465.3 2912H109.33C39.1923 2912 -10.4331 2843.43 11.4917 2776.8L48.896 2663.14C55.6655 2642.57 55.7792 2620.39 49.2211 2599.75L9.64079 2475.19Z" fill={primary || "#0E1B21"} />
    
    {/* Circle */}
    <circle cx="1290" cy="1287" r="929" fill="white" stroke={primary || "#0E1B21"} strokeWidth="84" />
    
    {/* Text "SCAN ME" */}
    <text fill={textColor || "white"} xmlSpace="preserve" style={{ whiteSpace: "pre" }} fontFamily="Arial" fontSize={fontSize || 300} fontWeight="bold" letterSpacing="0em">
      <tspan x="50%" textAnchor='middle' y="2730">{text}</tspan>
    </text>
  </g>
  
  {/* Clip path definition */}
  <defs>
    <clipPath id="clip0_175_7425">
      <rect width="2572" height="2912" fill="white" />
    </clipPath>
  </defs>


  
  {/* Image element */}
  <image href={url} x="718" y="718" width="1144" height="1144" />
</svg>


 

    </>
  )
}

export default IconFrame11