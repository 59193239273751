// import React, { useEffect } from "react";
// import { Link } from "react-router-dom";

// const BcardPreview = ({ formData }) => {
//   const {
//     firstName,
//     lastName,
//     profilePic,
//     job,
//     bio,
//     mobile,
//     phone,
//     fax,
//     email,
//     company,
//     website,
//     summary,
//     businessType,
//     address,
//     facebook,
//     linkedin,
//     google,
//     instagram,
//   } = formData;

//   useEffect(() => {
//     console.log("Vcard FORM DATA:-> ", formData);
//   }, [formData]);

//   return (
//     <div>
//       <div className="qr-p-v-card">
//         <div
//           style={{
//             background: formData?.gradientColor
//               ? `linear-gradient(to top, ${formData?.primaryColor}, ${formData?.gradientColor})`
//               : formData?.primaryColor,
//           }}
//           className=" d-flex justify-content-center align-items-center p-10 flex-column"
//         >
//           <img
//             className="w-100px h-100px mt-10 rounded-circle"
//             src={profilePic || "https://divsly.com/app/assets/media/avatars/300-23.jpg"}
//             alt="Avatar"
//           />
//           <div className="d-flex justify-content-center align-items-center flex-column text-center">
//             <div className="fs-1 mb-3 fw-bold text-white">
//               {(firstName && lastName)
//                 ? `${firstName + " "} ${lastName}` : firstName? firstName : lastName && lastName}
//             </div>
//             <p className="fs-4 text-white">{job || "Designer"}</p>
//           </div>
//         </div>
//         <div className="bg-light">
//           <div>
//             <p className="text-center  p-3 mb-3">
//               {bio || "No bio available."}
//             </p>
//           </div>
//           <div className="d-flex border-end p-3 mb-3">
//             <i className="fas fa-phone me-2"></i>
//             <span>{mobile || "11111111"}</span>
//           </div>
//           <div className="d-flex border-end p-3 mb-3">
//             <i className="fas fa-phone me-2"></i>
//             <span>{phone || "0000000"}</span>
//           </div>
//           <div className="d-flex border-end p-3 mb-3">
//             <i className="fas fa-envelope me-2"></i>
//             <span>{email || "Email"}</span>
//           </div>
//           <div className="d-flex border-end p-3 mb-3">
//             <i className="fas fa-briefcase me-2"></i>
//             <span>{company || "Company"}</span>
//           </div>
//           <div className="d-flex border-end p-3 mb-3">
//             <i className="fas fa-map-marker-alt me-2"></i>
//             <span>{address || "Address"}</span>
//           </div>
//           <div className="d-flex border-end p-3 mb-3">
//             <i className="fas fa-fax me-2"></i>
//             <span>{fax || "fax"}</span>
//           </div>
//           <div className="d-flex border-end p-3 mb-3">
//             <i className="fas fa-fax me-2"></i>
//             <span>{website || "website"}</span>
//           </div>
//           <div className="d-flex border-end p-3 mb-3">
//             <i className="fas fa-fax me-2"></i>
//             <span>{summary || "summary"}</span>
//           </div>
//           <div className="d-flex border-end p-3 mb-3">
//             <i className="fas fa-fax me-2"></i>
//             <span>{businessType || "businessType"}</span>
//           </div>
//           {/* social media */}
//           <div className="d-flex gap-4 mb-3 justify-content-center align-items-center">
//             <Link to={facebook || "#profile"} className="d-block">
//               <i class="fa-brands fa-facebook-f"></i>
//             </Link>
//             <Link to={instagram || "#profile"} className="d-block">
//               <i class="fa-brands fa-instagram"></i>
//             </Link>
//             <Link to={google || "#profile"} className="d-block">
//               <i class="fa-brands fa-google"></i>
//             </Link>
//             <Link to={linkedin || "#profile"} className="d-block">
//               <i class="fa-brands fa-linkedin"></i>
//             </Link>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default BcardPreview;




import React, { useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import FloatingBtn from "./floatingbutton/floatingBtn";

const BcardPreview = ({ formData }) => {
  const {
    firstName,
    lastName,
    profilePic,
    job,
    bio,
    mobile,
    phone,
    fax,
    email,
    company,
    website,
    summary,
    businessType,
    address,
    facebook,
    image,
    linkedin,
    google,
    instagram,
  } = formData;

  const vCardItems = useMemo(() => [
    // {
    //   text: "Send by Email",
    //   href: `mailto:${email || "#"}`,
    //   icon: "fa-solid fa-envelope",
    //   onClick: () => { console.log("Send mail to: " + email)},
    // },
    {
      text: "Save to My Phone",
      icon: "fa-solid fa-floppy-disk",
      onClick: () => {
        const blob = new Blob([formData?.vcard], { type: 'text/vcard' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `${formData?.firstName || 'contact'}.vcf`; // File name for the vCard
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      },
    },
  ], [formData]);

  return (
    <div className="position-relative onhvr-scroll-y">
      <div className="qr-p-v-card mx-auto bg-light pb-14 p-5 scroll-content"
        style={{
          height: "575px",
          maxWidth: " 900px"
        }}>
        <div
          style={{
            background: formData?.gradientColor
              ? `linear-gradient(to top, ${formData?.primaryColor}, ${formData?.gradientColor})`
              : formData?.primaryColor,
              paddingTop: '50px',
              marginTop: '70px'
          }}
          className=" d-flex justify-content-center align-items-center pb-4 px-4 rounded-3 flex-column position-relative"
        >
          <div className="px-10 position-absolute" style={{top:"-84px",}}>
            <img
              className="w-100px h-100px mt-10 border border-3 border-white rounded-circle"
              src={image || "https://divsly.com/app/assets/media/avatars/300-23.jpg"}
              alt="Avatar"
            />
          </div>
          <div className="d-flex justify-content-center align-items-center px-10 flex-column text-center">
          <div className="fs-1 mb-1 mt-2 fw-bold text-white" style={{ textShadow: "1px 1px 1px #000" }}>
  {((firstName || "Joy") + " " + (lastName || "Doe")).trim()}
</div>

            <p className="fs-5 text-gray-100">{job || "Designer"}</p>
          </div>
          <div>
            <p className="fs-6 text-center text-white mb-0 py-5" >
              {summary || "Creative Software Developer with 5 years of experience in building responsive and engaging websites. let's work together!"}
            </p>
          </div>
          <div className="btn-group row px-0 w-100 mt-5">
            <a href={`tel:${mobile}`} style={{color: formData?.primaryColor, borderRight: "1px solid", marginRight: "1px"}} className="col-4 btn btn-light d-flex justify-content-center align-items-center" >
              <i className="fas fa-phone" style={{color: formData?.primaryColor}} />
              <span>Call</span>
            </a>
            <a href={`mailto:${email}`} style={{color: formData?.primaryColor, marginRight: "1px"}} className="col-4 btn btn-light d-flex justify-content-center align-items-center" >
              <i className="fas fa-envelope" style={{color: formData?.primaryColor}} />
              <span>Mail</span>
            </a>
            <a href={formData?.location} target="_blank"  rel="noreferrer"  style={{color: formData?.primaryColor, borderLeft: "1px solid"}} className="col-4 btn btn-light d-flex justify-content-center align-items-center" >
              <i className="fas fa-location-dot" style={{color: formData?.primaryColor}} />
              <span>Maps</span>
            </a>
          </div>
        </div>
        <div className="bg-light position-relative mt-5">
          <div className="d-flex py-5 px-5 gap-5 align-items-center fs-6 p-3 mb-3">
            <span className="p-4 rounded-circle bg-white d-flex justify-content-center align-items-center"><i style={{color: formData?.primaryColor}} className="fas fa-phone fs-3"></i></span>
            <div>
            <p className="text-gray-600 mb-0 mt-1">Mobile</p>
            <span>{mobile || "11111111"}</span>
            </div>
          </div>
          <div className="d-flex py-5 px-5 gap-5 align-items-center fs-6 p-3 mb-3">
            <span className="p-4 rounded-circle bg-white d-flex justify-content-center align-items-center"><i style={{color: formData?.primaryColor}} className="fas fa-phone fs-3"></i></span>
            <div>
            <p className="text-gray-600 mb-0 mt-1">Phone</p>
            <span>{phone || "0000000"}</span>
            </div>
          </div>
          <div className="d-flex py-5 px-5 gap-5 align-items-center fs-6 p-3 mb-3">
          <span className="p-4 rounded-circle bg-white d-flex justify-content-center align-items-center"><i style={{color: formData?.primaryColor}} className="fas fa-envelope fs-3"></i></span>
            <div>
            <p className="text-gray-600 mb-0 mt-1">Email</p>
            <span>{email || "Email@email.com"}</span>
            </div>
          </div>
          <div className="d-flex py-5 px-5 gap-5 align-items-center fs-6 p-3 mb-3">
            <span className="p-4 rounded-circle bg-white d-flex justify-content-center align-items-center"><i style={{color: formData?.primaryColor}} className="fas fa-briefcase fs-3"></i></span>
            <div>
            <p className="text-gray-600 mb-0 mt-1">Company</p>
            <span>{company || "Company Name"}</span>
            </div>
          </div>
          <div className="d-flex py-5 px-5 gap-5 align-items-center fs-6 p-3 mb-3">
            <span className="p-4 rounded-circle bg-white d-flex justify-content-center align-items-center"><i style={{color: formData?.primaryColor}} className="fas fa-map-marker-alt fs-3"></i></span>
            <div>
            <p className="text-gray-600 mb-0 mt-1">Address</p>
            <span>{address || "123, street, city"}</span>
            </div>
          </div>
          <div className="d-flex py-5 px-5 gap-5 align-items-center fs-6 p-3 mb-3">
            <span className="p-4 rounded-circle bg-white d-flex justify-content-center align-items-center"><i style={{color: formData?.primaryColor}} className="fas fa-fax fs-3"></i></span>
            <div>
            <p className="text-gray-600 mb-0 mt-1">Fax</p>
            <span>{fax || "fax"}</span>
                </div>
          </div>
          <div className="d-flex py-5 px-5 gap-5 align-items-center fs-6 p-3 mb-3">
            <span className="p-4 rounded-circle bg-white d-flex justify-content-center align-items-center"><i style={{color: formData?.primaryColor}} className="fas fa-globe fs-3"></i></span>
            <div>
            <p className="text-gray-600 mb-0 mt-1">Website</p>
            <span>{website || "website"}</span>
                </div>
          </div>
          <div className="d-flex py-5 px-5 gap-5 align-items-center fs-6 p-3 mb-3">
            <span className="p-4 rounded-circle bg-white d-flex justify-content-center align-items-center"><i style={{color: formData?.primaryColor}} className="fas fa-fax fs-3"></i></span>
            <div>
            <p className="text-gray-600 mb-0 mt-1">Business Type</p>
            <span>{businessType || "businessType"}</span>
                </div>
          </div>
          {/* social media */}
          <div className="d-flex gap-6 mb-3 py-4 justify-content-center align-items-center">
            <span className="h-40px w-40px rounded-circle d-flex justify-content-center align-items-center" style={{backgroundColor: "#3F51B5"}}><Link to={facebook || "#profile"} className="d-flex">
              <i class="fa-brands fs-2 fa-facebook-f text-white"></i>
            </Link>
            </span>
            <span className="h-40px w-40px rounded-circle d-flex justify-content-center align-items-center" style={{background: 'radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%)'}}><Link to={instagram || "#profile"} className="d-flex">
              <i class="fa-brands fs-2 fa-instagram text-white"></i>
            </Link>
            </span>
            <span className="h-40px w-40px rounded-circle d-flex justify-content-center align-items-center" style={{backgroundColor: "#e84d40"}}><Link to={google || "#profile"} className="d-flex">
              <i class="fa-brands fs-2 fa-google text-white"></i>
            </Link>
            </span>
            <span className="h-40px w-40px rounded-circle d-flex justify-content-center align-items-center" style={{backgroundColor:"#0078D4"}}><Link to={linkedin || "#profile"} className="d-flex">
              <i class="fa-brands fs-2 fa-linkedin-in text-white"></i>
            </Link>
            </span>
          </div>

        </div>
      </div>
      {/* floating button */}
      <FloatingBtn type={"vCard"} items={vCardItems} />
    </div>
  );
};

export default BcardPreview;
