import React from 'react'

const IconFrame5 = ({ primary,  secondary, fontSize, text, textColor, type, url, width, height, minFontSize, maxFontSize }) => {
  return (
    <>
 

 
<svg width={width || 1316} height={height || 2280} viewBox="0 0 1316 2280" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_177_26436)">
<path d="M1120.75 202.459L949.4 263.098C956.651 282.953 960.949 303.882 960.949 325.615C960.949 424.622 880.646 506.189 780.2 506.189C700.703 506.189 633.023 454.137 608.851 383.034L0 595.806H1316C1316 451.722 1245.1 317.297 1120.75 202.459Z" fill={secondary || "#0E1B21"}/>
<path d="M652.897 324.538C652.897 273.29 684.32 228.482 728.097 208.627C702.852 139.939 634.097 70.9833 604.823 47.1034C593.275 37.713 592.2 21.0772 601.6 9.54048C611 -1.99628 627.652 -3.06998 639.2 6.31939C644.303 10.612 746.897 97.2769 780.2 197.358C851.103 197.358 907.503 254.776 907.503 324.538C907.503 394.299 850.029 451.719 780.2 451.719C710.372 451.719 652.897 394.567 652.897 324.538Z" fill={secondary || "#0E1B21"}/>
<rect y="2002.03" width="1316" height="277.97" fill={primary || "#0E1B21"}/>


<text fill={textColor || "white"} xmlSpace="preserve" style={{ whiteSpace: "pre" }} fontFamily="Arial" fontSize={fontSize || 170} fontWeight="bold" letterSpacing="0em">
      <tspan x="50%" textAnchor='middle' y="2199.45">{text}</tspan>
    </text>

<rect y="649.242" width="1316" height="1316" rx="50" fill="white"/>
</g>
<defs>
<clipPath id="clip0_177_26436">
<rect width="1316" height="2280" fill="white"/>
</clipPath>
</defs>

<image href={url} x="86" y="735.24" width="1144" height="1144" />
</svg>


    </>
  )
}

export default IconFrame5