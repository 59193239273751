import React from 'react'

const IconFrame2 = ({ primary,fontSize, text, textColor, type, url, width, height, minFontSize, maxFontSize }) => {
  return (
    <>
   <svg width={width || 1400} height={height || 1900} viewBox="0 0 1400 1900" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clipPath="url(#clip0_175_26)">
    {/* Top rectangle */}
    <rect width={1400} height={443} rx={114} fill={primary || "#0E1B21"} />
    
    {/* Bottom background rectangle */}
    <rect y={500} width={1400} height={1400} rx={114} fill={primary || "#0E1B21"} />
    
    {/* White box inside the bottom rectangle */}
    <rect x={63} y={563} width={1274} height={1274} rx={63} fill="white" />

    {/* Text inside the top rectangle */}
    <text
      fill={textColor || "white"}
      fontFamily="Arial"
      fontSize={fontSize || 210}
      fontWeight="bold"
      letterSpacing="0em"
      x="50%"  
      y="294.303"
      textAnchor="middle"
    >
      {text}
    </text>
  </g>

  {/* Clip path definition */}
  <defs>
    <clipPath id="clip0_175_26">
      <rect width="1400" height="1900" fill="white" />
    </clipPath>
  </defs>

  {/* Image positioned similarly to the first SVG */}
  <image href={url} x="129" y="629" width="1142" height="1142" />
</svg>


    </>
  )
}

export default IconFrame2