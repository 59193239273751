import React, { useContext, useState } from "react";
import { OffcanvasHeader } from "react-bootstrap";
import { Link } from "react-router-dom";
import ShortLinksAPI from "../../../services/http/shortLinks";
import CSVReader from "react-csv-reader";
import {
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Spinner,
  Tooltip,
  Progress,
} from "reactstrap";
import useCustomLinks from "../../../hooks/useCustomLinks";
import { AuthContxt } from "../../../store/authContxt";
import { ShortLinkContxt } from "../shortLinkContxt";
import DrawerWrapper from "../../wrapper/drawerWrapper";

const AddBulkShortLinks = ({ addBulk, addBulkClose }) => {
  const { getShortLinks } = useContext(ShortLinkContxt);
  const { quota, fetchQuota } = useContext(AuthContxt);
  const auth_token = localStorage.getItem("auth_token");
  const BASE_URL = process.env.REACT_APP_QR_BASE_URL;
  const [bulkUploadig, setBulkUploading] = useState(false);
  const fileTypes = "text/xlsx,text/xls,text/csv";
  const [tagInput, setTagInput] = useState("");
  const [domain, setDomain] = useState(BASE_URL);
  const [progress, setProgress] = useState(0);

  const customLinks = useCustomLinks();
  const [file, setFile] = useState(null);
  const [fileInfo, setFileInfo] = useState(null);

  const isLimitReached = quota?.shortlink === 0 || false;
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => {
    if (isLimitReached) {
      setTooltipOpen(!tooltipOpen);
    }
  };

  const [message, setMessage] = useState("");
  const messageHandler = (msg) => {
    setMessage(msg);
    setTimeout(() => {
      setMessage("");
    }, 5 * 1000);
  };

  const handleFileUpload = (data, fileInfo) => {
    setFileInfo(fileInfo);
    const newData = [];
    let nonHttps = false

    data.forEach((link) => {
      if (link.destinationUrl && !/^https:\/\//i.test(link.destinationUrl)) {
        nonHttps = true
      } else {
        newData.push(link)
      }
    });
    if (nonHttps) {
      messageHandler("CSV file contains non-HTTPS URLs. Please correct and re-upload.")
      clearFileHandler();
      return
    }
    setFile(newData);
  };

  const resetHandler = () => {
    clearFileHandler();
    setTagInput("");
    setDomain(BASE_URL);
    addBulkClose();
  };
  const clearFileHandler = () => {
    setFile(null);
    setFileInfo(null);
    setTagInput("");
    const el = document.getElementsByClassName("csv-input")[0];
    if (el) {
      el.value = "";
    }
  };

  const urlBuilder = (customLink) => {
    const { domain, subDomain, spare } = customLink;
    try {
      const url = new URL(domain);
      return spare === "No" ? `${subDomain}.${url.host}` : url.host;
    } catch (e) {
      console.log("Error while creating url : ", e);
      return domain;
    }
  };

  const incrementProgress = (target) => {
    const interval = setInterval(() => {
      setProgress((prev) => {
        if (prev >= target) {
          clearInterval(interval);
          return target;
        }
        return prev + 1;
      });
    }, 100);
  };

//   const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

// const uploadHandler = async () => {
//   if (quota?.shortlink === 0) {
//     messageHandler("Link Limit Exceeded for your current plan. Please upgrade!");
//     return;
//   }

//   if (file?.length > quota?.bulkShortening) {
//     messageHandler("Bulk Link Limit Exceeded for your current plan. Please upgrade!");
//     return;
//   }

//   let importData = file;

//   if (quota && importData?.length > quota?.shortlink) {
//     const remainingLinks = quota?.shortlink;
//     importData = importData?.slice(0, remainingLinks);
//     messageHandler(`Only ${remainingLinks} links will be created due to the remaining limit. Please upgrade!`);
//   }

//   console.log("Total links to upload:", importData.length);

//   const chunkSize = 10; // Adjusted chunk size for better handling
//   const chunks = [];
//   for (let i = 0; i < importData.length; i += chunkSize) {
//     chunks.push(importData.slice(i, i + chunkSize));
//   }

//   console.log("Total chunks created:", chunks.length);

//   setBulkUploading(true);
//   setProgress(0);

//   const maxRetries = 3;

//   for (let i = 0; i < chunks.length; i++) {
//     const formData = {
//       importData: chunks[i],
//       tags: tagInput,
//       brandedDomain: domain,
//     };

//     let success = false;
//     let attempt = 0;

//     while (!success && attempt < maxRetries) {
//       try {
//         console.log(`Uploading chunk ${i + 1} of ${chunks.length}, attempt ${attempt + 1}`);
//         await ShortLinksAPI.importShortLinks(auth_token, formData, { timeout: 10000 }); // Adjusted timeout
//         incrementProgress(((i + 1) / chunks.length) * 100);
//         console.log(`Successfully uploaded chunk ${i + 1}`);
//         success = true;
//       } catch (error) {
//         attempt++;
//         console.error(`Failed to upload chunk ${i + 1}, attempt ${attempt}:`, error.message);

//         if (attempt >= maxRetries) {
//           messageHandler(error?.response?.data?.data?.message || "Something went wrong");
//           break;
//         }
//       }
//     }

//     if (!success) {
//       console.error(`Failed to upload chunk ${i + 1} after ${maxRetries} attempts`);
//       break;
//     }

//     await delay(2000); 
//   }

//   resetHandler();
//   getShortLinks();
//   fetchQuota();
//   setBulkUploading(false);
//   console.log("Upload process completed");
// };

const uploadHandler = async () => {
  if (quota?.shortlink === 0) {
    messageHandler(
      "Link Limit Exceeded for your current plan. Please upgrade!"
    );
    return;
  }

  if (file?.length > quota?.bulkShortening) {
    messageHandler(
      "Bulk Link Limit Exceeded for your current plan. Please upgrade!"
    );
    return;
  }

  let importData = file;

  if (quota && importData?.length > quota?.shortlink) {
    const remainingLinks = quota?.shortlink;
    importData = importData?.slice(0, remainingLinks);
    messageHandler(
      `Only ${remainingLinks} link will create due to remaining limit. Please upgrade!`
    );
  }

  const chunkSize = 5;
  const chunks = [];
  for (let i = 0; i < importData.length; i += chunkSize) {
    chunks.push(importData.slice(i, i + chunkSize));
  }

  setBulkUploading(true);
  setProgress(0);

  for (let i = 0; i < chunks.length; i++) {
    const formData = {
      importData: chunks[i],
      tags: tagInput,
      brandedDomain: domain,
    };
    try {
      await ShortLinksAPI.importShortLinks(auth_token, formData);
      incrementProgress(((i + 1) / chunks.length) * 100);
    } catch (error) {
      messageHandler(
        error?.response?.data?.data?.message || "Something went wrong"
      );
      break;
    }
  }
  resetHandler();
  getShortLinks();
  fetchQuota();
  setBulkUploading(false);
};


  const header = (
    <OffcanvasHeader className="px-lg-20 border-bottom" closeButton>
      <h2 className="fw-bold my-2 fs-2x">Add Bulk Links</h2>
    </OffcanvasHeader>
  );

  return (
    <DrawerWrapper open={addBulk} toggle={resetHandler} header={header}>
      <div className="modal-body add_new_link_body">
      <p> Easily import your file containing multiple links for shortening, Just upload your document, and our tool will handle the rest!</p>
        <div className="me-n7 pe-7">
          <div className="d-flex mt-10 mb-7 gap-5 align-items-end">
            <div>
            <div className="fv-row mb-4">
            <label className="fs-5 fw-semibold text-grey">Select Your Branded Domain</label>
          </div>
          <div>
            <UncontrolledDropdown
              style={{
                border: "1px solid #e7e3f1",
                width: "200px",
              }}
              className="rounded-1"
              direction="down"
            >
              <DropdownToggle caret color="transparent" className="p-3 px-4 w-100 d-flex justify-content-between align-items-center">
                {domain}
              </DropdownToggle>
              <DropdownMenu
                isOpen={true}
                style={{
                  zIndex: 99999999,
                }}
              >
                {customLinks &&
                  customLinks.length > 0 &&
                  customLinks?.map((dom, index) => (
                    <DropdownItem
                      key={index}
                      onClick={() => setDomain(urlBuilder(dom))}
                    >
                      {urlBuilder(dom)}
                    </DropdownItem>
                  ))}
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
            </div>
            <div className="pb-3">
              
            <Link
                target="_blank"
                to="/custom-links"
                download
                className="text-info"
              >
                Add Branded Domain
              </Link>
            </div>
          </div>
          <div
            className="drop-container dropzone py-15 dropzone d-flex justify-content-center align-items-center"
            id="dropContainer"
          >
            <div className="d-flex flex-column justify-content-center align-item-center">
              <i class="fa-solid fa-file-import text-info opacity-75 fs-3x mb-5"></i>
              <p class="fs-3">Drag &amp; Drop CSV File Here</p>

              <div className="d-flex fs-3">
                <span className="fs-4 me-2">or</span>{" "}
                <span className="text-info cursor-pointer font-weight-bold fs-3 me-2">
                  Browse File
                </span>{" "}
                from device.
              </div>
              <CSVReader
                inputId="csvReader"
                onFileLoaded={handleFileUpload}
                parserOptions={{
                  header: true,
                  dynamicTyping: true,
                  skipEmptyLines: true,
                }}
                accept={fileTypes}
              />
            </div>
          </div>
          <div className="uploading-animation" id="uploadAnimation">
            Uploading...
          </div>

          <ul className="file-list mt-6" id="fileList"></ul>
          {fileInfo ? (
            <div className="fv-row mb-3 mt-10">
              <label className="fs-6 fw-semibold mb-2">{fileInfo?.name}</label>
              <button
                className="btn btn-info rounded-1"
                style={{
                  marginLeft: "10px",
                }}
                onClick={clearFileHandler}
              >
                <span className="indicator-label">Clear</span>
              </button>
            </div>
          ) : null}

          {file ? (
            <div id="addbulktag" style={{ display: "block" }}>
              <div className="fv-row mb-3 mt-10">
                <label className="fs-6 fw-semibold mb-2">
                  Tag for your Links{" "}
                  <span className="fw-light text-grey"> (optional) </span>
                </label>
              </div>
              <div className="fv-row mb-7">
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    alignItems: "center",
                  }}
                  id="tagList"
                ></div>
                <input
                  type="text"
                  value={tagInput}
                  onChange={(e) => {
                    setTagInput(e.target.value);
                  }}
                  className="form-control h-50px"
                  id="tagInput"
                  placeholder="Add tags..."
                />

                <input type="hidden" id="tagsData" name="tags" />
              </div>
              <p
                style={{ colod: "red", fontSize: "14px", marginTop: "5px" }}
                id="errorMessageTags"
              ></p>
            </div>
          ) : null}

          <div className="mt-7">
            
            <p>
              <Link
                target="_blank"
                to="/short-links/Bulk-link-import-template.csv"
                download
                className="text-info"
              >
                <i className="fa-solid fa-download me-3 text-info"></i>{" "}
                Download sample.CSV Template
              </Link>
            </p>
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-end mb-3">
        {message ? (
          <span className="error-text text-danger">{message}</span>
        ) : null}
      </div>

      {bulkUploadig ? (
        <div className="mt-10">
          <Progress color="success" value={progress}>{progress.toFixed(2)}%</Progress>
        </div>
      ) : null}

      <div className="modal-footer flex-center mt-10">
        <div className="w-100" onMouseOver={toggle} onMouseLeave={toggle} id="shortlinkForm">
          <button
            type="button"
            id="addBulkBtn"
            className="btn w-100 h-55px btn-info rounded-1 "
            onClick={uploadHandler}
            disabled={!file || bulkUploadig || isLimitReached}
          >
            <span className="indicator-label">
              {bulkUploadig ? "Uploading..." : "Upload"}
            </span>
            {bulkUploadig ? (
              <Spinner
                size="sm"
                style={{
                  color: "#fff",
                  position: "relative",
                  bottom: "0px",
                  marginLeft: "20px",
                }}
              />
            ) : null}
          </button>
        </div>
        <Tooltip
          placement={"top"}
          isOpen={tooltipOpen}
          target={"shortlinkForm"}
          toggle={toggle}
        >
          {`Your Monthly Shortlink Limit Reached. Please Upgrade your Plan`}
        </Tooltip>
      </div>
    </DrawerWrapper>
  );
};

export default AddBulkShortLinks;