import React from 'react'

const IconFrame5 = ({ primary, secondary, fontSize, text, textColor, type, url, width, height, minFontSize, maxFontSize }) => {
  return (
    <>
  <svg width={width || 1769} height={height || 2187} viewBox="0 0 1769 2187" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clipPath="url(#clip0_175_50)">
    {/* Background rectangle */}
    <rect y="188" width="1769" height="484" rx="34" fill={primary || "#0E1B21"} />
    
    {/* Main shape rectangle */}
    <rect x="97" y="671" width="1575" height="1327" fill={secondary || "white"} />
    
    {/* Horizontal lines */}
    <path d="M298 904V790H412" stroke="white" strokeWidth={21} strokeLinecap="round" strokeLinejoin="round" />
    <path d="M298 1848V1962H412" stroke="white" strokeWidth={21} strokeLinecap="round" strokeLinejoin="round" />
    <path d="M1470 904V790H1356" stroke="white" strokeWidth={21} strokeLinecap="round" strokeLinejoin="round" />
    <path d="M1470 1848V1962H1356" stroke="white" strokeWidth={21} strokeLinecap="round" strokeLinejoin="round" />
    
    {/* Text "SCAN ME" */}
    <text fill={textColor || "white"} 
    xmlSpace="preserve" 
    style={{ whiteSpace: "pre" }} 
    fontFamily="Arial" 
    fontSize={fontSize || 225} 
    fontWeight="bold" 
    letterSpacing="0em">
      <tspan x="50%" textAnchor="middle" y="508.503">{text}</tspan>
    </text>
    
    {/* Bottom decorative shape */}
    <path d="M1672 1998C1672 2022.82 1651.63 2047.4 1612.06 2070.33C1572.48 2093.26 1514.47 2114.09 1441.35 2131.64C1368.22 2149.19 1281.41 2163.12 1185.86 2172.61C1090.32 2182.11 987.916 2187 884.5 2187C781.084 2187 678.681 2182.11 583.137 2172.61C487.593 2163.12 400.78 2149.19 327.653 2131.64C254.527 2114.09 196.52 2093.26 156.945 2070.33C117.369 2047.4 97 2022.82 97 1998L884.5 1998H1672Z" fill={secondary || "white"} />
    
    {/* Top decorative shape */}
    <path d="M97 189C97 164.18 117.369 139.603 156.945 116.673C196.52 93.7423 254.527 72.9071 327.653 55.3568C400.78 37.8065 487.593 23.8849 583.137 14.3868C678.681 4.88863 781.084 -1.08491e-06 884.5 0C987.916 1.08491e-06 1090.32 4.88863 1185.86 14.3868C1281.41 23.8849 1368.22 37.8066 1441.35 55.3568C1514.47 72.9071 1572.48 93.7423 1612.06 116.673C1651.63 139.603 1672 164.18 1672 189L884.5 189H97Z" fill={secondary || "white"} />
  </g>

  {/* Clip path definition */}
  <defs>
    <clipPath id="clip0_175_50">
      <rect width="1769" height="2187" fill="white" />
    </clipPath>
  </defs>
  
  {/* Image element (positioning unchanged) */}
  <image href={url} x="313" y="805" width="1142" height="1142" />
</svg>


 

    </>
  )
}

export default IconFrame5