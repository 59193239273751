import React from 'react';

const Slide = ({ slide, loading, onSkip, onCreate }) => (
    <div key={slide.key} className="small-card bg-light text-start p-5 rounded">
        <img src={slide.image} alt={`${slide.feature}-image`} className="shortlinkarrow_image" />
        <h2 className="mt-5 fs-2x px-5 py-3 mb-4">{slide.title}</h2>
        <p className="px-5 text-gray-600">{slide.description}</p>
        <div className="button-group d-flex justify-content-between mt-5">
            <button
                className="btn btn-text bg-none text-hover-info ps-5 me-4"
                disabled={loading}
                onClick={() => onSkip(slide.key)}
            >
                Skip
            </button>
            <button
                className={`btn ${slide.btn} w-50 rounded-1`}
                disabled={loading}
                onClick={() => onCreate(slide.feature)}
            >
                Create
            </button>
        </div>
    </div>
);

export default Slide;