import React, { useState, useContext, useCallback, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Table,
  Card,
  CardBody,
  FormGroup,
  Input,
  Label,
  Col,
  Row,
  InputGroup,
  InputGroupText,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardTitle,
  CardText,
} from "reactstrap";
import Sidebar from "../common/sidebar";
import EmptyState from "../../assets/media/empty-state-link-list.png";
import { EmailCampaignContxt } from "./emailCampaignContxt";
import { AuthContxt } from "../../store/authContxt";
import NotSearchFound from "../common/emptyScreens/notSearchFound";
import NotFound from "../common/emptyScreens/notFound";
import { useNavigate } from "react-router-dom";
import EmailCampaignAPI from "../../services/http/emailCampaign";
import IntegrationAPI from "../../services/http/integration";
import _debounce from "lodash/debounce";
import capitalizeFirstLetter from "../../utils/captilizeFirstLetter.js";
import _capitalize from "lodash/capitalize";
import Analytics from "../common/analytics";
import SharedApi from "../../services/http/shared";

const EmailCampaignsMain = () => {
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState(false);
  const [searchHide, setSearchHidee] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [formOpen, setFormOpen] = useState(true);
  const [filtersLoading, setFiltersLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProvider, setSelectedProvider] = useState("");
  const [campaignId, setCampaignId] = useState(0);
  const [providers, setProviders] = useState([]);
  const [isAnalyticsOpen, setIsAnalyticsOpen] = useState(false);
  const [analytics, setAnalytics] = useState({});
  const [providerAnalytics, setProviderAnalytics] = useState({});
  const [analyticsLoading, setAnalyticsLoading] = useState(false);
  const [analyticsCampaignId, setAnalyticsCampaignId] = useState("");
  const [analyticsLink, setAnalyticsLink] = useState("");
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [campaignToDelete, setCampaignToDelete] = useState(null);
  const [sendLoading, setSendLoading] = useState(false);

  const toggleConfirmModal = () => setIsConfirmModalOpen(!isConfirmModalOpen);

  const confirmDelete = () => {
    if (campaignToDelete) {
      handleDelete(campaignToDelete);
      setCampaignToDelete(null);
    }
    toggleConfirmModal();
  };

  const openConfirmModal = (campaignId) => {
    setCampaignToDelete(campaignId);
    toggleConfirmModal();
  };

  useEffect(() => {
    const fetchProviders = async () => {
      try {
        const auth_token = localStorage.getItem("auth_token");
        const response = await IntegrationAPI.getAllEmailIntegrations(
          auth_token
        );
        setProviders(response.data.integrations || []);
      } catch (error) {
        console.error("Error fetching providers:", error);
      }
    };

    fetchProviders();
  }, []);

  const toggleModal = (id = 0) => {
    setIsModalOpen(!isModalOpen);
    setCampaignId(id);
  };

  const handleProviderChange = (e) => {
    setSelectedProvider(e.target.value);
  };

  const {
    emailCampaigns,
    setEmailCampaigns,
    getEmailCampaigns,
    loading,
    setLoading,
    notLinksScreen,
    setNotLinksScreen,
    search,
    setSearch,
    filters,
    setFilters,
    status,
    setStatus,
  } = useContext(EmailCampaignContxt);

  // console.log(emailCampaigns, loading, notLinksScreen);

  const toggleIcon = () => {
    setIsActive(!isActive);
  };

  const toggleSearch = () => {
    setSearchHidee(!searchHide);
  };

  const toggleCanvas = () => {
    setIsOpen(!isOpen);
  };
  const toggleForm = () => {
    setFormOpen(!formOpen);
  };

  const addNewEmailCampaign = () => {
    navigate("/createemailcampaign");
  };

  const notLinksScreenMapper = {
    searchFound: <NotSearchFound type={"emailCampaign"} />,
    notFound: (
      <NotFound
        isLimitReached={false}
        handler={() => addNewEmailCampaign()}
        type={"emailCampaign"}
      />
    ),
  };

  const handleClick = async (e) => {
    e.preventDefault();
    setSendLoading(true);
    try {
      await handleSend();
    } catch (err) {
      console.log("some error occured", err);
    } finally {
      setSendLoading(false);
    }
  };

  const handleSend = async () => {
    const auth_token = localStorage.getItem("auth_token");
    if (!selectedProvider) {
      return;
    }
    try {
      EmailCampaignAPI.sendEmailCampaign2(auth_token, campaignId, {
        provider: selectedProvider,
      })
        .then(({ data }) => {
          toggleModal(0);
          getEmailCampaigns();
        })
        .catch((error) => {
          console.log("Error while fetching shortlinks", error);
          toggleModal(0);
          getEmailCampaigns();
        });
    } finally {
      setSendLoading(false);
    }
  };

  const filterEmailCampaigns = async (params) => {
    try {
      const auth_token = localStorage.getItem("auth_token");
      setFiltersLoading(true);
      EmailCampaignAPI.getAllEmailCampaigns(auth_token, params)
        .then(({ data }) => {
          setEmailCampaigns(data.resultData);
          data.resultData.length < 1 && setNotLinksScreen("notFound");
        })
        .catch((error) => {
          console.log("Error while fetching shortlinks", error);
        })
        .finally(() => {
          setFiltersLoading(false);
        });
    } catch (e) {
      console.log("Error while fetching filtered data : ", e.message);
    }
  };

  const handleDelete = (id) => {
    try {
      const auth_token = localStorage.getItem("auth_token");
      EmailCampaignAPI.deleteEmailCampaign(auth_token, id)
        .then(({ data }) => {
          getEmailCampaigns();
        })
        .catch((error) => {
          console.log("Error while fetching shortlinks", error);
        })
        .finally(() => {});
    } catch (e) {
      console.log("Error while deleting data : ", e.message);
    }
  };

  const handleSearch = useCallback(
    _debounce((filters) => filterEmailCampaigns(filters), 500),
    []
  );

  const searchHandler = (search) => {
    const filter = { ...filters, search };
    handleSearch(filter);
    setSearch(search);
    setFilters(filter);
  };

  const statusHandler = (status) => {
    let filter = { ...filters };
    if (filter.status && filter.status === status) {
      delete filter.status;
      setStatus("All Statuses");
    } else {
      filter = { ...filters, status };
      setStatus(status);
    }
    handleSearch(filter);
    setFilters(filter);
  };

  const toggleOffcanvas = (emailCampaignId) => {
    setIsAnalyticsOpen(!isAnalyticsOpen);
    if (!isAnalyticsOpen) {
      try {
        setAnalyticsLoading(true);
        setAnalyticsCampaignId(emailCampaignId);
        fetchCampaignAnalytics(emailCampaignId);
        const auth_token = localStorage.getItem("auth_token");
        EmailCampaignAPI.getReports(auth_token, emailCampaignId)
          .then(({ data }) => {
            setProviderAnalytics(data.resultData);
          })
          .catch((error) => {
            console.log("Error while fetching shortlinks", error);
          })
          .finally(() => {
            setAnalyticsLoading(false);
          });
      } catch (err) {
        console.log("err", err);
        setAnalyticsLoading(false);
      }
    }
  };

  const fetchAnalytics = (
    startDate = new Date().setDate(new Date().getDate() - 30),
    endDate = new Date()
  ) => {
    fetchCampaignAnalytics(analyticsCampaignId, startDate, endDate);
  };

  const fetchCampaignAnalytics = (
    campaignId,
    startDate = new Date().setDate(new Date().getDate() - 30),
    endDate = new Date()
  ) => {
    setAnalyticsLoading(true);
    const auth_token = localStorage.getItem("auth_token");
    SharedApi.getCampaignAnalytics(auth_token, {
      source: campaignId + "",
      startDate,
      endDate,
    })
      .then(({ data }) => {
        const result = data?.resultData;
        for (const shortlink in result) {
          if (result.hasOwnProperty(shortlink)) {
            setAnalyticsLink(shortlink);
            setAnalytics(result[shortlink]);
            break;
          }
        }
      })
      .catch((error) => {
        setAnalyticsLoading(false);
      })
      .finally(() => {
        setAnalyticsLoading(false);
      });
  };

  return (
    <div>
      <div className="w-full d-flex">
        <div className="mt-md-10 w-[280px] me-md-7 mt-xs-0 me-xs-0">
          <Sidebar />
        </div>
        <div className="customDashboardSize">
          <div className="d-flex flex-column w-100 flex-root app-root">
            <div className="app-page flex-column flex-column-fluid">
              <div className="app-wrapper flex-column flex-row-fluid pe-3 pe-lg-0 me-lg-10 mt-lg-10 mt-0 position-relative">
                <div className="app-main flex-column flex-row-fluid">
                  <Card className="card card-flush">
                    <div className="px-6 px-lg-10 py-lg-10 py-5 d-flex items-center justify-content-between w-100 align-items-center">
                      <h2 className="me-15 fs-2x">Email Campaigns</h2>
                      <div className="card-toolbar justify-content-center align-items-center gap-5 me-0 mb-0 h-10 cardToolbarIcons">
                        {filtersLoading ? <Spinner /> : null}
                        <InputGroup className="mb-0 w-auto">
                          <Input
                            placeholder="Search..."
                            value={search}
                            onChange={(e) => {
                              searchHandler(e.target.value);
                            }}
                            className={`headerinputsearch fw-normal form-control ${
                              searchHide ? "" : "d-none"
                            }`}
                          />
                          <InputGroupText
                            className="btn btn-icon rounded-1 bg-light-info me-2"
                            onClick={toggleSearch}
                          >
                            <i className="fa-solid fa-magnifying-glass fs-3"></i>
                          </InputGroupText>
                        </InputGroup>

                        <UncontrolledDropdown className="d-inline nospce statusbutton">
                          <DropdownToggle
                            id="dropdown-autoclose-false"
                            className="btn btn-icon bg-light-info rounded-1"
                            title="Sort"
                            caret
                          >
                            {capitalizeFirstLetter(status.toLowerCase())}
                          </DropdownToggle>
                          <DropdownMenu className="py-3">
                            <DropdownItem
                              onClick={() => statusHandler("DRAFT")}
                              active={status === "DRAFT" ? true : false}
                            >
                              <div
                                className={`d-flex justify-content-between align-items-center cursor-pointer ${
                                  status === "DRAFT" ? "active" : ""
                                }`}
                              >
                                <div className="form-check form-check-custom form-check-info form-check-solid d-flex justify-content-between w-100">
                                  <label className="form-check-label text-dark me-3">
                                    Draft
                                  </label>
                                </div>
                              </div>
                            </DropdownItem>
                            <DropdownItem
                              onClick={() => statusHandler("SENT")}
                              active={status === "SENT" ? true : false}
                            >
                              <div
                                className={`d-flex justify-content-between align-items-center cursor-pointer ${
                                  status === "SENT" ? "active" : ""
                                }`}
                              >
                                <div className="form-check form-check-custom form-check-info form-check-solid d-flex justify-content-between w-100">
                                  <label className="form-check-label text-dark me-3">
                                    Sent
                                  </label>
                                </div>
                              </div>
                            </DropdownItem>
                            <DropdownItem
                              onClick={() => statusHandler("SCHEDULED")}
                              active={status === "SCHEDULED" ? true : false}
                            >
                              <div
                                className={`d-flex justify-content-between align-items-center cursor-pointer ${
                                  status === "SCHEDULED" ? "active" : ""
                                }`}
                              >
                                <div className="form-check form-check-custom form-check-info form-check-solid d-flex justify-content-between w-100">
                                  <label className="form-check-label text-dark me-3">
                                    Scheduled
                                  </label>
                                </div>
                              </div>
                            </DropdownItem>
                            <DropdownItem
                              onClick={() => statusHandler("RUNNING")}
                              active={status === "RUNNING" ? true : false}
                            >
                              <div
                                className={`d-flex justify-content-between align-items-center cursor-pointer ${
                                  status === "RUNNING" ? "active" : ""
                                }`}
                              >
                                <div className="form-check form-check-custom form-check-info form-check-solid d-flex justify-content-between w-100">
                                  <label className="form-check-label text-dark me-3">
                                    Running
                                  </label>
                                </div>
                              </div>
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>

                        <Link to="/createemailcampaign">
                          <button
                            type="button"
                            className="btn btn-icon rounded-1 btn-info px-4"
                            color="danger"
                          >
                            <i className="fa-solid fa-plus text-white fs-3"></i>
                          </button>
                        </Link>
                      </div>
                    </div>
                    <CardBody className="pt-0">
                      {loading ? (
                        <div
                          style={{
                            height: "70vh",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Spinner />
                        </div>
                      ) : (
                        <Table striped>
                          <thead className="table-border">
                            <tr className="fs-6 fw-semibold gs-0 table-style text-gray-600 text-start">
                              <th className="w-10px ps-6 pe-2 py-5">
                                <input
                                  type="checkbox"
                                  className="absolute form-check-input cursor-pointer left-4 top-1/2 -mt-2 h-4 w-4 rounded-1 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                />
                              </th>
                              <th className="min-w-200px py-5">
                                Campaign Name
                              </th>

                              <th className="min-w-150px py-5">Recepients</th>
                              <th className="min-w-150px py-5">Sender</th>
                              <th className="min-w-150px py-5">Status</th>

                              <th className="min-w-50px py-5">Actions</th>
                            </tr>
                          </thead>
                          <tbody className="fw-semibold text-gray-600">
                            {emailCampaigns.length > 0 ? (
                              emailCampaigns?.map((emailCampaign, index) => {
                                return (
                                  <tr className="link-row border border-right-0 py-3 border-top-0 border-left-0">
                                    <td className="ps-6 py-5">
                                      <input
                                        type="checkbox"
                                        className="absolute form-check-input cursor-pointer left-4 top-1/2 -mt-2 h-4 w-4 rounded-1 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                      />
                                    </td>
                                    <td className=" py-5">
                                      <div className="d-flex justify-content-start align-items-center">
                                        {emailCampaign.name}
                                      </div>
                                    </td>

                                    <td className="text-start pe-0 py-5">
                                      {emailCampaign.contactCount
                                        ? emailCampaign.contactCount
                                        : emailCampaign.recipients
                                        ? JSON.parse(emailCampaign.recipients)
                                            .length
                                        : 0}
                                    </td>
                                    <td className="text-start pe-0 py-5">
                                      {emailCampaign.emailProvider
                                        ? `${_capitalize(
                                            emailCampaign.emailProvider.name
                                          )}`
                                        : ""}
                                    </td>
                                    <td className="text-start pe-0 py-5">
                                      {_capitalize(emailCampaign.status)}
                                    </td>

                                    <td className="text-end pe-0 py-5">
                                      <div className="w-fit-content d-flex align-items-center">
                                        <button
                                          type="button"
                                          className="btn px-0 py-0"
                                          onClick={() =>
                                            toggleOffcanvas(emailCampaign.id)
                                          }
                                        >
                                          <i className="fa-bar-chart fs-2 cursor-pointer ms-5 fa-regular "></i>
                                        </button>

                                        <button
                                          type="button"
                                          className="btn px-0 py-0"
                                          onClick={() =>
                                            toggleModal(emailCampaign.id)
                                          }
                                          disabled={
                                            emailCampaign.status === "DRAFT"
                                          }
                                        >
                                          <i className="fa-paper-plane fs-2 cursor-pointer fa-regular  ms-5"></i>
                                        </button>
                                        <Link
                                          to={`/emailcampaignsettings/${emailCampaign.id}`}
                                        >
                                          <button
                                            type="button"
                                            className="btn px-0 py-0"
                                          >
                                            <i className="fa-cog fs-2 cursor-pointer fa-regular  ms-5"></i>
                                          </button>
                                        </Link>
                                        <button
                                          type="button"
                                          className="btn px-0 py-0"
                                          onClick={() =>
                                            openConfirmModal(emailCampaign.id)
                                          }
                                        >
                                          <i
                                            style={{ marginTop: "-4px" }}
                                            title="Delete"
                                            className="fa-regular fa-trash-can fs-2 ms-5 text-danger cursor-pointer"
                                          />
                                        </button>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td
                                  className="border-0 position-relative w-100"
                                  colSpan={"8"}
                                >
                                  {notLinksScreenMapper[notLinksScreen]}
                                </td>
                              </tr>
                            )}

                            <tr className="d-none">
                              <td colspan="9" className="py-5 bg-white">
                                <div className="d-flex flex-column justify-content-center align-items-center mt-15">
                                  <img
                                    className="opacity-50 img-fluid"
                                    src={EmptyState}
                                    alt=""
                                  />
                                  <div className="text-center mt-lg-10">
                                    <h2 className="fs-2x pt-5 pb-5 fw-bold">
                                      No Contacts to display.
                                    </h2>
                                    <p className="w-75 mx-auto fw-semibold fs-5 text-gray-500 mb-8">
                                      Lorem ipsum dolor, sit amet consectetur
                                      adipisicing elit. Odit, deserunt. Lorem
                                      ipsum dolor sit amet.
                                    </p>
                                    <button
                                      type="button"
                                      className="btn btn-info rounded-1"
                                    >
                                      Add New Contact
                                    </button>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      )}
                    </CardBody>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal isOpen={isModalOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Choose Email Provider</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label for="emailProvider">Email Provider</Label>
            <Input
              type="select"
              name="emailProvider"
              id="emailProvider"
              value={selectedProvider}
              onChange={handleProviderChange}
            >
              <option value="">Select a provider</option>
              {providers.map((provider) => (
                <option key={provider.id} value={provider.name}>
                  {_capitalize(provider.name)}
                </option>
              ))}
            </Input>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            class="btn text-nowrap rounded-1 btn-info text-white"
            color="danger"
            onClick={handleClick}
          >
            {sendLoading ? <Spinner size="sm" /> : "Send"}
          </button>

          <button
            type="button"
            className="btn text-nowrap rounded-1 btn-secondary"
            onClick={toggleModal}
            style={{ marginLeft: "10px" }}
          >
            Cancel
          </button>
        </ModalFooter>
      </Modal>
      <Offcanvas
        isOpen={isAnalyticsOpen}
        toggle={toggleOffcanvas}
        direction="end"
      >
        <OffcanvasHeader toggle={toggleOffcanvas}>
          Campaign Analytics
        </OffcanvasHeader>
        <OffcanvasBody>
          {analyticsLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <Spinner color="primary" />
            </div>
          ) : (
            <Row>
              <Col md="3">
                <Card>
                  <CardBody className="text-center">
                    <CardTitle tag="h5">Opened</CardTitle>
                    <CardText>
                      <h1 style={{ fontSize: "3rem" }}>{`${
                        providerAnalytics.open || 0
                      }`}</h1>
                    </CardText>
                  </CardBody>
                </Card>
              </Col>
              <Col md="3">
                <Card>
                  <CardBody className="text-center">
                    <CardTitle tag="h5">Sent</CardTitle>
                    <CardText>
                      <h1 style={{ fontSize: "3rem" }}>{`${
                        providerAnalytics.delivered || 0
                      }`}</h1>
                    </CardText>
                  </CardBody>
                </Card>
              </Col>
              <Col md="3">
                <Card>
                  <CardBody className="text-center">
                    <CardTitle tag="h5">Bounced</CardTitle>
                    <CardText>
                      <h1 style={{ fontSize: "3rem" }}>{`${
                        providerAnalytics.bounced || 0
                      }`}</h1>
                    </CardText>
                  </CardBody>
                </Card>
              </Col>
              <Col md="3">
                <Card>
                  <CardBody className="text-center">
                    <CardTitle tag="h5">Clicked</CardTitle>
                    <CardText>
                      <h1 style={{ fontSize: "3rem" }}>{`${
                        providerAnalytics.click || 0
                      }`}</h1>
                    </CardText>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
          <Analytics
            shortlink={analyticsLink}
            type={"shortlink"}
            loading={analyticsLoading}
            analytics={analytics}
            analyticsHandler={fetchAnalytics}
          />
        </OffcanvasBody>
      </Offcanvas>
      <Modal isOpen={isConfirmModalOpen} toggle={toggleConfirmModal}>
        <ModalHeader toggle={toggleConfirmModal}>Confirm Delete</ModalHeader>
        <ModalBody>Are you sure you want to delete this campaign?</ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={confirmDelete}>
            Confirm
          </Button>
          <Button color="secondary" onClick={toggleConfirmModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default EmailCampaignsMain;
