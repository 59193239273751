import React, { useCallback, useContext, useState } from "react";
import {
  Col,
  Row,
  TabPane,
  Input,
  FormGroup,
  Label,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { generateRandomString } from "../../../utils/generateRandomString";
import titleFetcher from "../../../utils/titleFetcher";
import _debounce from "lodash/debounce";
import useCustomLinks from "../../../hooks/useCustomLinks";
import { AuthContxt } from "../../../store/authContxt";
import { PLAN } from "../../../utils/enum";

const QrLink = ({ qrType, formData, setFormData, isEdit }) => {
  const { quota } = useContext(AuthContxt);
  const customLinks = useCustomLinks();
  const [destinationValue, setDestinationValue] = useState('');
  const BASE_URL = process.env.REACT_APP_QR_BASE_URL;

  const getCustomDomainHost = (customLink) => {
    const { domain, subDomain, spare } = customLink;
    try {
      const url = new URL(domain);
      return spare === "No" ? `${subDomain}.${url.host}` : url.host;
    } catch (e) {
      console.log("Error while creating url : ", e);
      return domain;
    }
  };

  const customLinkHandler = (customLink) => {
    const brandedDomain = getCustomDomainHost(customLink);
    setFormData(prevData => ({
      ...prevData,
      brandedDomain
    }));
  };

  
  

  return (
      <div className="card-body px-0 mw-1000px pb-0 pt-1">
        <Row>
          <Col sm="12">
            <div className="rounded-1">
            <div className="mt-lg-0 mt-7">
              
            <FormGroup>
                <Label className="fw-bold fs-5 mb-2 required">
                  Name your QR Code <span className="text-gray-400 fw-regular fs-7"></span>
                </Label>
                <Input
                  value={formData?.title || ""}
                  onChange={(e) => {
                    setFormData(prevData => ({
                      ...prevData,
                      title: e.target.value,
                    }));
                  }}
                  placeholder="Example: My Website QR Code"
                  className="h-50px"
                />
              </FormGroup>

                <p className="text-gray-700 w-75 mt-5 fs-5">
                Names help you stay organized and are only visible within your account; they won't be shown to customers scanning your QR codes.
                </p>
              </div>
              <div className="d-flex">
                <label className="fw-bold fs-5 mb-2 mt-3 w-50">Domain</label>
                <label className="fw-bold fs-5 mb-2 mt-3 ps-3">Custom Slug</label>
              </div>
              <div className="input-group justify-content-between mb-6 h-50px">
                <div className="custom-dropdown bg-light form-control p-0">
                  <UncontrolledDropdown direction="down">
                    <DropdownToggle
                      disabled={isEdit}
                      caret
                      color="transparent"
                      className="p-3 w-100 text-start text-wrap-oneline h-50px"
                    >
                      {formData?.brandedDomain || BASE_URL}
                    </DropdownToggle>
                    <DropdownMenu>
                      {customLinks &&
                        customLinks.length > 0 &&
                        customLinks.map((dom) => (
                          <DropdownItem
                            key={dom.id}
                            onClick={() => {
                              !isEdit && customLinkHandler(dom);
                            }}
                          >
                            {getCustomDomainHost(dom)}
                          </DropdownItem>
                        ))}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
                <span className="input-group-text">/</span>
                <Input
                  disabled={isEdit}
                  required
                  value={formData?.slashTag || ""}
                  onChange={(e) => {
                    if (!isEdit) {
                      setFormData(prevData => ({
                        ...prevData,
                        slashTag: e.target.value,
                      }));
                    }
                  }}
                  placeholder="Generated Slash Tag"
                />
              </div>
            </div>
          </Col>
        </Row>
      </div>
  );
};

export default QrLink;
