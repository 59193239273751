import { createContext, useEffect, useState } from "react";
import UserSettingsApi from "../services/http/userSettings";
import SubscriptionApi from "../services/http/subscription";
import generateProfileImage from "../utils/generateProfileImage";
import withOnboarding from "../components/wrapper/withOnboarding";

export const AuthContxt = createContext(null);

const AuthContxtProvider = (props) => {
    const [profile, setProfile] = useState({});
    const [quota, setQuota] = useState(null);
    console.log(quota , "quota data in auth context provider")
    const [render, setRender] = useState(null);
    const [loading, setLoading] = useState(true);
    const [errors, setErrors] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [dataFetched, setDataFetched] = useState(false); // Flag to prevent redundant fetches

    // Logout function
    const logout = () => {
        setProfile({});
        localStorage.removeItem("user_details");
        localStorage.removeItem("auth_token");
        sessionStorage.removeItem('tourState');
        sessionStorage.removeItem('skipProfileCompletion');
    };

    // Fetch user profile
    const fetchProfile = async () => {
        try {
            setLoading(true); // Set loading state
            const data = await UserSettingsApi.fetchProfile();
            const locationResponse = await UserSettingsApi.getLocation();
            
            let profileDetails = data?.data?.data || {};
            profileDetails.mobile = profileDetails?.mobile || '';
            profileDetails.country = profileDetails?.country || locationResponse?.data?.data?.countryCode || '';

            if (!profileDetails?.avatar) {
                profileDetails.avatar = generateProfileImage(profileDetails.email);
            }

            localStorage.setItem("user_details", JSON.stringify(profileDetails));
            setProfile(profileDetails);
        } catch (e) {
            setErrors('Failed to fetch profile');
            console.error("Error fetching profile:", e);
        } finally {
            setLoading(false); // End loading state
        }
    };

    // Fetch quota data
    const fetchQuota = async () => {
        try {
            const { data } = await SubscriptionApi.getCurrentQuota();
            setQuota(data?.data);
        } catch (e) {
            setErrors('Failed to fetch quota');
            console.error("Error fetching quota:", e);
        }
    };

    // Combined useEffect to fetch profile and quota once
    useEffect(() => {
        const authToken = localStorage.getItem("auth_token");
        const fetchData = async () => {
            if (authToken && !dataFetched) {  // Check if data is already fetched and if authToken exists
                await fetchProfile();  // Fetch profile first
                await fetchQuota();    // Fetch quota only after profile is fetched
                setDataFetched(true);  // Mark data as fetched to prevent redundant calls
            }
        };

        fetchData().catch((e) => console.error(e));
    }, [dataFetched]);  // Dependency array ensures it runs only when needed

    // Update profile after editing
    const updateProfile = async (updatedData) => {
        try {
            setLoading(true);  // Start loading state
            const data = await UserSettingsApi.updateProfile(updatedData);
            await fetchProfile();  // Refetch profile to get the latest data
            return data?.data?.data;
        } catch (e) {
            setErrorMessage('Failed to update profile');
            console.error("Error updating profile:", e);
            throw e;
        } finally {
            setLoading(false);  // End loading state
        }
    };

    // Track render state
    useEffect(() => {
        console.log("Render state has changed:", render);
    }, [render]);

    return (
        <AuthContxt.Provider value={{
            profile,
            setProfile,
            quota,
            loading,
            errors,
            errorMessage,
            logout,
            updateProfile,
            fetchProfile,
            fetchQuota,
            setErrors,
            setLoading,
            render,
            setRender
        }}>
            {props.children}
        </AuthContxt.Provider>
    );
};

const AuthProviderWithOnboarding = withOnboarding(AuthContxtProvider);
export { AuthContxtProvider, AuthProviderWithOnboarding };
