import httpMethods from "..";

const IntegrationsAPI = {
  testIntegration: async (token, values) => {
    return httpMethods
      .post(`/integration/test`, values, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => res);
  },

  saveIntegration: async (token, values) => {
    return httpMethods
      .post(`/integration/save`, values, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => res);
  },

  getIntegration: async (token, id) => {
    return httpMethods
      .get(`/integration/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => res);
  },

  updateIntegration: async (token, id, values) => {
    return httpMethods
      .put(`/integration/${id}`, values, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => res);
  },

  deleteIntegration: async (token, id) => {
    return httpMethods
      .delete(`/integration/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => res);
  },
  getAllIntegrations: async (token) => {
    return httpMethods
      .get(`/integration`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => res);
  },
  getAllEmailIntegrations: async (token) => {
    return httpMethods
      .get(`/integration/email`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => res);
  },
  getAllSmsIntegrations: async (token) => {
    return httpMethods
      .get(`/integration/sms`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => res);
  },
};

export default IntegrationsAPI;
