import WhatsappCampaignMain from "../components/whatsapp-campaigns";
import WhatsappCampaignContxtProvider from "../components/whatsapp-campaigns/whatsappCampaignContxt";

const WhatsappCampaigns = () => {
  return (
    <WhatsappCampaignContxtProvider>
      <WhatsappCampaignMain />
    </WhatsappCampaignContxtProvider>
  );
};

export default WhatsappCampaigns;
